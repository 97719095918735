<ng-container [ngSwitch]="dateFormat" *transloco="let translate">
    <!-- Format: dd/yyyy/MM -->
    <ng-container *ngSwitchCase="'dd/yyyy/MM'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat2 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: MM/dd/yyyy -->
    <ng-container *ngSwitchCase="'MM/dd/yyyy'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat3 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: yyyy-MM-dd -->
    <ng-container *ngSwitchCase="'yyyy-MM-dd'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat4 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: ddMMyyyy -->
    <ng-container *ngSwitchCase="'ddMMyyyy'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat5 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: dd-MM-yyyy -->
    <ng-container *ngSwitchCase="'dd-MM-yyyy'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat6 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: dd-mm-yyyy -->
    <ng-container *ngSwitchCase="'dd-mm-yyyy'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat6 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: dd-MMM-yyyy -->
    <ng-container *ngSwitchCase="'dd-MMM-yyyy'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat7 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: yyyy/dd/MM -->
    <ng-container *ngSwitchCase="'yyyy/dd/MM'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat8 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: MM-yyyy-dd -->
    <ng-container *ngSwitchCase="'MM-yyyy-dd'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat9 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: yyyy/MM/dd -->
    <ng-container *ngSwitchCase="'yyyy/MM/dd'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat10 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: yyyy-dd-MM -->
    <ng-container *ngSwitchCase="'yyyy-dd-MM'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat11 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: dd-yyyy-MM -->
    <ng-container *ngSwitchCase="'dd-yyyy-MM'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat12 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: MM-dd-yyyy -->
    <ng-container *ngSwitchCase="(dateFormat === 'MM-dd-yyyy' || dateFormat === 'mm-dd-yyyy') ? dateFormat : ''">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat13 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: MM/yyyy/dd -->
    <ng-container *ngSwitchCase="'MM/yyyy/dd'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat14 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: yyyyMMdd -->
    <ng-container *ngSwitchCase="'yyyyMMdd'">
        <mat-form-field class="{{formFieldClass}}" appCustomDateFormat15 [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>

    <!-- Format: default -->
    <ng-container *ngSwitchDefault>
        <mat-form-field class="{{formFieldClass}}" appDefaultDateFormat [appearance]="appearance">
            <mat-label *ngIf="label?.length > 0">{{label}}</mat-label>
            <input matInput tabindex="-1" placeholder="{{placeholder}}" name="{{name}}" title="{{title}} ( {{dateFormat}} )" id="{{id}}" class="{{classList && classList.join(' ')}}" [(ngModel)]="mannualInput" [errorStateMatcher]="matcher" [min]="minDateValue" [max]="maxDateValue" [matDatepicker]="DatePicker" (input)="onInput($event)" (blur)="onFocusOut($event)" (keyup.enter)="onFocusOut($event)" [disabled]="disabled">
            <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="mannualInput || originalInput" [disabled]="disabled" tabindex="-1" (click)="$event.stopPropagation();$event.preventDefault();applyChangesToDate(undefined)">
                <span class="material-icons-outlined fieldDescInfoIcon addToIgnoreList close" title="{{description}}"> close </span>
            </button>
            <mat-datepicker-toggle class="hover:text-taxillaGreen" matSuffix [for]="DatePicker" [disableRipple]="disabled"></mat-datepicker-toggle>
            <mat-datepicker touchUi #DatePicker [disabled]="disabled" (closed)="onDatePickerClose()"></mat-datepicker>
            <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}"> info </span>
            </button>
            <mat-hint align="start" *ngIf="required && errors?.length === 0 && warnings?.length === 0">
                <strong> <span> * </span> {{translate('Required')}} </strong>
            </mat-hint>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="errors?.length !== 0 || warnings?.length !== 0">
        <mat-error class="leading-4 -mt-6" *ngIf="errors?.length > 0" title="{{errors}}"> {{errors}}</mat-error>
        <mat-error class="leading-4 -mt-6" [class.mat-has-warning]="warnings?.length > 0" *ngIf="errors?.length === 0 && warnings?.length > 0" title="{{warnings}}"> {{warnings}} </mat-error>
    </ng-container>
</ng-container>
