import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AssetService } from '../../models/assetservice.class';
import { ApiService } from '../../services/api/api.service';
import { FileUpload } from '../../services/file-upload/fileuploadservice';
import { UtilsService } from '../../services/utils/utils.service';
import {
    AlertError,
    AlertSuccess,
    CreateReconRequest,
    CreateReportRequest,
    CreateRequestManually,
    CreateRequestViaSearch,
    CreateRequestViaUpload,
    DownloadReportRequest,
    GetGstFilingRequests,
    GetReconRequests,
    GetReconTemplates,
    GetUploadedFile,
    OpenReconRequest,
    RequestCreated,
    SetReconRequests,
    SetReconTemplates,
    SetRequest,
    SetRequestLoading,
    SetRequests,
    SetUploadedFile,
} from '../actions';
import {
    currentFilingMonth$,
    currentFilingYear$,
    getAppConfiguratorsMap$,
    getAppsMetaDataMap$,
    getCurrentOrganizationId$,
    getCustomPeriodicSearchMap$,
    getRequestsOrgServicesMap$,
    getRequestsUploadedFilesMap$,
    getSubscribedAppsMap$,
} from '../selectors';

@Injectable()
export class RequestEffects {
    constructor(
        private actions$: Actions,
        private _api: ApiService,
        private store$: Store,
        private _utils: UtilsService,
        private _fileUpload: FileUpload,
        private _router: Router
    ) {}

    getGstFilingRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetGstFilingRequests),
            delay(500),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getRequestsOrgServicesMap$),
                this.store$.select(getAppConfiguratorsMap$),
                this.store$.select(getCustomPeriodicSearchMap$)
            ),
            mergeMap(([action, organizationId, appsMap, assetsMetaDataMap, orgServicesMap, configurators, customPeriodicSearchMap]) => {
                const app = appsMap?.[action.serviceId] as AssetService;
                if (!app) {
                    return [];
                }
                if (app.assetType === 'RECON') {
                    return [];
                }
                const metaData = assetsMetaDataMap?.[action.serviceId]?.[app?.assetMetaUId];
                if (!metaData) {
                    return of(GetGstFilingRequests(action));
                }
                const searchMap = orgServicesMap?.[action.serviceId]?.searchedRequestIds;
                const searchCriteriaString = `||`;
                if (searchMap?.[searchCriteriaString]?.length > 0 && !action?.forceFetch) {
                    return [];
                }
                const selectedAppConfigurator = configurators?.[action.serviceId]?.[app?.assetMetaUId];
                const customPeriodicSearch = customPeriodicSearchMap?.[action.serviceId]?.[app?.assetMetaUId];
                const searchCriteria = UtilsService.getGSTRFilingRequestsSearchCriteria(
                    customPeriodicSearch,
                    selectedAppConfigurator,
                    metaData,
                    action.serviceId,
                    undefined,
                    undefined,
                    undefined
                );
                delete searchCriteria.loadRequests;
                return from(
                    this._api.requests.searchRequests({
                        entityCriterias: searchCriteria,
                        from: 1,
                        noAlerts: action.noAlerts,
                        size: undefined,
                        searchAfter: undefined,
                    })
                ).pipe(
                    map((res) => {
                        return SetRequests({
                            organizationId,
                            requests: res?.requests || [],
                            searchCriteria: searchCriteriaString,
                            serviceId: action.serviceId,
                        });
                    }),
                    catchError(() => [])
                );
            })
        )
    );

    createRequestViaUpload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateRequestViaUpload),
            delay(10),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, servicesMap]) => {
                const service = servicesMap?.[action.serviceId];
                const formData = new FormData();
                let fileFound = false;
                if (!action.transformation) {
                    return of(AlertError({ message: 'Select transformation' }));
                }
                let isSupportedFormat = true;
                action.transformation.sourceParts.forEach((source) => {
                    // We will allow to initiate the request even if one source file exists
                    fileFound = !!source.file || fileFound;
                    const externsions = ['.zip', ...source.fileExtension.split(',')];
                    const file = source.file;
                    if (file && externsions) {
                        const fileExt = file.name.substring(file.name.lastIndexOf('.'), file.name.length).toLowerCase();
                        isSupportedFormat = isSupportedFormat && externsions.indexOf(fileExt) >= 0;
                        const isIE = this._utils.isIEBrowser();
                        if (isIE) {
                            formData.append(source.name, source.file, source.file.name);
                        } else {
                            formData.append(source.name, source.file);
                        }
                    }
                });
                if (!isSupportedFormat) {
                    return of(AlertError({ message: 'Select valid file format' }));
                }
                if (!fileFound) {
                    return of(AlertError({ message: 'Select file(s) to upload' }));
                }
                let url = environment.taxilla_api + '/process/v1/' + service.restApiName + '?sourceType=UPLOAD';
                url += '&transformationName=' + action.transformation.chainName;
                return from(this._fileUpload.uploadRequestViaPromise('POST', url, formData)).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            AlertSuccess({
                                message: res?.msg,
                            })
                        );
                        return RequestCreated({
                            serviceId: action.serviceId,
                            requestId: res?.response?.requestId,
                            newRequest: true,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        )
    );

    createRequestManually$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateRequestManually),
            delay(10),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, servicesMap]) => {
                const service = servicesMap?.[action.serviceId];
                return from(
                    this._api.requests.createNewRequest({
                        payload: action.formData,
                        service,
                        bridge: undefined,
                        report: undefined,
                        noAlerts: true,
                    })
                ).pipe(
                    map((res) => {
                        if (res?.response?.REQUEST_EXISTS) {
                            return AlertError({
                                message: res?.msg,
                            });
                        }
                        if (!action.multipleCalls) {
                            this.store$.dispatch(
                                AlertSuccess({
                                    message: res?.msg,
                                })
                            );
                        }
                        return RequestCreated({
                            serviceId: action.serviceId,
                            requestId: res?.response?.requestId,
                            newRequest: true,
                            ...action,
                        });
                    }),
                    catchError(() => [])
                );
            })
        )
    );

    createRequestsViaAssetToAsset$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CreateRequestViaSearch),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                return this._api.requests
                    .processConsignorReport({
                        ...action,
                        appRestApiName: app.restApiName,
                    })
                    .pipe(
                        map((res: any) => {
                            this.store$.dispatch(
                                AlertSuccess({
                                    message: res?.msg,
                                })
                            );
                            this.store$.dispatch(SetRequest({ requestResponse: res }));
                            return RequestCreated({
                                serviceId: action.serviceId,
                                requestId: res?.response?.requestId,
                                newRequest: true,
                                ...action,
                            });
                        }),
                        catchError((err) => of(AlertError({ message: err.msg })))
                    );
            })
        );
    });

    getReconRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetReconRequests),
            delay(500),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getRequestsOrgServicesMap$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$)
            ),
            mergeMap(([action, organizationId, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                if (!app) {
                    return [];
                }
                if (app.assetType !== 'RECON') {
                    return [];
                }
                this.store$.dispatch(
                    SetRequestLoading({
                        category: 'requests',
                        loading: true,
                        organizationId: organizationId,
                        serviceId: action.serviceId,
                    })
                );
                return from(
                    this._api.requests.getReconciliationRequests({
                        fromDate: action.fromDate,
                        pagingState: action.pagingState,
                        restApiName: app.restApiName,
                        size: action.size || 20,
                        toDate: action.toDate,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetRequestLoading({
                                category: 'requests',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return SetReconRequests({
                            organizationId,
                            requests: res?.records || [],
                            fromDate: action.fromDate,
                            pagingState: res?.nextPagingState,
                            serviceId: action.serviceId,
                            toDate: action.toDate,
                            pushAtTop: action.pushAtTop,
                        });
                    }),
                    catchError(() =>
                        of(
                            SetRequestLoading({
                                category: 'requests',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        )
                    )
                );
            })
        )
    );

    getReconTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetReconTemplates),
            delay(500),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getRequestsOrgServicesMap$)
            ),
            mergeMap(([action, organizationId, appsMap, orgRequestsMap]) => {
                const app = appsMap?.[action.serviceId];
                const templates = orgRequestsMap?.[app.serviceId]?.templates;
                if (!app || app.assetType !== 'RECON' || (templates?.length > 0 && !action.forceFetch)) {
                    return [];
                }
                this.store$.dispatch(
                    SetRequestLoading({
                        category: 'templates',
                        loading: true,
                        organizationId: organizationId,
                        serviceId: action.serviceId,
                    })
                );
                return from(
                    this._api.requests.getAllReconTemplates({
                        restApiName: app.restApiName,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetRequestLoading({
                                category: 'templates',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return SetReconTemplates({
                            organizationId,
                            serviceId: action.serviceId,
                            templates: res?.records,
                        });
                    }),
                    catchError(() =>
                        of(
                            SetRequestLoading({
                                category: 'templates',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        )
                    )
                );
            })
        )
    );

    createReconRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateReconRequest),
            delay(500),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, organizationId, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                this.store$.dispatch(
                    SetRequestLoading({
                        category: 'requests',
                        loading: true,
                        organizationId,
                        serviceId: action.serviceId,
                    })
                );
                return from(
                    this._api.requests.createNewReconciliation({
                        restApiName: app.restApiName,
                        payload: action.formData,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetRequestLoading({
                                category: 'requests',
                                loading: false,
                                organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        this.store$.dispatch(
                            AlertSuccess({
                                message: res?.message || res?.msg || 'Request created successfully',
                            })
                        );
                        return RequestCreated({
                            serviceId: action.serviceId,
                            requestId: res?.requestId,
                            newRequest: true,
                        });
                    }),
                    catchError(() =>
                        of(
                            SetRequestLoading({
                                category: 'requests',
                                loading: false,
                                organizationId,
                                serviceId: action.serviceId,
                            })
                        )
                    )
                );
            })
        )
    );

    openReconRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(OpenReconRequest),
            delay(10),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, services, organizationId]) => {
                const app = services?.[action.serviceId];
                if (!app) {
                    return [];
                }
                this._router.navigateByUrl(
                    `enreconcile/organizations/${organizationId}/home/apps/${app.restApiName}/processes/inbound-transmissions/${action.requestId}/instances`
                );
                return [];
            })
        )
    );

    getRequestUploadedFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetUploadedFile),
            delay(500),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getRequestsUploadedFilesMap$)
            ),
            mergeMap(([action, organizationId, appsMap, requestsVsfilesMap]) => {
                const app = appsMap?.[action.serviceId];
                if (!app?.restApiName || requestsVsfilesMap?.[action.requestId]) {
                    return [];
                }
                this.store$.dispatch(
                    SetRequestLoading({
                        category: 'uploadedFiles',
                        loading: true,
                        organizationId: organizationId,
                        serviceId: action.serviceId,
                    })
                );
                return from(
                    this._api.requests.downloadNewUploadedFiles({
                        resourceId: action.requestId,
                        restApiName: app?.restApiName,
                        noAlerts: action?.noAlerts,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetRequestLoading({
                                category: 'uploadedFiles',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        const fileObj = res?.[0];
                        const fileName = fileObj.fileUri.substring(fileObj.fileUri.lastIndexOf('/') + 1);
                        const fileLink =
                            environment?.taxilla_api +
                            '/process/v1/files?fileUrl=' +
                            encodeURIComponent(fileObj.fileUri) +
                            '&fileName=' +
                            encodeURIComponent(fileName);
                        return SetUploadedFile({
                            fileObject: {
                                fileName,
                                fileLink,
                            },
                            requestId: action.requestId,
                        });
                    }),
                    catchError((error) => {
                        this.store$.dispatch(AlertError({ message: error?.msg }));
                        return of(
                            SetRequestLoading({
                                category: 'uploadedFiles',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                    })
                );
            })
        )
    );

    createReportRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateReportRequest),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                return this._api.assets
                    .downloadErrorReport({
                        assetName: app?.displayName,
                        reportName: action.reportName,
                        repositoryId: action.repositoryId,
                        restApiServiceName: app?.restApiName,
                        serviceId: action.serviceId,
                        defaultFilterName: action.defaultFilterName,
                        filterCriteria: action.filterCriteria,
                        generateCompleteInstanceReport: action.generateCompleteInstanceReport,
                    })
                    .pipe(
                        map((res) => {
                            return DownloadReportRequest({
                                downloadUrl: res?.['assetRequest']?.url,
                                requestId: res?.['assetRequest']?.requestId,
                                serviceId: action.serviceId,
                            });
                        }),
                        catchError((e) => of(AlertError({ message: e?.msg || translate('Failed to initiate download error report') })))
                    );
            })
        )
    );
}
