import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { translate, TranslocoService } from '@ngneat/transloco';

import * as timezones from '../../constants/timezones.json';

@Injectable({
    providedIn: 'root',
})
export class CommonUtilsService {
    analyticsServiceTypes: string;
    static offsetTime: number;
    static shortMonthsList = {
        Jan: '01',
        Feb: '02',
        Mar: '03',
        Apr: '04',
        May: '05',
        Jun: '06',
        Jul: '07',
        Aug: '08',
        Sep: '09',
        Oct: '10',
        Nov: '11',
        Dec: '12',
    };
    static readonly monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    setOffsetTimeout: any;
    transformDateToLocale = CommonUtilsService.transformDateToLocale;
    transformDateToDefaultFormat = CommonUtilsService.transformDateToDefaultFormat;
    isIsoFormat = CommonUtilsService.isIsoFormat;
    static INSTANCE_STATES: any[] = [
        {
            name: 'In-Progress',
            value: 'IN_PROGRESS',
        },
        {
            name: 'Request Completed',
            value: 'REQUEST_COMPLETED',
        },
        {
            name: 'Process Cancelled',
            value: 'REQUEST_CANCELLED',
        },
    ];
    static VALIDATION_STATUS: any[] = [
        {
            name: 'Success',
            value: 'SUCCESS',
        },
        {
            name: 'Warning',
            value: 'WARNING',
        },
        {
            name: 'Error',
            value: 'ERROR',
        },
    ];
    static gstnStateCode = {
        '01': 'Jammu And Kashmir',
        '02': 'Himachal Pradesh',
        '03': 'Punjab',
        '04': 'Chandigarh',
        '05': 'Uttarakhand',
        '06': 'Haryana',
        '07': 'Delhi',
        '08': 'Rajasthan',
        '09': 'Uttar Pradesh',
        '10': 'Bihar',
        '11': 'Sikkim',
        '12': 'Arunachal Pradesh',
        '13': 'Nagaland',
        '14': 'Manipur',
        '15': 'Mizoram',
        '16': 'Tripura',
        '17': 'Meghalaya',
        '18': 'Assam',
        '19': 'West Bengal',
        '20': 'Jharkhand',
        '21': 'Odisha',
        '22': 'Chattishgarh',
        '23': 'Madhya Pradesh',
        '24': 'Gujarat',
        '26': 'Dadra And Nagar Haveli / Daman And Diu',
        '27': 'Maharashtra',
        '28': 'Andhra Pradesh',
        '29': 'Karnataka',
        '30': 'Goa',
        '31': 'LakshaDweep',
        '32': 'Kerala',
        '33': 'Tamil Nadu',
        '34': 'Puducherry',
        '35': 'Andaman And Nicobar Islands',
        '36': 'Telangana',
        '37': 'Andhra Pradesh',
        '38': 'Ladakh',
        '97': 'Other Territory',
        '99': 'Centre Jurisdiction',
    };

    constructor(private _router: Router, private _translate: TranslocoService) {
        this.setOffsetTime();
    }

    static getOrgAndUserOffsetTimeStamp = () => {
        const totalOffset = CommonUtilsService.getFromStorage('timeZoneOffset');
        const timeStamp =
            totalOffset !== undefined
                ? new Date().getTime() - (totalOffset - new Date().getTimezoneOffset()) * 60 * 1000
                : new Date().getTime();
        return timeStamp;
    };

    static transformDateToLocale = (
        date: string,
        inputFormat:
            | 'dd/mm/yyyy'
            | 'mm/dd/yyyy'
            | 'dd/MM/yyyy'
            | 'DD/MM/YYYY HH:MM:SS AM'
            | 'DD-MM-YYYY HH:MM:SS AM'
            | 'yyyy-mm-dd'
            | 'ddmmyyyy'
            | 'yyyymmdd'
            | 'dd-mm-yyyy'
            | 'dd/yyyy/MM'
            | 'dd-MMM-yyyy'
            | 'dd-mm-yyyy'
            | 'YYYY-MM-DDTHH:MM:SSZ'
            | 'DATE'
            | 'yyyy/dd/MM'
            | 'yyyy-MM-dd'
            | 'yyyy-dd-MM'
            | 'yyyy/MM/dd'
            | 'dd-yyyy-MM'
            | 'MM-yyyy-dd'
            | 'MM/yyyy/dd'
            | 'MM-dd-yyyy',
        outputFormat:
            | 'dd/mm/yyyy'
            | 'mm/dd/yyyy'
            | 'mm-dd-yyyy'
            | 'dd/MM/yyyy'
            | 'DD/MM/YYYY HH:MM:SS AM'
            | 'DD-MM-YYYY HH:MM:SS AM'
            | 'dd-MMM-yyyy'
            | 'yyyy-MM-dd'
            | 'ddMMyyyy'
            | 'yyyyMMdd'
            | 'dd/yyyy/MM'
            | 'ddMMMyyyy HH:MM:SS AM'
            | 'ddMMMyyyy'
            | 'MM-DD-YYYY HH:MM:SS AM'
            | 'dd-mm-yyyy'
            | 'yyyy/dd/MM'
            | 'yyyy-dd-MM'
            | 'yyyy/MM/dd'
            | 'dd-yyyy-MM'
            | 'MM-yyyy-dd'
            | 'MM/yyyy/dd'
            | 'MM-dd-yyyy'
            | 'DATE'
            | 'TIMESTAMP',
        noOffsetTime: boolean
    ): string => {
        let zoneOffsetTime = noOffsetTime ? 0 : CommonUtilsService.offsetTime || 0;
        if (typeof zoneOffsetTime === 'object') {
            zoneOffsetTime = 0;
        }
        if (!date || date === null || date === undefined || date === 'null' || date === 'undefined') {
            return;
        }
        let splitCompleteDate;
        let splitDate;
        let splitTime;
        if (inputFormat === 'YYYY-MM-DDTHH:MM:SSZ') {
            splitCompleteDate = date.split('T');
            splitDate = splitCompleteDate[0]?.split(splitCompleteDate[0].indexOf('-') > -1 ? '-' : '/');
            const splitZone = splitCompleteDate[1]?.split(splitCompleteDate[1].indexOf('Z') > -1 ? 'Z' : '+');
            splitTime = splitZone?.[0]?.split(':');
        } else if (inputFormat !== 'DATE') {
            splitCompleteDate = date.split(' ');
            splitDate = splitCompleteDate[0].split(splitCompleteDate[0].indexOf('-') > -1 ? '-' : '/');
            splitTime = splitCompleteDate[1] && splitCompleteDate[1].split(':');
            if (date) {
                if (inputFormat.toLowerCase() === 'ddmmyyyy') {
                    splitDate = [date.substring(0, 2), date.substring(2, 4), date.substring(4, 6)];
                }
            }
        }
        splitTime = splitTime ?? ['00', '00', '00'];
        let dd;
        let mm, month;
        let yyyy;
        let outputDate = '';
        let hh: any;
        let minutes: any;
        let ss: any;
        let time = ((splitCompleteDate && splitCompleteDate[2]) || 'AM').toLowerCase();
        const output = [];
        if (inputFormat === 'DATE' && (date as any) instanceof Date) {
            const preFormatDate: Date = date as any;
            yyyy = '' + preFormatDate.getFullYear();
            mm = '' + (preFormatDate.getMonth() + 1);
            dd = '' + preFormatDate.getDate();
            hh = preFormatDate.getHours();
            minutes = '' + preFormatDate.getMinutes();
            ss = '' + preFormatDate.getSeconds();
            if (hh > 12) {
                time = 'pm';
                hh -= 12;
            } else if (hh === 12) {
                time = 'pm';
            } else {
                time = 'am';
            }
            hh = '' + hh;
        } else if (inputFormat === 'YYYY-MM-DDTHH:MM:SSZ') {
            yyyy = splitDate[0];
            mm = splitDate[1];
            dd = splitDate[2];
            hh = splitTime[0];
            minutes = splitTime[1];
            ss = splitTime[2];
            if (hh === 12 || hh === '12') {
                time = 'pm';
            }
        } else if (inputFormat.toLowerCase() === 'dd/mm/yyyy' || inputFormat.toLocaleLowerCase() === 'dd-mm-yyyy') {
            dd = splitDate[0];
            mm = splitDate[1];
            yyyy = splitDate[2];
        } else if (inputFormat.toLowerCase() === 'mm/dd/yyyy') {
            dd = splitDate[1];
            mm = splitDate[0];
            yyyy = splitDate[2];
        } else if (inputFormat === 'DD/MM/YYYY HH:MM:SS AM' || inputFormat === 'DD-MM-YYYY HH:MM:SS AM') {
            dd = splitDate[0];
            mm = splitDate[1];
            yyyy = splitDate[2];
            hh = splitTime[0];
            minutes = splitTime[1];
            ss = splitTime[2];
        } else if (inputFormat.toLowerCase() === 'yyyy-mm-dd') {
            dd = splitDate[2];
            mm = splitDate[1];
            yyyy = splitDate[0];
        } else if (inputFormat.toLowerCase() === 'yyyy/dd/mm') {
            dd = splitDate[1];
            mm = splitDate[2];
            yyyy = splitDate[0];
        } else if (inputFormat.toLowerCase() === 'yyyy/mm/dd') {
            dd = splitDate[2];
            mm = splitDate[1];
            yyyy = splitDate[0];
        } else if (inputFormat.toLowerCase() === 'yyyy-dd-mm') {
            dd = splitDate[1];
            mm = splitDate[2];
            yyyy = splitDate[0];
        } else if (inputFormat.toLowerCase() === 'mm-yyyy-dd') {
            dd = splitDate[2];
            mm = splitDate[0];
            yyyy = splitDate[1];
        } else if (inputFormat.toLowerCase() === 'dd-yyyy-mm') {
            dd = splitDate[2];
            mm = splitDate[0];
            yyyy = splitDate[1];
        } else if (inputFormat.toLowerCase() === 'mm-dd-yyyy') {
            dd = splitDate[1];
            mm = splitDate[0];
            yyyy = splitDate[2];
        } else if (inputFormat.toLowerCase() === 'mm/yyyy/dd') {
            dd = splitDate[2];
            mm = splitDate[0];
            yyyy = splitDate[1];
        } else if (inputFormat.toLowerCase() === 'ddmmyyyy') {
            dd = (date as string).substring(0, 2);
            mm = (date as string).substring(2, 4);
            yyyy = (date as string).substring(4, 8);
        } else if (inputFormat.toLowerCase() === 'yyyymmdd') {
            dd = (date as string).substring(6, 8);
            mm = (date as string).substring(4, 6);
            yyyy = (date as string).substring(0, 4);
        } else if (inputFormat.toLowerCase() === 'dd/yyyy/mm') {
            dd = splitDate[0];
            mm = splitDate[2];
            yyyy = splitDate[1];
        } else if (inputFormat.toLocaleLowerCase() === 'dd-mmm-yyyy') {
            dd = splitDate[0];
            if (splitDate[1].length < 3) {
                mm = splitDate[1];
            } else {
                month = splitDate[1];
            }
            yyyy = splitDate[2];
        } else {
            throw new Error('Date input format not defined');
        }
        if (!yyyy || yyyy?.trim()?.length <= 3) {
            return;
        }
        if (mm && mm.length > 2) {
            month = mm;
            mm = undefined;
        }
        if (!mm && month) {
            mm = CommonUtilsService.shortMonthsList[month];
        }
        dd = dd && dd.length < 2 ? '0' + dd : dd;
        mm = mm && mm.length < 2 ? '0' + mm : mm;
        ss?.length > 2 && (ss = (ss as string).substring(0, 2));
        if (hh && mm && ss) {
            const offsetTimeInMinutes = zoneOffsetTime || 0;
            minutes = parseInt(minutes, null) - (offsetTimeInMinutes || 0);
            const daysInMonth = new Date(parseInt(yyyy, null), parseInt(mm, null), 0).getDate();
            let preHh = hh;
            if (minutes >= 0) {
                /**
                 * Meta data time is behind the actual time
                 */
                // if (hh === '12') {
                //     hh = 0;
                // }
                if ((hh === '12' || hh === 12) && time === 'am') {
                    hh = 0;
                }
                if (minutes >= 60) {
                    hh = parseInt(hh, null) + Math.floor(minutes / 60);
                    minutes = minutes % 60;
                }
                if (hh >= 12) {
                    if (Math.floor(hh / 12) % 2 === 0) {
                        /** No change in AM/PM */
                    } else {
                        /** Change in AM/PM */
                        if (time === 'pm') {
                            if (parseInt(preHh, null) !== 12) {
                                time = 'am';
                            }
                        } else {
                            time = 'pm';
                        }
                        // dd = parseInt(dd, null) + Math.floor(hh / 24);
                        // hh = hh % 12;
                        hh = hh - 12;
                        dd = time === 'am' ? parseInt(dd, null) + 1 : parseInt(dd, null);
                    }
                    dd = parseInt(dd, null) + Math.floor(hh / 24);
                    hh = hh % 12;
                    if (time === 'pm' && hh === 0) {
                        hh = '12';
                    }
                }
                // else if (hh === '12' || hh === 12) {
                //     if (time === 'am') {
                //         time = 'pm';
                //     }
                // }
                if (dd > daysInMonth) {
                    mm = parseInt(mm, null) + Math.floor(dd / daysInMonth);
                    dd = dd % daysInMonth;
                }
                if (mm > 12) {
                    yyyy = parseInt(yyyy, null) + Math.floor(mm / 12);
                    mm = (typeof mm === 'string' ? parseInt(mm, null) : mm) % 12;
                }
            } else if (minutes < 0) {
                dd = parseInt(dd, null);
                preHh = parseInt(hh, null);
                const preHh24 = preHh < 12 && time === 'pm' ? preHh + 12 : preHh;
                let postHh24 = preHh24;
                if (minutes < -60) {
                    hh = parseInt(hh, null) - Math.ceil(-minutes / 60);
                    postHh24 -= Math.ceil(-minutes / 60);
                    minutes = (60 + (minutes % 60)) % 60;
                }
                if (hh <= 0) {
                    if (Math.ceil(-hh / 12) % 2 === 0) {
                        /** No change in AM/PM */
                    } else {
                        /** Change in AM/PM */
                        time = time === 'am' ? 'pm' : 'am';
                        // dd = parseInt(dd, null) + Math.floor(hh / 24);
                        // hh = hh % 12;
                        hh = hh + 12;
                        dd = time === 'pm' ? parseInt(dd, null) - 1 : parseInt(dd, null);
                    }
                    dd = parseInt(dd, null) - Math.ceil(-hh / 12);
                    // hh = 12 + (hh) % 12;
                } else {
                    if (time === 'am') {
                        // time = (hh === 0 || hh === 12) ? 'am' : 'pm';
                        if (((preHh === 12 || preHh === 0) && hh < 12) || (preHh24 < 12 && postHh24 < 0)) {
                            time = 'pm';
                        }
                        // dd = parseInt(dd, null) - 1;
                    } else {
                        // time = (hh === 0 || hh === 12) ? 'pm' : 'am';
                        if ((preHh === 12 && hh < 12) || (preHh24 > 12 && postHh24 < 12) || (preHh < 12 && hh === 12 && ss === 0)) {
                            time = 'am';
                        }
                        dd = parseInt(dd, null);
                    }
                }
                if (postHh24 >= 12) {
                    time = 'pm';
                }
                if (dd <= 0) {
                    const currentMonthIndex = parseInt(mm, null);
                    const newDaysInMonth = new Date(
                        parseInt(currentMonthIndex === 1 ? parseInt(yyyy, null) - 1 : yyyy, null),
                        currentMonthIndex - 1,
                        0
                    ).getDate();
                    // mm = parseInt(mm, null) - Math.ceil(-dd / newDaysInMonth);
                    mm = parseInt(mm, null) - 1;
                    dd = newDaysInMonth + (dd % newDaysInMonth);
                    if (dd > newDaysInMonth) {
                        dd = newDaysInMonth;
                    }
                }
                if (mm <= 0) {
                    yyyy = parseInt(yyyy, null) - Math.ceil(-mm / 12);
                    mm = 12 + (mm % 12);
                }
            }
        } else {
            const currentTimeStamp = new Date().getTime();
            let fromMidnightTimeStamp = currentTimeStamp - new Date(mm + '/' + dd + '/' + yyyy).getTime();
            const halfDayTimeStamp = 12 * 60 * 60 * 1000;
            if (fromMidnightTimeStamp >= halfDayTimeStamp) {
                time = 'pm';
                fromMidnightTimeStamp = fromMidnightTimeStamp - halfDayTimeStamp;
            }
            hh = Math.floor(fromMidnightTimeStamp / (1000 * 60 * 60));
            minutes = Math.floor(fromMidnightTimeStamp / (1000 * 60) - hh * 60);
            ss = Math.floor(fromMidnightTimeStamp / 1000 - (Math.floor(hh) * 60 * 60 + minutes * 60));
        }
        if (hh > 12) {
            hh = hh % 12;
        }
        if (hh === 0) {
            hh = 12;
        }
        dd = typeof dd === 'number' ? dd.toString() : dd;
        mm = typeof mm === 'number' ? mm.toString() : mm;
        hh = typeof hh === 'number' ? hh.toString() : hh;
        minutes = typeof minutes === 'number' ? minutes.toString() : minutes;
        ss = typeof ss === 'number' ? ss.toString() : ss;
        dd = dd && dd.length < 2 ? '0' + dd : dd;
        mm = mm && mm.length < 2 ? '0' + mm : mm;
        hh = hh && hh.length < 2 ? '0' + hh : hh;
        minutes = minutes.length < 2 ? '0' + minutes : minutes;
        ss = ss.length < 2 ? '0' + ss : ss;
        time = time.toUpperCase();
        if (outputFormat.toLowerCase() === 'dd/mm/yyyy') {
            outputDate = dd + '/' + mm + '/' + yyyy;
            output.push(outputDate);
        } else if (outputFormat.toLowerCase() === 'dd-mm-yyyy') {
            outputDate = dd + '-' + mm + '-' + yyyy;
            output.push(outputDate);
        } else if (outputFormat.toLowerCase() === 'mm/dd/yyyy') {
            outputDate = mm + '/' + dd + '/' + yyyy;
            output.push(outputDate);
        } else if (outputFormat.toLowerCase() === 'mm-dd-yyyy') {
            outputDate = mm + '-' + dd + '-' + yyyy;
            output.push(outputDate);
        } else if (outputFormat === 'DD-MM-YYYY HH:MM:SS AM') {
            outputDate = dd + '-' + mm + '-' + yyyy;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
            output.push(hh + ':' + minutes + ':' + ss);
            output.push(time);
        } else if (outputFormat === 'DD/MM/YYYY HH:MM:SS AM') {
            outputDate = dd + '/' + mm + '/' + yyyy;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
            output.push(hh + ':' + minutes + ':' + ss);
            output.push(time);
        } else if (outputFormat === 'dd-MMM-yyyy') {
            const mmm = Object.keys(CommonUtilsService.shortMonthsList).find(
                (monthString) => CommonUtilsService.shortMonthsList[monthString] === mm
            );
            outputDate = dd + '-' + mmm + '-' + yyyy;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
        } else if (outputFormat === 'yyyy-MM-dd') {
            outputDate = yyyy + '-' + mm + '-' + dd;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
        } else if (outputFormat === 'ddMMyyyy') {
            outputDate = dd + mm + yyyy;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
        } else if (outputFormat === 'yyyyMMdd') {
            outputDate = yyyy + mm + dd;
            output.push(outputDate);
        } else if (outputFormat === 'dd/mm/yyyy') {
            outputDate = dd + '/' + mm + '/' + yyyy;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
        } else if (outputFormat === 'dd/MM/yyyy') {
            outputDate = dd + '/' + mm + '/' + yyyy;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
        } else if (outputFormat === 'dd/yyyy/MM') {
            outputDate = dd + '/' + yyyy + '/' + mm;
            // outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
        } else if (outputFormat === 'mm/dd/yyyy') {
            outputDate = mm + '/' + dd + '/' + yyyy;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
        } else if (outputFormat === 'ddMMMyyyy HH:MM:SS AM') {
            const mmm = Object.keys(CommonUtilsService.shortMonthsList).find(
                (monthString) => CommonUtilsService.shortMonthsList[monthString] === mm
            );
            outputDate = dd + ' ' + mmm + ' ' + yyyy;
            output.push(outputDate);
            output.push(hh + ':' + minutes + ':' + ss);
            output.push(time);
        } else if (outputFormat === 'ddMMMyyyy') {
            const mmm = Object.keys(CommonUtilsService.shortMonthsList).find(
                (monthString) => CommonUtilsService.shortMonthsList[monthString] === mm
            );
            outputDate = dd + ' ' + mmm + ' ' + yyyy;
            output.push(outputDate);
        } else if (outputFormat === 'yyyy/dd/MM') {
            outputDate = `${yyyy}/${dd}/${mm}`;
            output.push(outputDate);
        } else if (outputFormat === 'yyyy-dd-MM') {
            outputDate = `${yyyy}-${dd}-${mm}`;
            output.push(outputDate);
        } else if (outputFormat === 'yyyy/MM/dd') {
            outputDate = `${yyyy}/${mm}/${dd}`;
            output.push(outputDate);
        } else if (outputFormat === 'MM-yyyy-dd') {
            outputDate = `${mm}-${yyyy}-${dd}`;
            output.push(outputDate);
        } else if (outputFormat === 'dd-yyyy-MM') {
            outputDate = `${mm}-${yyyy}-${dd}`;
            output.push(outputDate);
        } else if (outputFormat === 'MM-dd-yyyy') {
            outputDate = `${mm}-${dd}-${yyyy}`;
            output.push(outputDate);
        } else if (outputFormat === 'MM/yyyy/dd') {
            outputDate = `${mm}/${yyyy}/${dd}`;
            output.push(outputDate);
        } else if (outputFormat === 'MM-DD-YYYY HH:MM:SS AM') {
            outputDate = mm + '-' + dd + '-' + yyyy;
            outputDate = outputDate.replace(/\//g, '-');
            output.push(outputDate);
            output.push(hh + ':' + minutes + ':' + ss);
            output.push(time);
        } else if (outputFormat === 'DATE') {
            if (parseInt(hh) < 0) {
                hh = '00';
            }
            if (!hh || !minutes || !time) {
                return new Date(`${mm}-${dd}-${yyyy}`) as any;
            }
            return new Date(`${mm}-${dd}-${yyyy} ${hh}:${minutes} ${time}`) as any;
        } else if (outputFormat === 'TIMESTAMP') {
            if (parseInt(hh) < 0) {
                hh = '00';
            }
            if (!hh || !minutes || !time) {
                return new Date(`${mm}-${dd}-${yyyy}`).getTime() as any;
            }
            return new Date(`${mm}-${dd}-${yyyy} ${hh}:${minutes} ${time}`).getTime() as any;
        } else {
            throw new Error('Date output format not defined');
        }
        return output.join(' ');
    };

    /**
     * date: expected date formats
     *       DD/MM/YYYY (dd/mm/yyyy)
     *       MM/DD/YYYY (mm/dd/yyyy)
     *       DD/MM/YYYY HH:MM:SS AM (dd/mm/yyyy HH:MM:SS AM)
     *       MM/DD/YYYY HH:MM:SS AM (mm/dd/yyyy HH:MM:SS AM)
     */
    static transformDate = (
        date: string,
        inputFormat:
            | 'dd/mm/yyyy'
            | 'mm/dd/yyyy'
            | 'dd/MM/yyyy'
            | 'DD/MM/YYYY HH:MM:SS AM'
            | 'DD-MM-YYYY HH:MM:SS AM'
            | 'yyyy-mm-dd'
            | 'ddmmyyyy'
            | 'yyyymmdd'
            | 'dd-mm-yyyy'
            | 'dd/yyyy/MM'
            | 'dd-MMM-yyyy'
            | 'dd-mm-yyyy'
            | 'YYYY-MM-DDTHH:MM:SSZ'
            | 'DATE'
            | 'yyyy/dd/MM'
            | 'yyyy-MM-dd'
            | 'yyyy-dd-MM'
            | 'yyyy/MM/dd'
            | 'dd-yyyy-MM'
            | 'MM-yyyy-dd'
            | 'MM/yyyy/dd'
            | 'MM-dd-yyyy',
        outputFormat:
            | 'dd/mm/yyyy'
            | 'mm/dd/yyyy'
            | 'mm-dd-yyyy'
            | 'dd/MM/yyyy'
            | 'DD/MM/YYYY HH:MM:SS AM'
            | 'DD-MM-YYYY HH:MM:SS AM'
            | 'dd-MMM-yyyy'
            | 'yyyy-MM-dd'
            | 'ddMMyyyy'
            | 'yyyyMMdd'
            | 'dd/yyyy/MM'
            | 'ddMMMyyyy HH:MM:SS AM'
            | 'ddMMMyyyy'
            | 'MM-DD-YYYY HH:MM:SS AM'
            | 'dd-mm-yyyy'
            | 'yyyy/dd/MM'
            | 'yyyy-dd-MM'
            | 'yyyy/MM/dd'
            | 'dd-yyyy-MM'
            | 'MM-yyyy-dd'
            | 'MM/yyyy/dd'
            | 'MM-dd-yyyy'
            | 'DATE'
    ) => CommonUtilsService.transformDateToLocale(date, inputFormat, outputFormat, true);

    /**
     *
     * @param date Date Object
     * Output dd-MM-yyyy
     *
     */
    static transformDateToDefaultFormat = (date, type?) => {
        if (!date) {
            return;
        }
        const givenDate = new Date(date);
        let dd: any = givenDate.getDate();
        let MM: any = givenDate.getMonth() + 1;
        const yyyy = givenDate.getFullYear();
        let hh: any = givenDate.getHours();
        let minutes: any = givenDate.getMinutes();
        let ss: any = givenDate.getSeconds();
        let time: any;
        if (hh > 12) {
            time = 'PM';
        } else {
            time = 'AM';
        }
        dd = ('' + dd).length < 2 ? '0' + dd : dd;
        MM = ('' + MM).length < 2 ? '0' + MM : MM;
        hh = ('' + hh).length < 2 ? '0' + hh : hh;
        minutes = ('' + minutes).length < 2 ? '0' + minutes : minutes;
        ss = ('' + ss).length < 2 ? '0' + ss : ss;
        if (type === 'dateTime') {
            return dd + '-' + MM + '-' + yyyy + ' ' + hh + ':' + minutes + ':' + ss + ' ' + time;
        } else {
            return dd + '-' + MM + '-' + yyyy;
        }
    };

    /**
     * To check whether date in iso format or not
     * @param date
     */
    static isIsoFormat = (date) => {
        if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) {
            return true;
        } else if (/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})[+-](\d{2})\:(\d{2})/.test(date)) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Creating a deep copy of an object
     */
    static cloneObject = (dataObject): any => {
        if (dataObject instanceof MatDrawer) {
            return dataObject;
        }
        if (typeof dataObject === 'object') {
            let newObj = dataObject instanceof Array ? [] : {};
            if (
                typeof dataObject === 'object' &&
                !Array.isArray(dataObject) &&
                dataObject.constructor.name !== undefined &&
                dataObject.constructor.name !== 'Object'
            ) {
                newObj = new dataObject.constructor();
            }
            for (const i in dataObject) {
                if (i === 'clone') {
                    continue;
                }
                if (dataObject[i] && typeof dataObject[i] === 'object') {
                    // Filtering case for Date whose type is 'object'
                    if (dataObject[i] instanceof File) {
                        newObj[i] = dataObject[i];
                    } else if (Object.prototype.toString.call(dataObject[i]) === '[object Date]') {
                        newObj[i] = dataObject[i];
                    } else {
                        newObj[i] = CommonUtilsService.cloneObject(dataObject[i]);
                    }
                } else if (typeof dataObject[i] !== 'function') {
                    newObj[i] = dataObject[i];
                }
            }
            return newObj;
        } else {
            return dataObject;
        }
    };

    static dataObjectContainsPrimitiveDataOnly = (dataObject: any[]) => {
        return dataObject.filter((item) => ['bigint', 'boolean', 'number', 'string'].indexOf(typeof item) === -1).length === 0;
    };

    static getMaproDataType = (macreDatatype: string) => {
        const map = {
            int: 'NUMBER',
            long: 'BIG_NUMBER',
            float: 'FLOAT',
            double: 'DECIMAL',
            char: 'CHAR',
            string: 'TEXT',
            date: 'TIMESTAMP',
            boolean: 'BOOLEAN',
        };
        return map[macreDatatype];
    };

    getEpochDateFormat = (originalTime, originalDate, triggerOrigin?: string, isIsoFormat?: boolean, defaultTime?: string) => {
        let newTime, newH, newDate, newM, newDD, newMM, newYY, dayTime;
        if (triggerOrigin && triggerOrigin === 'IE' && this.isBrowserIE()) {
            originalDate = CommonUtilsService.transformDateToLocale(
                originalDate,
                'dd/mm/yyyy',
                'dd/mm/yyyy',
                false
            ); /* checks  if month value is single or double digit*/
            newTime = Object.assign({}, originalTime);
            newDate = Object.assign({}, originalDate);
            if (defaultTime?.length > 0) {
                if (defaultTime === 'startTime') {
                    newH = '00';
                    newM = '00';
                    dayTime = 'AM';
                }
                if (defaultTime === 'endTime') {
                    newH = '11';
                    newM = '59';
                    dayTime = 'PM';
                }
            } else {
                if (Object.keys(newTime).length === 13) {
                    /* this is the case when the time is 12:00 */
                    newH = `${newTime[1]}${newTime[2]}`;
                    newM = `${newTime[6]}${newTime[7]}`;
                    dayTime = `${newTime[11]}${newTime[12]}`;
                } else {
                    /* this case takes care of 9:00 */
                    newH = `${newTime[1]}`;
                    newM = `${newTime[5]}${newTime[6]}`;
                    dayTime = `${newTime[10]}${newTime[11]}`;
                }
            }
            newDD = `${newDate[0]}${newDate[1]}`;
            newMM = `${newDate[3]}${newDate[4]}`;
            newYY = `${newDate[6]}${newDate[7]}${newDate[8]}${newDate[9]}`;
        }
        const splitTimeStamp = originalTime.split(' ');
        const splitTime = splitTimeStamp[0]?.split(':');
        const splitDate = originalDate.split(originalDate.indexOf('-') > -1 ? '-' : '/');
        const time = splitTimeStamp[1]?.toLowerCase();
        // time
        let hh: any = parseInt(newH ?? splitTime[0]);
        let minutes: any = parseInt(newM ?? splitTime[1]);
        //  date
        let dd: any = parseInt(newDD ?? splitDate[0]);
        let mm: any = parseInt(newMM ?? splitDate[1]);
        let yyyy: any = parseInt(newYY ?? splitDate[2]);

        if (hh === 12 && (dayTime?.toLowerCase() ?? time) === 'am') {
            hh = 0;
        }
        if ((dayTime?.toLowerCase() ?? time) === 'pm' && hh !== 12) {
            hh = 12 + hh;
        }
        if (isIsoFormat) {
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            if (hh < 10) {
                hh = '0' + hh;
            }
            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            const isoDateFormat =
                yyyy.toString() + '-' + mm.toString() + '-' + dd.toString() + 'T' + hh.toString() + ':' + minutes.toString() + ':00';
            // const returnDate = new Date(yyyy, mm - 1, dd, hh, minutes, 0);
            // const isoDateFormat = returnDate.toISOString();
            return isoDateFormat;
        } else {
            const returnDate = new Date(yyyy, mm - 1, dd, hh, minutes, 0);
            const returnTime = returnDate.getTime();
            return returnTime;
        }
    };

    setOffsetTime = () => {
        const session = this.getFromStorage('session');
        if (session) {
            let tzOffset = this.getFromStorage('timeZoneOffset');
            if (tzOffset === undefined) {
                if (this.setOffsetTimeout) {
                    clearTimeout(this.setOffsetTimeout);
                }
                this.setOffsetTimeout = setTimeout(() => {
                    this.setOffsetTime();
                }, 2000);
            } else {
                if (typeof tzOffset === 'object' || isNaN(parseInt(tzOffset))) {
                    // tzOffset = 0;
                    tzOffset = -330;
                }
                CommonUtilsService.offsetTime = parseInt(tzOffset);
                if (this.setOffsetTimeout) {
                    clearTimeout(this.setOffsetTimeout);
                }
            }
        }
    };

    /**
     * Setting values in Local storage
     */
    public static setInStorage = (property, value, fromSessionStorage?: boolean) => {
        // this.log(property, value, typeof value);
        if ([undefined, null].indexOf(value) > -1) {
            CommonUtilsService.removeFromStorage(property);
            return;
        }
        let valueToStore = value;
        if (typeof value === 'object') {
            valueToStore = JSON.stringify(value);
        }
        const storage = fromSessionStorage ? sessionStorage : localStorage;
        storage.setItem(property.toLowerCase(), valueToStore);
    };
    public setInStorage = CommonUtilsService.setInStorage;

    /**
     * Retrieving values from Local storage
     */
    static getFromStorage = (property, fromSessionStorage?: boolean) => {
        const storage = fromSessionStorage ? sessionStorage : localStorage;
        const value = storage.getItem(property.toLowerCase());
        let returnValue;
        try {
            if (value === undefined || value === null) {
                returnValue = value;
            } else if (value.indexOf('[') > -1 || value.indexOf('{') > -1 || value === 'null' || value === 'true' || value === 'false') {
                returnValue = JSON.parse(value);
            } else {
                returnValue = value;
            }
        } catch (error) {
            returnValue = value;
        }
        return returnValue === 'undefined' || !returnValue ? undefined : returnValue;
    };
    public getFromStorage = CommonUtilsService.getFromStorage;

    /**
     * Removing a value from Local storage
     */
    public static removeFromStorage = (property, fromSessionStorage?: boolean) => {
        if (property && property.length > 0) {
            const storage = fromSessionStorage ? sessionStorage : localStorage;
            storage.removeItem(property.toLowerCase());
        }
    };
    public removeFromStorage = CommonUtilsService.removeFromStorage;

    clearStorage = () => {
        this.clearTaxillaLocalStorage();
    };

    clearTaxillaLocalStorage = () => {
        const keys = Object.keys(localStorage);
        keys.filter((key) => !key.startsWith('macre-'))
            .filter((key) => ['colors', 'ui-schema'].indexOf(key) === -1)
            .forEach((key) => {
                // This is not a macre related key
                localStorage.removeItem(key);
            });
    };

    static compareObjects = (obj1: any, obj2: any) => {
        if (obj1 === obj2) {
            return true;
        }
        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
            return false;
        }
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            if (!keys2.includes(key) || !CommonUtilsService.compareObjects(obj1[key], obj2[key])) {
                return false;
            }
        }
        return true;
    };

    setBodyContext = (className: string) => {
        if (this.getCookie('new-ui') === 'true') {
            className += ' onNewUI';
        }
        const body = document.querySelector('body');
        const newClasses = className.split(' ');
        body.classList.forEach((classItem) => {
            !newClasses.includes(classItem) && body.classList.remove(classItem);
        });
        newClasses.forEach((classItem) => {
            !body.classList.contains(classItem) && body.classList.add(classItem);
        });
    };

    filterObjectProperties = (filters, obj) => {
        const filteredObj = Object.keys(obj).reduce((acc, key) => {
            if (!filters.includes(key)) {
                acc[key] = obj[key];
            }
            return acc;
        }, {});
        return filteredObj;
    };

    fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        let isCopied = false;
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                isCopied = true;
            } else {
                isCopied = false;
            }
        } catch (error) {
            isCopied = false;
        }
        document.body.removeChild(textArea);
        return isCopied;
    };

    copyTextToClipboard = (text, s?, f?) => {
        let isCopied = false;
        if (!navigator.clipboard) {
            isCopied = this.fallbackCopyTextToClipboard(text);
            if (isCopied) {
                s && s();
            } else {
                f && f();
            }
        } else {
            navigator.clipboard.writeText(text).then(
                () => {
                    s && s();
                },
                () => {
                    f && f();
                }
            );
        }
    };

    // method to check object empty or not
    isEmpty = (obj) => {
        return obj === null || undefined
            ? true
            : (() => {
                  for (const prop in obj) {
                      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                          return false;
                      }
                  }
                  return true;
              })();
    };

    getTimeZoneOffset = (timeZone) => {
        if (!timeZone) {
            return;
        }
        const allTimeZones = this.getAllTimeZones();
        const timeZoneStr = allTimeZones[timeZone]; // Expected value "(UTC+05:30)"
        if (timeZoneStr) {
            const signedTime = timeZoneStr.substring(timeZoneStr.indexOf('UTC') + 3, timeZoneStr.length - 1);
            const time = signedTime.substring(1).split(':');
            let minutes = parseInt(time[0], null) * 60 + parseInt(time[1], undefined);
            if (signedTime[0] === '+') {
                minutes = 0 - minutes;
            }
            return minutes;
        } else {
            return 0;
        }
    };

    getAllTimeZones = () => {
        return (timezones as any).default;
    };

    redirectToPage = (url: string, forceLoad?: boolean) => {
        const origin = window.location.origin;
        if (url.indexOf('/vdm/') > -1) {
            window.location.href = url;
        } else if (url.indexOf(origin) === 0 && !forceLoad) {
            const redirect = url.substring(origin.length);
            this._router.navigateByUrl(redirect);
        } else {
            window.location.href = url;
        }
    };

    getCookie = (key: string) => {
        return (new RegExp((key || '=') + '=(.*?); ', 'gm').exec(document.cookie + '; ') || ['', null])[1];
    };

    setCookie(key: string, value: string, exdays?: number, domain?: string, httpOnly?: boolean, path?: string) {
        let domainValue = '';
        let httpOnlyValue = '';
        let pathValue = '';
        const d = new Date();
        d.setTime(d.getTime() + (exdays || 15) * 24 * 60 * 60 * 1000);
        const expires = '; expires=' + d.toUTCString();
        domain?.length > 0 && (domainValue = `; domain=${domain}`);
        httpOnly && (httpOnlyValue = `; httpOnly`);
        const secure = window.location.protocol === 'https:' ? '; Secure' : '';
        path = path || '/';
        pathValue = `; path=${path}`;
        document.cookie = `${key}=${value}${expires}${domainValue}${httpOnlyValue}${secure}${pathValue}`;
    }

    deleteCookie = (key: string, domain?: string, httpOnly?: boolean, path?: string) => {
        let domainValue = '';
        let httpOnlyValue = '';
        let pathValue = '';
        domain?.length > 0 && (domainValue = `; domain=${domain}`);
        httpOnly && (httpOnlyValue = `; httpOnly`);
        path = path || '/';
        pathValue = `; path=${path}`;
        const expires = '; expires=Thu, 01 Jan 1970 00:00:00 UTC';
        document.cookie = `${key}= ${expires}${domainValue}${httpOnlyValue}${pathValue}`;
    };

    isBrowserIE = () => {
        return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    };

    /**
     *
     * @param arr1
     * @param arr2
     * @returns
     */
    hasCommonElements = (arr1, arr2) => {
        return arr1.some((item) => arr2.includes(item));
    };

    getActiveLanguage = () => this._translate.getActiveLang();

    getDefaultLanguage = () => this._translate.getDefaultLang();

    setActiveLanguage = (language: string) => this._translate.setActiveLang(language);

    getAvailableLanguages = () => this._translate.getAvailableLangs();

    getLocaleLangugae = () => {
        let locale: string = '';
        switch (this.getActiveLanguage()) {
            case 'ar':
                locale = 'ar-SA';
                break;
            case 'sr-latn':
                locale = 'sr-latn';
                break;
            case 'sr-cyrl':
                locale = 'sr-cyrl';
                break;
            case 'pl':
                locale = 'pl';
                break;
            case 'ja':
                locale = 'ja';
                break;
            case 'ro':
                locale = 'ro';
                break;
            case 'fr':
                locale = 'fr-FR';
                break;
            default:
                locale = 'en-US';
                break;
        }
        return locale;
    };

    isObject = (obj) => {
        return obj && typeof obj === 'object' && obj instanceof Object && !(obj instanceof Array);
    };

    static getDataType = (dataType: string) => {
        const dt = dataType && dataType.toLowerCase();
        let allowedDT = '';
        switch (dt) {
            case 'string':
                allowedDT = 'string';
                break;
            case 'textarea':
                allowedDT = 'textarea';
                break;
            case 'boolean':
                allowedDT = 'boolean';
                break;
            case 'date':
                allowedDT = 'date';
                break;
            case 'double':
                allowedDT = 'number';
                break;
            case 'int':
                allowedDT = 'number';
                break;
            case 'number':
                allowedDT = 'number';
                break;
            case 'checkbox':
                allowedDT = 'category';
                break;
            case 'equals':
                allowedDT = 'equals';
                break;
            case 'options':
                allowedDT = 'multiselect';
                break;
            case 'long':
                allowedDT = 'number';
                break;
            default:
                allowedDT = 'string';
                break;
        }
        return allowedDT;
    };

    static getOperatorsPerDataType = (dt?) => {
        const operatorsPerDataType = {
            date: ['EQ', 'NOT_EQUALS', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
            number: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
            textarea: ['EQ', 'NOT_EQUALS', 'CONTAINS', 'is null', 'is not null'],
            string: ['EQ', 'NOT_EQUALS', 'IN', 'CONTAINS', 'is null', 'is not null'],
            options: ['EQ', 'NOT_EQUALS', 'IN', 'is null', 'is not null'],
            equals: ['EQ', 'NOT_EQUALS'],
            boolean: ['EQ', 'NOT_EQUALS', 'is null', 'is not null'],
        };

        if (dt) {
            return operatorsPerDataType[dt];
        }

        return operatorsPerDataType;
    };

    static getElasticSearchObject = (field: any, searchConfig, options: { name: string; value: string }[]) => {
        const dataType = CommonUtilsService.getDataType(field.datatype);
        const id = field.id;
        if (!id) {
            return;
        }
        searchConfig[id] = {
            name: field.displayName || field.name,
            type: options ? options : dataType,
            operators: CommonUtilsService.getOperatorsPerDataType(dataType),
            nullable: false,
            options: options,
        };
        switch (dataType) {
            case 'number':
                searchConfig[id]['minimum'] = 0;
                break;
            case 'category':
                searchConfig[id]['choices'] = ['SUCCESS', 'ERROR'];
                break;
            case 'date':
                searchConfig[id]['defaultValue'] = new Date();
                searchConfig[id]['format'] = field.format || 'dd-mm-yyyy';
                break;
            case 'boolean':
                searchConfig[id]['options'] = [
                    {
                        name: 'true',
                        value: 'true',
                    },
                    {
                        name: 'false',
                        value: 'false',
                    },
                ];
                break;
        }
    };

    static getElasticSortObject = (field: any, contObj) => {
        if (!field || !field.id) {
            return;
        }
        contObj[field.id] = {
            name: field.displayName || field.name,
            type: 'category',
            operators: ['='],
            nullable: false,
            options: [
                {
                    name: translate('Ascending'),
                    value: 'ASC',
                },
                {
                    name: translate('Descending'),
                    value: 'DESC',
                },
            ],
        };
    };

    getElementStyle = (
        computeElement: HTMLElement,
        property: 'padding-left' | 'padding-right' | 'padding-top' | 'padding-bottom' | 'width'
    ) => {
        return (computeElement && parseInt(getComputedStyle(computeElement).getPropertyValue(property), 10)) || 0;
    };

    setElementStyle = (element: HTMLElement, property: 'width' | 'height', value: number, unit: 'px') => {
        element.style.setProperty(property, `${value}${unit}`);
    };
    /**
     * Method to convert minutes to milliseconds
     * @param minutes is the number of minutes
     * @returns milliseconds of the minutes
     */
    convertMinutesToMilliseconds = (minutes: number) => minutes * 60 * 1000;

    static isOlderThanGivenTime = (date: string, ageInDays: number) => {
        const dateTimeStamp = new Date(date)?.getTime();
        const ageInTimeStamp = new Date().getTime() - ageInDays * 24 * 60 * 60 * 1000;
        return dateTimeStamp < ageInTimeStamp;
    };

    static getRange = (startFrom: number, until: number) => {
        return Array.from({ length: until + 1 - startFrom }, (_, k) => k + startFrom);
    };

    static readonly DATE_FORMATS = [
        'DD/MM/YYYY',
        'MM/DD/YYYY',
        'DD/MM/YYYY',
        'DD/MM/YYYY',
        'DD-MM-YYYY',
        'YYYY-MM-DD',
        'DDMMYYYY',
        'YYYYMMDD',
        'DD-MM-YYYY',
        'DD/YYYY/MM',
        'DD-MMM-YYYY',
        'YYYY-MM-DD',
        'YYYY/DD/MM',
        'YYYY-MM-DD',
        'YYYY-DD-MM',
        'YYYY/MM/DD',
        'DD-YYYY-MM',
        'MM-YYYY-DD',
        'MM/YYYY/DD',
        'MM-DD-YYYY',
    ];

    static formatAnyDateStringToSpecificFormat(
        dateStr: string,
        outputFormat: string
    ): { isValid: boolean; formattedDate?: string; error?: string } {
        try {
            if (!dateStr || !outputFormat) {
                return { isValid: false, error: 'Input date string and output format are required' };
            }

            // Remove any whitespace and convert to uppercase for consistency
            dateStr = dateStr.trim().toUpperCase();
            const originalOutputFormat = outputFormat; // Keep original format for later
            outputFormat = outputFormat.toUpperCase();

            let day: number;
            let month: number;
            let year: number;

            // Special handling for DDMMYYYY and YYYYMMDD formats
            if (dateStr.length === 8 && !dateStr.includes('/') && !dateStr.includes('-')) {
                if (/^\d{8}$/.test(dateStr)) {
                    // Try DDMMYYYY
                    day = parseInt(dateStr.substring(0, 2));
                    month = parseInt(dateStr.substring(2, 4));
                    year = parseInt(dateStr.substring(4));

                    if (!this.isValidDate(day, month, year)) {
                        // Try MMDDYYYY
                        month = parseInt(dateStr.substring(0, 2));
                        day = parseInt(dateStr.substring(2, 4));
                        year = parseInt(dateStr.substring(4));
                        if (!this.isValidDate(day, month, year)) {
                            // Try YYYYMMDD
                            year = parseInt(dateStr.substring(0, 4));
                            month = parseInt(dateStr.substring(4, 6));
                            day = parseInt(dateStr.substring(6));

                            if (!this.isValidDate(day, month, year)) {
                                return { isValid: false, error: 'Invalid date values' };
                            }
                        }
                    }
                } else {
                    return { isValid: false, error: 'Invalid numeric format' };
                }
            } else {
                // Handle formats with separators
                const separator = dateStr.includes('/') ? '/' : dateStr.includes('-') ? '-' : null;
                if (!separator) {
                    return { isValid: false, error: 'Invalid date format: missing separator' };
                }

                const parts = dateStr.split(separator);
                if (parts.length !== 3) {
                    return { isValid: false, error: 'Invalid date format: must have 3 parts' };
                }

                // Handle month names
                if (parts[1].length >= 3 && isNaN(Number(parts[1]))) {
                    const monthStr = parts[1].substring(0, 3);
                    const numericMonth = CommonUtilsService.getMonthNumber(monthStr);
                    if (numericMonth === 0) {
                        return { isValid: false, error: 'Invalid month name' };
                    }
                    parts[1] = '' + numericMonth;
                }

                // Try each format to find the correct date parts
                let found = false;
                for (const format of CommonUtilsService.DATE_FORMATS) {
                    if (format.includes(separator)) {
                        const dateParts = this.extractDateParts(parts.join(separator), format);

                        if (dateParts && this.isValidDate(dateParts.day, dateParts.month, dateParts.year)) {
                            day = dateParts.day;
                            month = dateParts.month;
                            year = dateParts.year;
                            found = true;
                            break;
                        }
                    }
                }

                if (!found) {
                    return { isValid: false, error: 'Could not parse date with any known format' };
                }
            }

            // At this point we have valid day, month, and year
            const formattedDay = day.toString().padStart(2, '0');
            let formattedMonth: string;

            // Check if the output format contains 'MMM'
            if (originalOutputFormat.includes('MMM')) {
                formattedMonth = this.monthNames[month - 1]; // Convert to month name
            } else {
                formattedMonth = month.toString().padStart(2, '0');
            }

            const formattedYear = year.toString();

            // Replace format tokens with actual values
            let formattedDate = outputFormat
                .replace(/DD/g, formattedDay)
                .replace(/MMM/g, formattedMonth)
                .replace(/MM/g, formattedMonth)
                .replace(/YYYY/g, formattedYear);

            return { isValid: true, formattedDate };
        } catch (error) {
            return { isValid: false, error: 'Error processing date' };
        }
    }

    private static getMonthNumber = (monthName: string) => {
        return CommonUtilsService.monthNames.findIndex((month) => month.toLowerCase() === monthName.toLowerCase()) + 1;
    };

    private static isValidDate(day: number, month: number, year: number): boolean {
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return false;
        }

        if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1000 || year > 9999) {
            return false;
        }

        const date = new Date(year, month - 1, day);
        return date.getDate() === day && date.getMonth() + 1 === month && date.getFullYear() === year;
    }

    private static extractDateParts(dateStr: string, format: string): { day: number; month: number; year: number } | null {
        try {
            const separator = dateStr.includes('/') ? '/' : dateStr.includes('-') ? '-' : null;
            if (!separator) return null;

            const dateParts = dateStr.split(separator);
            const formatParts = format.split(separator);

            if (dateParts.length !== 3 || formatParts.length !== 3) return null;

            let day: number | undefined;
            let month: number | undefined;
            let year: number | undefined;

            for (let i = 0; i < formatParts.length; i++) {
                const value = parseInt(dateParts[i]);
                if (isNaN(value)) return null;

                if (formatParts[i].includes('DD')) {
                    day = value;
                } else if (formatParts[i].includes('MM')) {
                    month = value;
                } else if (formatParts[i].includes('YYYY')) {
                    year = value;
                }
            }

            if (day !== undefined && month !== undefined && year !== undefined) {
                return { day, month, year };
            }
        } catch (error) {
            return null;
        }
        return null;
    }
}
