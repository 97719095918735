import { createReducer, on } from '@ngrx/store';

import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { SessionLogoutSuccessfull } from '../actions';
import * as actions from '../actions/gst-filing.actions';
import { GSTFilingState, gstFilingState } from '../states/gst-filing.state';

export const GST_FILING_REDUCER = createReducer(
    gstFilingState,
    on(actions.SetFilingYear, (state, action) => {
        return {
            ...state,
            currentYear: action.year,
        };
    }),
    on(actions.SetFilingMonth, (state, action) => {
        return {
            ...state,
            currentMonth: action.month,
            currentQuarter: action.quarter,
        };
    }),
    on(actions.SetFilingAppGroup, (state, action) => {
        return {
            ...state,
            currentAppGroup: action.app,
        };
    }),
    on(actions.SetFilingReconRequestsPagination, (state, action) => {
        if (!action.organizationId) {
            return state;
        }
        let month = state.currentMonth;
        let year = state.currentYear;
        year = year || '' + new Date().getFullYear();
        const dateMonth = new Date().getMonth() + 1;
        const date = dateMonth > parseInt(month) ? new Date(parseInt(year), dateMonth, 0).getDate() + '' : new Date().getDate() + '';
        const fromDate = (year && month && `${year}-${month?.length === 1 ? `0${month}` : month}-01`) || '';
        let toDate =
            (year && month && `${year}-${month?.length === 1 ? `0${month}` : month}-${date.length === 1 ? `0${date}` : date}`) || '';
        if (year && month && (CommonUtilsService.transformDateToLocale(toDate, 'yyyy-mm-dd', 'DATE', true) as any) > new Date()) {
            toDate = CommonUtilsService.transformDateToLocale(new Date() as any, 'DATE', 'yyyy-MM-dd', true) as string;
        }
        const criteria = `${fromDate}|${toDate}`;
        const criteriaState = state.reconPagination?.[action.organizationId]?.[action.serviceId]?.[criteria];
        const pageIndex = action.pageIndex !== undefined ? action.pageIndex : criteriaState?.pageIndex || 0;
        const criteriaObject = {
            ...criteriaState,
            pageIndex,
            pageSize: action.size || criteriaState?.pageSize || 5,
        };
        return {
            ...state,
            reconPagination: {
                ...state.reconPagination,
                [action.organizationId]: {
                    ...state.reconPagination?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.reconPagination?.[action.organizationId]?.[action.serviceId],
                        [criteria]: criteriaObject,
                    },
                },
            },
        };
    }),
    on(actions.SetFilingReconRequestsPagingState, (state, action) => {
        if (!action.organizationId) {
            return state;
        }
        const date = new Date().getDate();
        const fromDate = `${state.currentYear}-${state.currentMonth}-${date}`;
        const toDate = `${state.currentYear}-${state.currentMonth}-${date}`;
        const criteria = `${fromDate}|${toDate}`;
        const criteriaState = state.reconPagination?.[action.organizationId]?.[action.serviceId]?.[criteria];
        const pageIndex = criteriaState?.pageIndex;
        return {
            ...state,
            reconPagination: {
                ...state.reconPagination,
                [action.organizationId]: {
                    ...state.reconPagination?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.reconPagination?.[action.organizationId]?.[action.serviceId],
                        [criteria]: {
                            ...criteriaState,
                            [pageIndex]: action.pagingState || criteriaState?.pagingState?.[pageIndex],
                        },
                    },
                },
            },
        };
    }),
    on(actions.SetFilingSelectedInstanceDetails, (state, action) => {
        return {
            ...state,
            selected: {
                ...state.selected,
                serviceId: action.serviceId || state.selected.serviceId,
                instanceId: action.instanceId || state.selected.instanceId,
                detachedSource1EntityId: undefined,
                detachedSource2EntityId: undefined,
                nonDetachedSourceEntityId: undefined,
            },
        };
    }),
    on(actions.SetFilingInstanceEntityIds, (state, action) => {
        return {
            ...state,
            selected: {
                ...state.selected,
                detachedSource1EntityId: action.detachedSource1EntityId,
                detachedSource2EntityId: action.detachedSource2EntityId,
                nonDetachedSourceEntityId: action.nonDetachedSourceEntityId,
            },
        };
    }),
    on(actions.SetFilingInstanceEntityView, (state, action) => {
        const updatedState: GSTFilingState = {
            ...state,
            selected: {
                ...state.selected,
                currentViewLevel: action.viewLevel,
                viewLevel: {
                    ...state.selected?.viewLevel,
                    [action.viewLevel]: {
                        entityId: action.entityId,
                        recordId: action.recordId,
                    },
                },
            },
        };
        Object.keys(updatedState.selected.viewLevel).forEach((level) => {
            if (parseInt(level) > updatedState.selected.currentViewLevel) {
                delete updatedState.selected.viewLevel[level];
            }
        });
        return updatedState;
    }),
    on(actions.SetCustomPeriodicSearch, (state, action) => ({
        ...state,
        customPeriodicSearch: {
            ...state.customPeriodicSearch,
            [action.serviceId]: {
                ...state.customPeriodicSearch?.[action.serviceId],
                [action.assetMetaUID]: action.customPeriodicSearch,
            },
        },
    })),
    on(SessionLogoutSuccessfull, () => gstFilingState)
);

export const GST_FILING_REDUCER_KEY = 'gstFiling';
