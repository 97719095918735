<ng-container *transloco="let translate;">
    <ng-container *ngFor="let criteria of parameters">
        <ng-container [ngSwitch]="criteria.fullId">
            <ng-container *ngSwitchCase="'search criteria'">
                <div class="sourceSectionHeader text-right mb-2">
                    <span class="relatedTenants">
                        <material-checkbox [name]="translate('Check Related Tenants')" [disabled]="true" [model]="criteria?.search?.filterCriteria?.unitConsiderationEnabled" [showName]="true">
                        </material-checkbox>
                    </span>
                </div>
                <div class="sourceSectionDetails">
                    <mat-tab-group class="w-100" [selectedIndex]="criteria?.selectedSearchTab">
                        <mat-tab label="{{translate('Rules')}}">
                            <app-search-builder [search]="criteria?.search" [searchType]="'SEARCH'"></app-search-builder>
                        </mat-tab>
                        <mat-tab label="{{translate('Relations')}}">
                            <div class="relationsTab pl-3">
                                <div class="queryBuilderMainCntr">
                                    <app-search-builder [search]="criteria?.search" [showQueryBuilderDirectly]="true" searchType="RELATION"></app-search-builder>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div class="requestReviewDataContainer">
                    <div class="row">
                        <div class="reviewSelectedOrganizationsHeader requestReviewFieldLabel col-3 noPadding">
                            {{translate('Selected Organizations')}} :
                        </div>
                        <div class="reviewTenantNamesDisplayContainer col-9 noPadding">
                            <div class="row">
                                <ng-container *ngFor='let orgid of allTenantsMap | ObjNgFor; let i = index;'>
                                    <ng-container *ngIf="criteria?.unitIds?.includes(orgid)">
                                        <div class="reviewTenantNameDisplay col-4 noPadding">
                                            {{allTenantsMap[orgid].displayTenantName || allTenantsMap[orgid].name}}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
