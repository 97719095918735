import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';
import { CommonUtilsService } from '../../../services/commonutils/common-utils.service';

@Component({
    selector: 'material-input-date',
    templateUrl: './inputdate.component.html',
    styleUrls: ['./inputdate.component.scss'],
})
export class MaterialInputDateComponent implements OnInit, OnChanges, OnDestroy {
    @Input() placeholder;
    @Input() name;
    @Input() title;
    @Input() model;
    @Input() id;
    @Input() classList;
    @Input() debounceAfter: number;
    @Input() skipStartDate;
    @Input() required: boolean;
    @Input() errors: any[];
    @Input() warnings: any[];
    @Input() disabled: boolean;
    @Input() description: string;
    @Input() dateFormat: string = 'dd/mm/yyyy';
    @Input() inputOnlyDisabled: boolean;
    @Input() appearance: MatFormFieldAppearance;
    @Input() formFieldClass: string;
    @Input() isSearchField: boolean;
    @Input() label: string;
    @Input() minDate: string;
    @Input() minDateFormat: string;
    @Input() maxDate: string;
    @Input() maxDateFormat: string;

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();

    matcher = new ModifiedErrorStateMatcher();
    minDateValue: Date;
    maxDateValue: Date;
    mannualInput: any;
    originalInput: any;

    constructor(private _commonUtils: CommonUtilsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.model) {
            this.applyChangesToDate(changes.model.currentValue);
        }
        if (changes?.minDate || changes?.maxDate) {
            this.applyDateRestriction();
        }
    }

    onInput = (event: any) => {
        this.originalInput = event.target.value;
    };

    onFocusOut = (event: any) => {
        this.errors = [];
        if (event.target.value && this.dateFormat) {
            let result: { isValid: boolean; formattedDate?: string; error?: string } =
                CommonUtilsService.formatAnyDateStringToSpecificFormat(event.target.value, this.dateFormat);
            if (result.isValid) {
                this.applyChangesToDate(result.formattedDate);
            } else {
                this.errors.push('Please enter a valid date');
            }
        } else {
            this.mannualInput = undefined;
            this.errors = [];
        }
    };

    applyChangesToDate = (date: string) => {
        if (date === undefined) {
            this.originalInput = undefined;
            this.errors = [];
        }
        let dateString: string;
        if ((date as any) instanceof Date) {
            dateString = date;
        } else {
            dateString = this._commonUtils.transformDateToLocale(date, this.dateFormat as any, 'DATE', true);
        }
        this.mannualInput = dateString;
        this.emitChanges(dateString);
    };

    private applyDateRestriction = () => {
        if (this.minDate && this.minDateFormat) {
            this.minDateValue = CommonUtilsService.transformDate(this.minDate, this.minDateFormat as any, 'DATE') as any;
        } else {
            this.minDateValue = undefined;
        }
        if (this.maxDate && this.maxDateFormat) {
            this.maxDateValue = CommonUtilsService.transformDate(this.maxDate, this.maxDateFormat as any, 'DATE') as any;
        } else {
            this.maxDateValue = undefined;
        }
    };

    private emitChanges = (dateTime) => {
        if (dateTime && dateTime.getDate) {
            this.model = this._commonUtils.transformDateToLocale(dateTime, 'DATE', this.dateFormat as any, true);
        } else {
            this.model = dateTime;
        }
        this.modelChange.emit(this.model);
    };

    onDatePickerClose = () => {
        if (this.mannualInput) {
            this.emitChanges(this.mannualInput);
        }
    };

    ngOnInit() {
        if (this?.minDate || this?.maxDate) {
            this.applyDateRestriction();
        }
    }

    ngOnDestroy(): void {}
}
