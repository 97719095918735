import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, delay, from, map, mergeMap, of, withLatestFrom } from 'rxjs';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import * as DashboardActionType from '../actions';
import {
    getAnalyticsDashboards$,
    getCurrentOrganizationId$,
    getDashboardConfiguration$,
    getFilteringAttributesMetadata$,
    getSelectedDashboard$,
} from '../selectors';

@Injectable()
export class DashboardEffects {
    constructor(private actions$: Actions, private store: Store, private _api: ApiService, private _libUtils: CommonUtilsService) {}

    public getAllDashboards = createEffect(() => {
        return this.actions$.pipe(
            ofType(DashboardActionType.GetDashboards),
            withLatestFrom(this.store.select(getAnalyticsDashboards$), this.store.select(getCurrentOrganizationId$)),
            mergeMap(([action, analyticDashboards, currentOrgId]) => {
                if (analyticDashboards?.length > 0) {
                    return [];
                }
                this.store.dispatch(DashboardActionType.SetDashboardLoader({ isDashboardsLoading: true }));
                return from(this._api.analytics.getDashboards(action.noAlerts)).pipe(
                    map((res: any) => {
                        this.store.dispatch(DashboardActionType.SetDashboardLoader({ isDashboardsLoading: false }));
                        res?.response?.dashboards?.forEach((dashbaord) => {
                            const name: string = dashbaord?.displayName || dashbaord?.serviceName;
                            dashbaord.displayName = name[0].toUpperCase() + name.substring(1);
                        });
                        const dashboards = res?.response?.dashboards?.sort((a, b) => {
                            const aName = a.displayName;
                            const bName = b.displayName;
                            if (aName < bName) {
                                return -1;
                            } else if (aName > bName) {
                                return 1;
                            }
                            return 0;
                        });
                        return DashboardActionType.SetDashboards({
                            analyticDashboards: dashboards,
                            currentOrganizationId: currentOrgId,
                        });
                    }),
                    catchError((errorResponse) => {
                        this.store.dispatch(DashboardActionType.SetDashboardLoader({ isDashboardsLoading: false }));
                        return of(DashboardActionType.AlertError({ message: errorResponse }));
                    })
                );
            })
        );
    });

    public getDashboardConfiguration = createEffect(() => {
        return this.actions$.pipe(
            ofType(DashboardActionType.GetDashboardConfiguration),
            delay(10),
            withLatestFrom(this.store.select(getCurrentOrganizationId$), this.store.select(getDashboardConfiguration$)),
            mergeMap(([action, currentOrgId, config]) => {
                if (!this._libUtils.isEmpty(config)) {
                    return of(
                        DashboardActionType.SetDashboardConfiguration({
                            configuration: config,
                            serviceId: action.serviceId,
                            organizationId: currentOrgId,
                        })
                    );
                }
                return from(this._api.analytics.getDashboardConfiguration(action.serviceId)).pipe(
                    map((res: any) => {
                        return DashboardActionType.SetDashboardConfiguration({
                            configuration: res?.response,
                            serviceId: action.serviceId,
                            organizationId: currentOrgId,
                        });
                    }),
                    catchError((errorResponse) => {
                        return of(DashboardActionType.AlertError({ message: errorResponse?.msg }));
                    })
                );
            })
        );
    });

    public getFilteringAttributesMetadata = createEffect(() => {
        return this.actions$.pipe(
            ofType(DashboardActionType.GetFilteringAttributesMetadata),
            withLatestFrom(this.store.select(getCurrentOrganizationId$), this.store.select(getFilteringAttributesMetadata$)),
            mergeMap(([action, currentOrgId, metadata]) => {
                if (metadata?.length > 0) {
                    return [];
                }
                return from(this._api.analytics.getFilteringAttributesMetadata(action.serviceId)).pipe(
                    map((res: any) => {
                        return DashboardActionType.SetFilteringAttributesMetadata({
                            metadata: res?.response,
                            serviceId: action.serviceId,
                            organizationId: currentOrgId,
                        });
                    }),
                    catchError((errorResponse) => {
                        return of(DashboardActionType.AlertError({ message: errorResponse?.msg }));
                    })
                );
            })
        );
    });

    public saveDashboardConfiguration = createEffect(() => {
        return this.actions$.pipe(
            ofType(DashboardActionType.SaveDashboardConfiguration),
            withLatestFrom(this.store.select(getCurrentOrganizationId$), this.store.select(getSelectedDashboard$)),
            mergeMap(([action, currentOrgId, selectedDashboard]) => {
                let data = {
                    serviceId: selectedDashboard.serviceId,
                    payload: action.payload,
                };
                return from(this._api.analytics.saveDashboardConfiguration(data)).pipe(
                    map((res: any) => {
                        let msg = '';
                        switch (action.actionType) {
                            case 'SAVE':
                                msg = translate('Dashboard date range saved successfully');
                                break;
                            case 'UPDATE':
                                msg = translate('Dashboard filtering attributes updated successfully');
                                break;
                            case 'DELETE':
                                msg = translate('Dashboard date range deleted successfully');
                                break;
                            case 'RESET':
                                msg = translate('Dashboard filtering attributes reset successfully');
                                break;
                        }
                        this.store.dispatch(
                            DashboardActionType.AlertSuccess({
                                message: msg,
                            })
                        );
                        return DashboardActionType.SetDashboardConfiguration({
                            configuration: res?.response,
                            serviceId: selectedDashboard.serviceId,
                            organizationId: currentOrgId,
                        });
                    }),
                    catchError((errorResponse) => {
                        return of(DashboardActionType.AlertError({ message: errorResponse?.msg }));
                    })
                );
            })
        );
    });
}
