import { Injectable } from '@angular/core';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';

import { IntegrationModal } from '../../interface/integrationmodal.interface';

@Injectable({
    providedIn: 'root',
})
export class IntegrationsConfigService {
    constructor() {}

    environment = environment.restrictedFeatures;
    integrationRestriction = this.environment?.integrations || [];
    translator = (stringToTranslate: string): string => translate(`${stringToTranslate}`);

    globalConfigs: IntegrationModal[] = [
        {
            displayName: 'FTP',
            api: 'FTP',
            tableFieldIds: ['name', 'serverAddress', 'createdByName', 'createdOn', 'lastUpdatedByName', 'lastUpdatedOn'],
            canAddRecord: true,
            canTestConnection: true,
            canUpdateRecord: true,
            canDeleteRecord: true,
            canDownloadAuditTrail: true,
            tableRecordActions: [],
            fields: [
                {
                    id: 'name',
                    displayName: 'Name',
                    required: true,
                    type: 'TEXT',
                    validations: ['maxLength250', 'minLength3', 'mustContainAlphaAsFirstLetter'],
                    conditionalDisable: [
                        {
                            type: 'ANY_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'id',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'useMaster',
                    displayName: 'Use Master',
                    required: true,
                    isMasterField: true,
                    defaultValue: false,
                    type: 'INPUT_RADIO',
                    options: [
                        {
                            id: 'yes',
                            displayName: 'Yes',
                            value: true,
                        },
                        {
                            id: 'no',
                            displayName: 'No',
                            value: false,
                        },
                    ],
                },
                {
                    id: 'masterName',
                    displayName: 'Master Data Table',
                    required: true,
                    type: 'MASTER_DROPDOWN',
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'serverAddress',
                    displayName: 'Server Address',
                    required: true,
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                },
                {
                    id: 'port',
                    displayName: 'Port',
                    required: true,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                    validations: ['checkNumberOnly'],
                },
                {
                    id: 'maxNoOfConnections',
                    displayName: 'Maximum No. of Connections',
                    required: false,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                    validations: ['checkNumberOnly', 'checkMinimumNumberValue'],
                },
                {
                    id: 'folderPath',
                    displayName: 'Folder Path',
                    required: true,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'userName',
                    displayName: 'User Name',
                    required: true,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'password',
                    displayName: 'Password',
                    required: true,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'PASSWORD',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                                {
                                    fieldId: 'addKey',
                                    requiredValue: false,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'secureFTP',
                    displayName: 'SSH FTP',
                    required: true,
                    defaultValue: false,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'INPUT_RADIO',
                            options: [
                                {
                                    id: 'yes',
                                    displayName: 'Yes',
                                    value: true,
                                },
                                {
                                    id: 'no',
                                    displayName: 'No',
                                    value: false,
                                },
                            ],
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'addKey',
                    displayName: 'Add Key',
                    required: true,
                    defaultValue: false,
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                                {
                                    fieldId: 'secureFTP',
                                    requiredValue: true,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'INPUT_RADIO',
                            options: [
                                {
                                    id: 'yes',
                                    displayName: 'Yes',
                                    value: true,
                                },
                                {
                                    id: 'no',
                                    displayName: 'No',
                                    value: false,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'encryptedKey',
                    displayName: 'Encrypted Key',
                    required: true,
                    hint: '(Encrypted RSA key)',
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXTAREA',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                                {
                                    fieldId: 'addKey',
                                    requiredValue: true,
                                },
                            ],
                        },
                    ],
                    hasEncoding: true,
                    hasDecoding: true,
                    encodingType: 'btoa',
                    decodingType: 'atob',
                },
                {
                    id: 'createdByName',
                    displayName: 'Created By',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    copyToPayload: false,
                },
                {
                    id: 'createdOn',
                    displayName: 'Created Date',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    decodingType: 'DATE',
                    copyToPayload: false,
                },
                {
                    id: 'lastUpdatedByName',
                    displayName: 'Modified By',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    copyToPayload: false,
                },
                {
                    id: 'lastUpdatedOn',
                    displayName: 'Modified Date',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    decodingType: 'DATE',
                    copyToPayload: false,
                },
            ],
        },
        {
            displayName: 'S3',
            api: 'S3',
            tableFieldIds: ['name', 'accessKeyId', 'createdByName', 'createdOn', 'lastUpdatedByName', 'lastUpdatedOn'],
            canAddRecord: true,
            canTestConnection: true,
            canUpdateRecord: true,
            canDeleteRecord: true,
            canDownloadAuditTrail: true,
            tableRecordActions: [],
            fields: [
                {
                    id: 'name',
                    displayName: 'Name',
                    required: true,
                    type: 'TEXT',
                    validations: ['maxLength250', 'minLength3', 'mustContainAlphabets', 'mustContainAlphaAsFirstLetter'],
                    conditionalDisable: [
                        {
                            type: 'ANY_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'id',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'useMaster',
                    displayName: 'Use Master',
                    required: true,
                    isMasterField: true,
                    defaultValue: false,
                    type: 'INPUT_RADIO',
                    options: [
                        {
                            id: 'yes',
                            displayName: 'Yes',
                            value: true,
                        },
                        {
                            id: 'no',
                            displayName: 'No',
                            value: false,
                        },
                    ],
                },
                {
                    id: 'masterName',
                    displayName: 'Master Data Table',
                    required: true,
                    type: 'MASTER_DROPDOWN',
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'accessKeyId',
                    displayName: 'Access Key Id',
                    required: true,
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                },
                {
                    id: 'secretAccessKey',
                    displayName: 'Secret Access Key',
                    required: true,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'bucketName',
                    displayName: 'Bucket Name',
                    required: true,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'folderPath',
                    displayName: 'Folder Path',
                    required: true,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'TEXT',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'region',
                    displayName: 'Region',
                    required: true,
                    inputTypeConditonal: true,
                    type: [
                        {
                            fieldId: 'useMaster',
                            requiredValue: true,
                            type: 'DROPDOWN',
                        },
                        {
                            fieldId: 'useMaster',
                            requiredValue: false,
                            type: 'OPTIONS',
                        },
                    ],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: true,
                                },
                                {
                                    fieldId: 'masterName',
                                    valueExists: true,
                                },
                            ],
                        },
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'useMaster',
                                    requiredValue: false,
                                },
                            ],
                        },
                    ],
                    options: [
                        {
                            id: 'us-east-1',
                            value: 'us-east-1',
                            displayName: 'US East (N. Virginia)',
                        },
                        {
                            id: 'us-east-2',
                            value: 'us-east-2',
                            displayName: 'US East (Ohio)',
                        },
                        {
                            id: 'us-west-1',
                            value: 'us-west-1',
                            displayName: 'US West (N. California)',
                        },
                        {
                            id: 'us-west-2',
                            value: 'us-west-2',
                            displayName: 'US West (Oregon)',
                        },
                        {
                            id: 'ca-central-1',
                            value: 'ca-central-1',
                            displayName: 'Canada (Central)',
                        },
                        {
                            id: 'eu-central-1',
                            value: 'eu-central-1',
                            displayName: 'EU (Frankfurt)',
                        },
                        {
                            id: 'eu-west-1',
                            value: 'eu-west-1',
                            displayName: 'EU (Ireland)',
                        },
                        {
                            id: 'eu-west-2',
                            value: 'eu-west-2',
                            displayName: 'EU (London)',
                        },
                        {
                            id: 'eu-west-3',
                            value: 'eu-west-3',
                            displayName: 'EU (Paris)',
                        },
                        {
                            id: 'ap-northeast-1',
                            value: 'ap-northeast-1',
                            displayName: 'Asia Pacific (Tokyo)',
                        },
                        {
                            id: 'ap-northeast-2',
                            value: 'ap-northeast-2',
                            displayName: 'Asia Pacific (Seoul)',
                        },
                        {
                            id: 'ap-northeast-3',
                            value: 'ap-northeast-3',
                            displayName: 'Asia Pacific (Osaka-Local)',
                        },
                        {
                            id: 'ap-southeast-1',
                            value: 'ap-southeast-1',
                            displayName: 'Asia Pacific (Singapore)',
                        },
                        {
                            id: 'ap-southeast-2',
                            value: 'ap-southeast-2',
                            displayName: 'Asia Pacific (Sydney)',
                        },
                        {
                            id: 'ap-south-1',
                            value: 'ap-south-1',
                            displayName: 'Asia Pacific (Mumbai)',
                        },
                        {
                            id: 'sa-east-1',
                            value: 'sa-east-1',
                            displayName: 'South America (São Paulo)',
                        },
                    ],
                },
                {
                    id: 'createdByName',
                    displayName: 'Created By',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    copyToPayload: false,
                },
                {
                    id: 'createdOn',
                    displayName: 'Created Date',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    decodingType: 'DATE',
                    copyToPayload: false,
                },
                {
                    id: 'lastUpdatedByName',
                    displayName: 'Modified By',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    copyToPayload: false,
                },
                {
                    id: 'lastUpdatedOn',
                    displayName: 'Modified Date',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    decodingType: 'DATE',
                    copyToPayload: false,
                },
            ],
        },
        {
            api: 'RESTAPI',
            canAddRecord: true,
            canTestConnection: false,
            canUpdateRecord: true,
            canDeleteRecord: true,
            canDownloadAuditTrail: true,
            displayName: 'Rest API',
            tableFieldIds: ['name', 'url', 'createdByName', 'createdOn', 'lastUpdatedByName', 'lastUpdatedOn'],
            fields: [
                {
                    id: 'name',
                    displayName: 'Name',
                    required: true,
                    inputTypeConditonal: false,
                    type: 'TEXT',
                    conditionalDisplay: false,
                    validations: [
                        'mustNotContainSpaces',
                        'maxLength250',
                        'minLength3',
                        'mustContainAlphabets',
                        'mustContainAlphaAsFirstLetter',
                    ],
                    conditionalDisable: [
                        {
                            type: 'ANY_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'id',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'authorizationSystem',
                    displayName: 'Authorization System',
                    required: true,
                    inputTypeConditonal: false,
                    type: 'OPTIONS',
                    options: [],
                    conditionalDisplay: false,
                },
                {
                    id: 'restApiBaseUrl',
                    displayName: 'Base URL',
                    required: true,
                    inputTypeConditonal: false,
                    type: 'TEXT',
                    validations: ['checkUrl'],
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'authorizationSystem',
                                    requiredValue: 'OTHERS',
                                },
                            ],
                        },
                    ],
                },

                {
                    id: 'createdByName',
                    displayName: 'Created By',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    copyToPayload: false,
                },
                {
                    id: 'createdOn',
                    displayName: 'Created Date',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    decodingType: 'DATE',
                    copyToPayload: false,
                },
                {
                    id: 'lastUpdatedByName',
                    displayName: 'Modified By',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    copyToPayload: false,
                },
                {
                    id: 'lastUpdatedOn',
                    displayName: 'Modified Date',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    decodingType: 'DATE',
                    copyToPayload: false,
                },
            ],
            subSections: [
                {
                    structure: 'POSTMAN',
                    haveTabs: true,
                    conditionalDisplay: [
                        {
                            type: 'ALL_MUST_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'authorizationSystem',
                                    requiredValue: 'OTHERS',
                                },
                            ],
                        },
                    ],
                    tabs: [
                        {
                            id: 'authentication',
                            displayName: 'Authentication',
                            array: false,
                            canAddRecord: false,
                            canDeleteRecord: false,
                            active: true,
                            fields: [
                                {
                                    id: 'type',
                                    displayName: 'Type',
                                    inputTypeConditonal: false,
                                    required: false,
                                    type: 'OPTIONS',
                                    options: [],
                                },
                                {
                                    id: 'grantType',
                                    displayName: 'Grant Types',
                                    required: true,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'OPTIONS',
                                    options: [],
                                },
                                {
                                    id: 'callbackURL',
                                    displayName: 'Callback URL',
                                    required: false,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Implicit',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Authorization Code',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'TEXT',
                                },
                                {
                                    id: 'authorizationURL',
                                    displayName: 'Authorization URL',
                                    required: false,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Authorization Code',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Implicit',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'TEXT',
                                },
                                {
                                    id: 'accessTokenURL',
                                    displayName: 'Access Token URL',
                                    required: false,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Authorization Code',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Password Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Client Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'TEXT',
                                },
                                {
                                    id: 'clientId',
                                    displayName: 'Client ID',
                                    required: false,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Authorization Code',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Implicit',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Password Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Client Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'TEXT',
                                },
                                {
                                    id: 'clientSecret',
                                    displayName: 'Client Secret',
                                    required: false,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Authorization Code',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Client Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Password Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'PASSWORD',
                                },
                                {
                                    id: 'scope',
                                    displayName: 'Scope',
                                    required: false,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Authorization Code',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Implicit',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Password Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Client Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'TEXT',
                                },
                                {
                                    id: 'refreshTokenURL',
                                    displayName: 'Refresh Token URL',
                                    required: false,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Authorization Code',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Implicit',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'TEXT',
                                },
                                {
                                    id: 'clientAuthentication',
                                    displayName: 'Client Authentication',
                                    required: false,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Authorization Code',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Implicit',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Password Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Client Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'OPTIONS',
                                    options: [
                                        {
                                            id: 'sendAsBasicAuthHeader',
                                            value: 'Send as Basic Auth Header',
                                            displayName: 'Send as Basic Auth Header',
                                        },
                                        {
                                            id: 'sendClientCredentialsInBody',
                                            value: 'Send client credentials in body',
                                            displayName: 'Send client credentials in body',
                                        },
                                    ],
                                },
                                {
                                    id: 'userName',
                                    displayName: 'User Name',
                                    required: true,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'BASIC',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Password Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'BASIC',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'PASSWORD',
                                },
                                {
                                    id: 'password',
                                    displayName: 'Password',
                                    required: true,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'grantType',
                                                    requiredValue: 'Password Credentials',
                                                },
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'OAUTH',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'BASIC',
                                                },
                                            ],
                                        },
                                    ],
                                    type: 'PASSWORD',
                                },
                                {
                                    type: 'TEXTAREA',
                                    id: 'customAuthenticationSchema',
                                    displayName: 'Authentication Schema',
                                    required: true,
                                    inputTypeConditonal: true,
                                    conditionalDisplay: [
                                        {
                                            type: 'ALL_MUST_SATISFY',
                                            conditions: [
                                                {
                                                    fieldId: 'type',
                                                    requiredValue: 'CUSTOMAUTH',
                                                },
                                            ],
                                        },
                                    ],
                                    encodingType: 'btoa',
                                    decodingType: 'atob',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            api: 'EMAIL',
            canAddRecord: true,
            canTestConnection: false,
            canUpdateRecord: true,
            canDeleteRecord: true,
            canDownloadAuditTrail: true,
            displayName: 'Email',
            tableFieldIds: ['name', 'url', 'createdByName', 'createdOn', 'lastUpdatedByName', 'lastUpdatedOn'],
            fields: [
                {
                    id: 'name',
                    displayName: 'Name',
                    required: true,
                    inputTypeConditonal: false,
                    type: 'TEXT',
                    conditionalDisplay: false,
                    validations: [
                        'mustNotContainSpaces',
                        'maxLength250',
                        'minLength3',
                        'mustContainAlphabets',
                        'mustContainAlphaAsFirstLetter',
                    ],
                    conditionalDisable: [
                        {
                            type: 'ANY_SATISFY',
                            conditions: [
                                {
                                    fieldId: 'id',
                                    valueExists: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'authorizationSystem',
                    displayName: 'Authorization System',
                    required: true,
                    inputTypeConditonal: false,
                    type: 'OPTIONS',
                    options: [],
                    conditionalDisplay: false,
                },
                {
                    id: 'createdByName',
                    displayName: 'Created By',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    copyToPayload: false,
                },
                {
                    id: 'createdOn',
                    displayName: 'Created Date',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    decodingType: 'DATE',
                    copyToPayload: false,
                },
                {
                    id: 'lastUpdatedByName',
                    displayName: 'Modified By',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    copyToPayload: false,
                },
                {
                    id: 'lastUpdatedOn',
                    displayName: 'Modified Date',
                    required: false,
                    type: 'TEXT',
                    show: false,
                    decodingType: 'DATE',
                    copyToPayload: false,
                },
                {
                    id: 'receiverMailId',
                    displayName: 'Email ID',
                    required: true,
                    type: 'TEXT',
                    inputTypeConditonal: false,
                    conditionalDisplay: false,
                    errors: [],
                    validations: [],
                },
            ],
            subSections: [],
        },
    ];

    inboundIntegrations: IntegrationModal = {
        api: 'inbound',
        canAddRecord: false,
        canTestConnection: true,
        useMaster: false,
        canUpdateRecord: true,
        displayName: 'Inbound Integration',
        tableFieldIds: [
            'configName',
            'chainName',
            'transformationName',
            'createdByUserName',
            'createdDate',
            'modifiedByUserName',
            'lastModifiedDate',
        ],
        tableRecordActions: undefined,
        fields: [
            {
                array: false,
                displayName: 'Configuration Name',
                defaultValue: 'DefaultConfiguration',
                errors: [],
                id: 'configName',
                conditionalDisable: [
                    {
                        type: 'ANY_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                        ],
                    },
                ],
                options: [],
                required: true,
                type: 'TEXT',
                isFieldInMainDataOnly: true,
            },
            {
                array: false,
                displayName: 'Chain',
                errors: [],
                id: 'chainName',
                options: [],
                required: true,
                type: 'CHAIN_DROPDOWN',
                isFieldInMainDataOnly: true,
                conditionalDisable: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                            {
                                fieldId: 'chainName',
                                valueExists: true,
                            },
                        ],
                    },
                ],
            },
            {
                array: false,
                displayName: 'Transformation',
                errors: [],
                id: 'transformationName',
                options: [],
                required: true,
                type: 'TRANSFORMATIONS_DROPDOWN',
                isFieldInMainDataOnly: true,
                conditionalDisable: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                            {
                                fieldId: 'transformationName',
                                valueExists: true,
                            },
                        ],
                    },
                ],
            },
            {
                id: 'createdByUserName',
                displayName: 'Created By',
                required: false,
                type: 'TEXT',
                show: false,
                copyToPayload: false,
            },
            {
                id: 'createdDate',
                displayName: 'Created Date',
                required: false,
                type: 'TEXT',
                show: false,
                decodingType: 'DATE',
                copyToPayload: false,
            },
            {
                id: 'modifiedByUserName',
                displayName: 'Modified By',
                required: false,
                type: 'TEXT',
                show: false,
                copyToPayload: false,
            },
            {
                id: 'lastModifiedDate',
                displayName: 'Modified Date',
                required: false,
                type: 'TEXT',
                show: false,
                decodingType: 'DATE',
                copyToPayload: false,
            },
        ],
        subSections: [
            {
                displayName: 'Integration Methods for Sources',
                id: 'configs',
                structure: 'RECORDS_FORM',
                canAddRecord: true,
                canDeleteRecord: true,
                array: true,
                haveTabs: false,
                fields: [
                    {
                        array: false,
                        displayName: 'Source Name',
                        errors: [],
                        id: 'resourceName',
                        options: [],
                        uniqueValueInRecords: true,
                        required: true,
                        type: 'SOURCES_DROPDOWN',
                    },
                    {
                        array: false,
                        displayName: 'Integration Method',
                        errors: [],
                        id: 'integrationMethod',
                        options: [],
                        required: true,
                        type: 'API_CONFIG',
                    },
                    {
                        array: false,
                        displayName: 'Configuration',
                        errors: [],
                        id: 'globalConfigName',
                        options: [],
                        required: true,
                        type: 'API_CONFIGURED_LIST',
                        isFieldInConfiguredData: true,
                    },
                    {
                        array: false,
                        displayName: 'Organization Id',
                        errors: [],
                        id: 'orgId',
                        options: [],
                        required: false,
                        show: false,
                        type: 'TEXT',
                        isFieldInConfiguredData: true,
                    },
                    {
                        array: false,
                        displayName: 'Relative Folder Path',
                        errors: [],
                        id: 'relativePath',
                        options: [],
                        uniqueValueInRecords: true,
                        required: true,
                        type: 'TEXT',
                        isFieldInConfiguredData: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'FTP',
                                    },
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'S3',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'fileNamePattern',
                        displayName: 'File Name Pattern',
                        required: false,
                        errors: [],
                        isFieldInConfiguredData: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'FTP',
                                    },
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'S3',
                                    },
                                ],
                            },
                        ],
                        type: 'TEXT',
                        defaultValue: undefined,
                    },
                    {
                        array: false,
                        displayName: 'Checksum Verification',
                        id: 'checksumVerification',
                        required: false,
                        isFieldInConfiguredData: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'FTP',
                                    },
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'S3',
                                    },
                                ],
                            },
                        ],
                        type: 'OPTIONS',
                        options: [
                            {
                                id: 'marker',
                                displayName: 'Marker',
                                value: 'marker',
                            },
                            {
                                id: 'checksumFile',
                                displayName: 'Checksum File',
                                value: 'checksumFile',
                            },
                        ],
                        errors: [],
                    },
                    {
                        array: false,
                        displayName: 'File Extension',
                        id: 'checksumFileExtension',
                        required: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'checksumVerification',
                                        requiredValue: 'marker',
                                    },
                                    {
                                        fieldId: 'checksumVerification',
                                        requiredValue: 'checksumFile',
                                    },
                                ],
                            },
                        ],
                        type: 'TEXT',
                        errors: [],
                        isFieldInConfiguredData: true,
                    },
                    {
                        id: 'url',
                        displayName: 'Relative Url',
                        required: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'RESTAPI',
                                    },
                                ],
                            },
                        ],
                        type: 'TEXT',
                        defaultValue: undefined,
                        errors: [],
                        isFieldInConfiguredData: true,
                    },
                    {
                        id: 'method',
                        displayName: 'Method',
                        errors: [],
                        required: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'RESTAPI',
                                    },
                                ],
                            },
                        ],
                        type: 'OPTIONS',
                        options: [
                            {
                                id: 'get',
                                value: 'GET',
                                displayName: this.translator('Get'),
                            },
                            {
                                id: 'post',
                                displayName: this.translator('Post'),
                                value: 'POST',
                            },
                            {
                                id: 'put',
                                displayName: this.translator('Put'),
                                value: 'PUT',
                            },
                        ],
                        isFieldInConfiguredData: true,
                    },
                    {
                        id: 'async',
                        displayName: 'Async',
                        required: false,
                        array: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'RESTAPI',
                                    },
                                ],
                            },
                        ],
                        isMasterField: true,
                        defaultValue: false,
                        type: 'INPUT_RADIO',
                        options: [
                            {
                                id: 'yes',
                                displayName: 'Yes',
                                value: true,
                            },
                            {
                                id: 'no',
                                displayName: 'No',
                                value: false,
                            },
                        ],
                    },
                    {
                        id: 'internalAsync',
                        displayName: 'Internal',
                        required: false,
                        array: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'RESTAPI',
                                    },
                                ],
                            },
                        ],
                        isMasterField: true,
                        defaultValue: false,
                        type: 'INPUT_RADIO',
                        options: [
                            {
                                id: 'yes',
                                displayName: 'Yes',
                                value: true,
                            },
                            {
                                id: 'no',
                                displayName: 'No',
                                value: false,
                            },
                        ],
                    },
                    {
                        id: 'internalConfig',
                        displayName: 'Internal Config',
                        required: true,
                        type: 'INTERNALCONFIG_DROPDOWN',
                        isFieldInMainDataOnly: true,
                        options: [],
                        errors: [],
                        array: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'async',
                                        requiredValue: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'internalRetryCount',
                        displayName: 'Internal Retry Count',
                        required: true,
                        type: 'TEXT',
                        isFieldInConfiguredData: false,
                        validations: ['checkInteger', 'checkMinimumNumberValue'],
                        errors: [],
                        array: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'internalAsync',
                                        requiredValue: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'internalSchedulerInterval',
                        displayName: 'Internal Scheduler Interval (milliSeconds)',
                        required: true,
                        type: 'TEXT',
                        isFieldInConfiguredData: false,
                        validations: ['checkInteger', 'checkMinimumNumberValue'],
                        errors: [],
                        array: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'internalAsync',
                                        requiredValue: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                hasChildSubSectons: true,
                childSubSections: [
                    {
                        structure: 'POSTMAN',
                        haveTabs: true,
                        conditionalDisplay: [
                            {
                                type: 'ALL_MUST_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'EMAIL',
                                    },
                                ],
                            },
                        ],
                        tabs: [
                            {
                                id: 'filters',
                                displayName: 'Filters',
                                array: true,
                                canAddProperty: true,
                                active: true,
                                isFieldInConfiguredData: true,
                                canDeleteProperty: true,
                                fields: [
                                    {
                                        id: 'key',
                                        displayName: 'Key',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        required: false,
                                        position: 'SBS',
                                    },
                                    {
                                        id: 'values',
                                        displayName: 'Value',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        required: false,
                                        position: 'SBS',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        structure: 'POSTMAN',
                        haveTabs: true,
                        conditionalDisplay: [
                            {
                                type: 'ALL_MUST_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'method',
                                        valueExists: true,
                                    },
                                ],
                            },
                        ],
                        tabs: [
                            {
                                id: 'headers',
                                displayName: 'Header',
                                array: true,
                                canAddRecord: true,
                                active: true,
                                isFieldInConfiguredData: true,
                                canDeleteRecord: true,
                                fields: [
                                    {
                                        id: 'key',
                                        displayName: 'Key',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        required: false,
                                        position: 'SBS',
                                    },
                                    {
                                        id: 'values',
                                        displayName: 'Value',
                                        valueAsArrayInPayload: true,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: true,
                                        type: [
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: true,
                                                type: 'OPTIONS',
                                            },
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: false,
                                                type: 'TEXT',
                                            },
                                        ],
                                        required: false,
                                        position: 'SBS',
                                        options: [
                                            {
                                                displayName: 'Current Month',
                                                id: 'currentMonth',
                                                value: 'm',
                                            },
                                            {
                                                displayName: 'Current Year',
                                                id: 'currentYear',
                                                value: 'y',
                                            },
                                            {
                                                displayName: 'Current Date',
                                                id: 'currentDate',
                                                value: 'd',
                                            },
                                            {
                                                displayName: 'Beginning of Month',
                                                id: 'beginningOfMonth',
                                                value: 'bom',
                                            },
                                            {
                                                displayName: 'End of Month',
                                                id: 'endOfMonth',
                                                value: 'eom',
                                            },
                                            {
                                                displayName: 'Scheduler from Date',
                                                id: 'schedulerFromDate',
                                                value: 'schedular_from_date',
                                            },
                                            {
                                                displayName: 'Scheduler to Date',
                                                id: 'schedulerToDate',
                                                value: 'schedular_to_date',
                                            },
                                            {
                                                displayName: 'Other',
                                                id: 'other',
                                                value: 'other',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'expression',
                                        displayName: 'Expression',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        show: false,
                                        required: false,
                                        valueSameAsFieldId: 'values',
                                        position: 'SBS',
                                        defaultValueIf: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'dynamicExp',
                                                        requiredValue: false,
                                                    },
                                                ],
                                            },
                                        ],
                                        defaultValue: '',
                                    },
                                ],
                                recordActions: [
                                    {
                                        id: 'dynamic',
                                        displayName: 'Dynamic Value',
                                        defaultValue: false,
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                    },
                                    {
                                        id: 'dynamicExp',
                                        displayName: 'Dynamic Expression',
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                        defaultValue: false,
                                    },
                                ],
                            },
                            {
                                id: 'body',
                                displayName: 'Body',
                                array: false,
                                canAddRecord: false,
                                active: false,
                                isFieldInConfiguredData: true,
                                noSubObjectForFields: true,
                                canDeleteRecord: false,
                                fields: [
                                    {
                                        id: 'type',
                                        displayName: 'Body Type',
                                        required: false,
                                        conditionalDisplay: false,
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'text',
                                                displayName: 'Text',
                                                value: 'Text',
                                            },
                                            {
                                                id: 'json',
                                                displayName: 'JSON',
                                                value: 'JSON',
                                            },
                                            {
                                                id: 'xml',
                                                displayName: 'XML',
                                                value: 'XML',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'content',
                                        displayName: 'Raw Text',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXTAREA',
                                        required: false,
                                    },
                                ],
                                conditionallyDisable: true,
                                disableConditions: [
                                    {
                                        type: 'ANY_SATISFY',
                                        conditions: [
                                            {
                                                fieldId: 'method',
                                                requiredValue: 'GET',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 'queryParams',
                                displayName: 'Query Params',
                                array: true,
                                isFieldInConfiguredData: true,
                                canAddRecord: true,
                                canDeleteRecord: true,
                                fields: [
                                    {
                                        id: 'key',
                                        displayName: 'Key',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        required: false,
                                        position: 'SBS',
                                    },
                                    {
                                        id: 'values',
                                        displayName: 'Value',
                                        valueAsArrayInPayload: true,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: true,
                                        type: [
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: true,
                                                type: 'OPTIONS',
                                            },
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: false,
                                                type: 'TEXT',
                                            },
                                        ],
                                        required: false,
                                        position: 'SBS',
                                        options: [
                                            {
                                                displayName: 'Current Month',
                                                id: 'currentMonth',
                                                value: 'm',
                                            },
                                            {
                                                displayName: 'Current Year',
                                                id: 'currentYear',
                                                value: 'y',
                                            },
                                            {
                                                displayName: 'Current Date',
                                                id: 'currentDate',
                                                value: 'd',
                                            },
                                            {
                                                displayName: 'Beginning of Month',
                                                id: 'beginningOfMonth',
                                                value: 'bom',
                                            },
                                            {
                                                displayName: 'End of Month',
                                                id: 'endOfMonth',
                                                value: 'eom',
                                            },
                                            {
                                                displayName: 'Scheduler from Date',
                                                id: 'schedulerFromDate',
                                                value: 'schedular_from_date',
                                            },
                                            {
                                                displayName: 'Scheduler to Date',
                                                id: 'schedulerToDate',
                                                value: 'schedular_to_date',
                                            },
                                            {
                                                displayName: 'Other',
                                                id: 'other',
                                                value: 'other',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'expression',
                                        displayName: 'Expression',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        show: false,
                                        required: false,
                                        valueSameAsFieldId: 'values',
                                        position: 'SBS',
                                        defaultValueIf: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'dynamicExp',
                                                        requiredValue: false,
                                                    },
                                                ],
                                            },
                                        ],
                                        defaultValue: '',
                                    },
                                ],
                                recordActions: [
                                    {
                                        id: 'dynamic',
                                        defaultValue: false,
                                        displayName: 'Dynamic',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                    },
                                    {
                                        id: 'dynamicExp',
                                        displayName: 'Dynamic Expression',
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                        defaultValue: false,
                                    },
                                ],
                            },
                            {
                                id: 'authentication',
                                displayName: 'Authentication',
                                array: false,
                                canAddRecord: false,
                                canDeleteRecord: false,
                                isFieldInConfiguredData: true,
                                active: false,
                                conditionallyDisable: true,
                                disableConditions: [
                                    {
                                        type: 'ALL_MUST_SATISFY',
                                        conditions: [
                                            {
                                                fieldId: 'integrationMethod',
                                                requiredValue: 'RESTAPI',
                                            },
                                        ],
                                    },
                                ],
                                fields: [
                                    {
                                        id: 'type',
                                        conditionalDisplay: false,
                                        displayName: 'Type',
                                        inputTypeConditonal: false,
                                        required: false,
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'no_auth',
                                                displayName: 'No Auth',
                                                value: 'NOAUTH',
                                            },
                                            {
                                                id: 'basic_auth',
                                                displayName: 'Basic Auth',
                                                value: 'BasicAuthSettings',
                                            },
                                            {
                                                id: 'oauth_2.0',
                                                displayName: 'OAuth 2.0',
                                                value: 'OAuthSettings',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'grantType',
                                        displayName: 'Grant Types',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'authorizationCode',
                                                value: 'Authorization Code',
                                                displayName: 'Authorization Code',
                                            },
                                            {
                                                id: 'implicit',
                                                displayName: 'Implicit',
                                                value: 'Implicit',
                                            },
                                            {
                                                id: 'passwordCredential',
                                                displayName: 'Password Credential',
                                                value: 'Password Credentials',
                                            },
                                            {
                                                id: 'clientCredential',
                                                displayName: 'Client Credential',
                                                value: 'Client Credentials',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'callbackURL',
                                        displayName: 'Callback URL',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'authURL',
                                        displayName: 'Auth URL',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'accessTokenURL',
                                        displayName: 'Access Token URL',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'clientId',
                                        displayName: 'Client ID',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'clientSecret',
                                        displayName: 'Client Secret',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'scope',
                                        displayName: 'Scope',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'state',
                                        displayName: 'State',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'clientAuthentication',
                                        displayName: 'Client Authentication',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credential',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'sendAsBasicAuthHeader',
                                                value: 'Send as Basic Auth Header',
                                                displayName: 'Send as Basic Auth Header',
                                            },
                                            {
                                                id: 'sendClientCredentialsInBody',
                                                value: 'Send client credentials in body',
                                                displayName: 'Send client credentials in body',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'userName',
                                        displayName: 'User Name',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'BasicAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                    {
                                                        fieldId: 'authType',
                                                        requiredValue: 'credentials',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'password',
                                        displayName: 'Password',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'BasicAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                    {
                                                        fieldId: 'authType',
                                                        requiredValue: 'credentials',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'PASSWORD',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                displayName: 'Scheduler Details',
                structure: 'FORM',
                haveTabs: false,
                conditionalDisplay: [
                    {
                        type: 'ANY_SATISFY',
                        conditions: [
                            {
                                fieldId: 'internalAsync',
                                requiredValue: false,
                            },
                            {
                                fieldId: 'internalAsync',
                                valueExists: false,
                            },
                        ],
                    },
                ],
                fields: [
                    {
                        displayName: 'Trigger Job',
                        id: 'triggerJob',
                        array: false,
                        defaultValue: false,
                        required: true,
                        type: 'INPUT_RADIO',
                        options: [
                            {
                                id: 'yes',
                                displayName: 'Yes',
                                value: true,
                            },
                            {
                                id: 'no',
                                displayName: 'No',
                                value: false,
                            },
                        ],
                        errors: [],
                        hideLabel: false,
                        inputTypeConditonal: true,
                    },
                ],
            },
            {
                structure: 'FLEX_FORM',
                id: 'schedulerExpression',
                appendType: 'cronExpression',
                haveTabs: false,
                conditionalDisplay: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'triggerJob',
                                requiredValue: true,
                            },
                        ],
                    },
                ],
                fields: [
                    {
                        displayName: 'Cron Expression',
                        id: 'schedulerExpression',
                        array: false,
                        defaultValue: '0 10 1 1 ?',
                        required: true,
                        type: 'TEXT',
                        errors: [],
                        hideLabel: false,
                        inputTypeConditonal: true,
                    },
                ],
            },
        ],
    };

    mastersScheduler: IntegrationModal = {
        api: 'inbound',
        canAddRecord: false,
        canTestConnection: true,
        useMaster: true,
        canUpdateRecord: true,
        displayName: 'Master Scheduler',
        tableFieldIds: [
            'configName',
            'chainName',
            'transformationName',
            'createdByName',
            'createdOn',
            'lastUpdatedByName',
            'lastUpdatedOn',
        ],
        tableRecordActions: undefined,
        fields: [
            {
                array: false,
                displayName: 'Configuration Name',
                defaultValue: 'DefaultConfiguration',
                errors: [],
                id: 'configName',
                conditionalDisable: [
                    {
                        type: 'ANY_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                        ],
                    },
                ],
                options: [],
                required: true,
                type: 'TEXT',
                isFieldInMainDataOnly: true,
            },
            {
                array: false,
                displayName: 'Chain',
                errors: [],
                id: 'chainName',
                options: [],
                required: true,
                type: 'CHAIN_DROPDOWN',
                isFieldInMainDataOnly: true,
                conditionalDisable: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                            {
                                fieldId: 'chainName',
                                valueExists: true,
                            },
                        ],
                    },
                ],
            },
            {
                array: false,
                displayName: 'Transformation',
                errors: [],
                id: 'transformationName',
                options: [],
                required: true,
                type: 'TRANSFORMATIONS_DROPDOWN',
                isFieldInMainDataOnly: true,
            },
            {
                id: 'masterId',
                displayName: 'Master Data Table',
                required: true,
                type: 'MASTER_DROPDOWN',
            },
            {
                id: 'createdByName',
                displayName: 'Created By',
                required: false,
                type: 'TEXT',
                show: false,
                copyToPayload: false,
            },
            {
                id: 'createdOn',
                displayName: 'Created Date',
                required: false,
                type: 'TEXT',
                show: false,
                decodingType: 'DATE',
                copyToPayload: false,
            },
            {
                id: 'lastUpdatedByName',
                displayName: 'Modified By',
                required: false,
                type: 'TEXT',
                show: false,
                copyToPayload: false,
            },
            {
                id: 'lastUpdatedOn',
                displayName: 'Modified Date',
                required: false,
                type: 'TEXT',
                show: false,
                decodingType: 'DATE',
                copyToPayload: false,
            },
        ],
        subSections: [
            {
                displayName: 'Integration Methods by using Masters',
                id: 'configs',
                structure: 'FORM',
                array: false,
                haveTabs: false,
                fields: [
                    {
                        array: false,
                        displayName: 'Integration Method',
                        errors: [],
                        id: 'integrationMethod',
                        options: [
                            {
                                displayName: 'Asset',
                                id: 'asset',
                                value: 'ASSET',
                            },
                        ],
                        required: true,
                        type: 'OPTIONS',
                    },
                    {
                        array: true,
                        displayName: 'Select Organization',
                        errors: [],
                        id: 'orgUnitDetail',
                        options: [],
                        required: true,
                        type: 'ORGANIZATIONS_CHECKBOX',
                        isFieldInConfiguredData: true,
                        fieldIdInPayloadAs: 'unitId',
                        payloadType: 'ORGANIZATION',
                    },
                ],
            },
            {
                structure: 'POSTMAN',
                haveTabs: true,
                tabsInProperty: 'list',
                tabs: [
                    {
                        id: 'attributes',
                        displayName: 'Attributes',
                        array: true,
                        canAddRecord: false,
                        active: true,
                        isFieldInConfiguredData: true,
                        canDeleteRecord: false,
                        fieldsType: 'UNIQUE-SBS',
                        propertiesToInclude: ['dataFormat', 'dataType'],
                        fields: [],
                        dynamicFields: true,
                        recordActions: [
                            {
                                id: 'dynamic',
                                displayName: 'Dynamic',
                                hideLabel: false,
                                conditionalDisplay: false,
                                inputTypeConditonal: false,
                                type: 'CHECKBOX',
                                required: false,
                                defaultValue: false,
                            },
                        ],
                        tabsInProperty: 'list',
                    },
                ],
            },
            {
                displayName: 'Scheduler Details',
                id: 'configs',
                structure: 'FORM',
                array: false,
                haveTabs: false,
                fields: [
                    {
                        array: false,
                        displayName: 'Active',
                        errors: [],
                        id: 'active',
                        required: true,
                        type: 'DROPDOWN',
                        isFieldInConfiguredData: true,
                    },
                    {
                        array: false,
                        displayName: 'Scheduler Expression',
                        errors: [],
                        id: 'schedulerExpression',
                        options: [],
                        required: true,
                        type: 'DROPDOWN',
                    },
                    {
                        array: false,
                        displayName: 'Last Executed Time',
                        errors: [],
                        id: 'lastExecutionField',
                        options: [],
                        required: true,
                        type: 'DROPDOWN',
                    },
                ],
            },
        ],
    };

    outboundIntegrations: IntegrationModal = {
        api: 'outbound',
        canAddRecord: false,
        canTestConnection: true,
        canUpdateRecord: true,
        useMaster: false,
        displayName: 'Outbound Integration',
        tableFieldIds: [
            'configName',
            'chainName',
            'transformationName',
            'createdByUserName',
            'createdDate',
            'modifiedByUserName',
            'lastModifiedDate',
        ],
        tableRecordActions: undefined,
        fields: [
            {
                array: false,
                displayName: 'Configuration Name',
                errors: [],
                id: 'configName',
                defaultValue: 'DefaultConfiguration',
                conditionalDisable: [
                    {
                        type: 'ANY_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                        ],
                    },
                ],
                options: [],
                required: true,
                type: 'TEXT',
                isFieldInMainDataOnly: true,
            },
            {
                array: false,
                displayName: 'Chain',
                errors: [],
                id: 'chainName',
                options: [],
                required: true,
                type: 'CHAIN_DROPDOWN',
                isFieldInMainDataOnly: true,
                conditionalDisable: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                            {
                                fieldId: 'chainName',
                                valueExists: true,
                            },
                        ],
                    },
                ],
            },
            {
                array: false,
                displayName: 'Transformation',
                errors: [],
                id: 'transformationName',
                options: [],
                required: true,
                type: 'TRANSFORMATIONS_DROPDOWN',
                isFieldInMainDataOnly: true,
                conditionalDisable: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                            {
                                fieldId: 'transformationName',
                                valueExists: true,
                            },
                        ],
                    },
                ],
            },
            {
                id: 'createdByUserName',
                displayName: 'Created By',
                required: false,
                type: 'TEXT',
                show: false,
                copyToPayload: false,
            },
            {
                id: 'createdDate',
                displayName: 'Created Date',
                required: false,
                type: 'TEXT',
                show: false,
                decodingType: 'DATE',
                copyToPayload: false,
            },
            {
                id: 'modifiedByUserName',
                displayName: 'Modified By',
                required: false,
                type: 'TEXT',
                show: false,
                copyToPayload: false,
            },
            {
                id: 'lastModifiedDate',
                displayName: 'Modified Date',
                required: false,
                type: 'TEXT',
                show: false,
                decodingType: 'DATE',
                copyToPayload: false,
            },
        ],
        subSections: [
            {
                displayName: 'Integration Methods for Sources',
                id: 'configs',
                structure: 'RECORDS_FORM',
                canAddRecord: true,
                canDeleteRecord: true,
                array: true,
                haveTabs: false,
                fields: [
                    {
                        array: false,
                        displayName: 'Source Name',
                        errors: [],
                        id: 'resourceName',
                        options: [],
                        uniqueValueInRecords: true,
                        required: false,
                        type: 'SOURCES_DROPDOWN',
                        show: false,
                        valueSameAsFieldId: 'transformationName',
                    },
                    {
                        array: false,
                        displayName: 'Integration Method',
                        errors: [],
                        id: 'integrationMethod',
                        options: [],
                        required: true,
                        type: 'API_CONFIG',
                    },
                    {
                        array: false,
                        displayName: 'Configuration',
                        errors: [],
                        id: 'globalConfigName',
                        options: [],
                        required: true,
                        type: 'API_CONFIGURED_LIST',
                        isFieldInConfiguredData: true,
                    },
                    {
                        array: false,
                        displayName: 'Organization Id',
                        errors: [],
                        id: 'orgId',
                        options: [],
                        required: false,
                        show: false,
                        type: 'TEXT',
                        isFieldInConfiguredData: true,
                    },
                    {
                        array: false,
                        displayName: 'Relative Folder Path',
                        errors: [],
                        id: 'relativePath',
                        options: [],
                        uniqueValueInRecords: true,
                        required: true,
                        type: 'TEXT',
                        isFieldInConfiguredData: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'FTP',
                                    },
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'S3',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        array: false,
                        displayName: 'Checksum Verification',
                        id: 'checksumVerification',
                        required: false,
                        isFieldInConfiguredData: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'FTP',
                                    },
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'S3',
                                    },
                                ],
                            },
                        ],
                        type: 'OPTIONS',
                        options: [
                            {
                                id: 'marker',
                                displayName: 'Marker',
                                value: 'marker',
                            },
                            {
                                id: 'checksumFile',
                                displayName: 'Checksum File',
                                value: 'checksumFile',
                            },
                        ],
                        errors: [],
                    },
                    {
                        array: false,
                        displayName: 'File Extension',
                        id: 'checksumFileExtension',
                        required: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'checksumVerification',
                                        requiredValue: 'marker',
                                    },
                                    {
                                        fieldId: 'checksumVerification',
                                        requiredValue: 'checksumFile',
                                    },
                                ],
                            },
                        ],
                        type: 'TEXT',
                        errors: [],
                        isFieldInConfiguredData: true,
                    },
                    {
                        id: 'url',
                        displayName: 'Relative Url',
                        required: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'RESTAPI',
                                    },
                                ],
                            },
                        ],
                        type: 'TEXT',
                        defaultValue: undefined,
                        errors: [],
                        isFieldInConfiguredData: true,
                    },
                    {
                        id: 'method',
                        displayName: 'Method',
                        errors: [],
                        required: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'RESTAPI',
                                    },
                                ],
                            },
                        ],
                        type: 'OPTIONS',
                        options: [
                            {
                                id: 'post',
                                displayName: 'Post',
                                value: 'POST',
                            },
                            {
                                id: 'put',
                                displayName: 'Put',
                                value: 'PUT',
                            },
                        ],
                        isFieldInConfiguredData: true,
                    },
                ],
                hasChildSubSectons: true,
                childSubSections: [
                    {
                        structure: 'POSTMAN',
                        haveTabs: true,
                        conditionalDisplay: [
                            {
                                type: 'ALL_MUST_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'method',
                                        valueExists: true,
                                    },
                                ],
                            },
                        ],
                        tabs: [
                            {
                                id: 'headers',
                                displayName: 'Header',
                                array: true,
                                canAddRecord: true,
                                active: true,
                                isFieldInConfiguredData: true,
                                canDeleteRecord: true,
                                fields: [
                                    {
                                        id: 'key',
                                        displayName: 'Key',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        required: false,
                                        position: 'SBS',
                                    },
                                    {
                                        id: 'values',
                                        displayName: 'Value',
                                        valueAsArrayInPayload: true,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: true,
                                        type: [
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: true,
                                                type: 'OPTIONS',
                                            },
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: false,
                                                type: 'TEXT',
                                            },
                                        ],
                                        required: false,
                                        position: 'SBS',
                                        options: [
                                            {
                                                displayName: 'Current Month',
                                                id: 'currentMonth',
                                                value: 'm',
                                            },
                                            {
                                                displayName: 'Current Year',
                                                id: 'currentYear',
                                                value: 'y',
                                            },
                                            {
                                                displayName: 'Current Date',
                                                id: 'currentDate',
                                                value: 'd',
                                            },
                                            {
                                                displayName: 'Beginning of Month',
                                                id: 'beginningOfMonth',
                                                value: 'bom',
                                            },
                                            {
                                                displayName: 'End of Month',
                                                id: 'endOfMonth',
                                                value: 'eom',
                                            },
                                            {
                                                displayName: 'Scheduler from Date',
                                                id: 'schedulerFromDate',
                                                value: 'schedular_from_date',
                                            },
                                            {
                                                displayName: 'Scheduler to Date',
                                                id: 'schedulerToDate',
                                                value: 'schedular_to_date',
                                            },
                                            {
                                                displayName: 'Other',
                                                id: 'other',
                                                value: 'other',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'expression',
                                        displayName: 'Expression',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        show: false,
                                        required: false,
                                        valueSameAsFieldId: 'values',
                                        position: 'SBS',
                                        defaultValueIf: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'dynamicExp',
                                                        requiredValue: false,
                                                    },
                                                ],
                                            },
                                        ],
                                        defaultValue: '',
                                    },
                                ],
                                recordActions: [
                                    {
                                        id: 'dynamic',
                                        displayName: 'Dynamic Value',
                                        defaultValue: false,
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                    },
                                    {
                                        id: 'dynamicExp',
                                        displayName: 'Dynamic Expression',
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                        defaultValue: false,
                                    },
                                ],
                            },
                            {
                                id: 'body',
                                displayName: 'Body',
                                array: false,
                                canAddRecord: false,
                                active: false,
                                isFieldInConfiguredData: true,
                                canDeleteRecord: false,
                                noSubObjectForFields: true,
                                fields: [
                                    {
                                        id: 'type',
                                        displayName: 'Body Type',
                                        required: false,
                                        conditionalDisplay: false,
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'text',
                                                displayName: 'Text',
                                                value: 'Text',
                                            },
                                            {
                                                id: 'json',
                                                displayName: 'JSON',
                                                value: 'JSON',
                                            },
                                            {
                                                id: 'xml',
                                                displayName: 'XML',
                                                value: 'XML',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'content',
                                        displayName: 'Raw Text',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXTAREA',
                                        required: false,
                                    },
                                ],
                                conditionallyDisable: true,
                                disableConditions: [
                                    {
                                        type: 'ANY_SATISFY',
                                        conditions: [
                                            {
                                                fieldId: 'method',
                                                requiredValue: 'GET',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 'queryParams',
                                displayName: 'Query Params',
                                array: true,
                                isFieldInConfiguredData: true,
                                canAddRecord: true,
                                canDeleteRecord: true,
                                fields: [
                                    {
                                        id: 'key',
                                        displayName: 'Key',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        required: false,
                                        position: 'SBS',
                                    },
                                    {
                                        id: 'values',
                                        displayName: 'Value',
                                        valueAsArrayInPayload: true,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: true,
                                        type: [
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: true,
                                                type: 'OPTIONS',
                                            },
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: false,
                                                type: 'TEXT',
                                            },
                                        ],
                                        required: false,
                                        position: 'SBS',
                                        options: [
                                            {
                                                displayName: 'Current Month',
                                                id: 'currentMonth',
                                                value: 'm',
                                            },
                                            {
                                                displayName: 'Current Year',
                                                id: 'currentYear',
                                                value: 'y',
                                            },
                                            {
                                                displayName: 'Current Date',
                                                id: 'currentDate',
                                                value: 'd',
                                            },
                                            {
                                                displayName: 'Beginning of Month',
                                                id: 'beginningOfMonth',
                                                value: 'bom',
                                            },
                                            {
                                                displayName: 'End of Month',
                                                id: 'endOfMonth',
                                                value: 'eom',
                                            },
                                            {
                                                displayName: 'Scheduler from Date',
                                                id: 'schedulerFromDate',
                                                value: 'schedular_from_date',
                                            },
                                            {
                                                displayName: 'Scheduler to Date',
                                                id: 'schedulerToDate',
                                                value: 'schedular_to_date',
                                            },
                                            {
                                                displayName: 'Other',
                                                id: 'other',
                                                value: 'other',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'expression',
                                        displayName: 'Expression',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        show: false,
                                        required: false,
                                        valueSameAsFieldId: 'values',
                                        position: 'SBS',
                                        defaultValueIf: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'dynamicExp',
                                                        requiredValue: false,
                                                    },
                                                ],
                                            },
                                        ],
                                        defaultValue: '',
                                    },
                                ],
                                recordActions: [
                                    {
                                        id: 'dynamic',
                                        defaultValue: false,
                                        displayName: 'Dynamic',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                    },
                                    {
                                        id: 'dynamicExp',
                                        displayName: 'Dynamic Expression',
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                        defaultValue: false,
                                    },
                                ],
                            },
                            {
                                id: 'authentication',
                                displayName: 'Authentication',
                                array: false,
                                canAddRecord: false,
                                canDeleteRecord: false,
                                isFieldInConfiguredData: true,
                                active: false,
                                conditionallyDisable: true,
                                disableConditions: [
                                    {
                                        type: 'ANY_SATISFY',
                                        conditions: [
                                            {
                                                fieldId: 'integrationMethod',
                                                requiredValue: 'RESTAPI',
                                            },
                                        ],
                                    },
                                ],
                                fields: [
                                    {
                                        id: 'type',
                                        conditionalDisplay: false,
                                        displayName: 'Type',
                                        inputTypeConditonal: false,
                                        required: false,
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'no_auth',
                                                displayName: 'No Auth',
                                                value: 'NOAUTH',
                                            },
                                            {
                                                id: 'basic_auth',
                                                displayName: 'Basic Auth',
                                                value: 'BasicAuthSettings',
                                            },
                                            {
                                                id: 'oauth_2.0',
                                                displayName: 'OAuth 2.0',
                                                value: 'OAuthSettings',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'grantType',
                                        displayName: 'Grant Types',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'authorizationCode',
                                                value: 'Authorization Code',
                                                displayName: 'Authorization Code',
                                            },
                                            {
                                                id: 'implicit',
                                                displayName: 'Implicit',
                                                value: 'Implicit',
                                            },
                                            {
                                                id: 'passwordCredential',
                                                displayName: 'Password Credential',
                                                value: 'Password Credentials',
                                            },
                                            {
                                                id: 'clientCredential',
                                                displayName: 'Client Credential',
                                                value: 'Client Credentials',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'callbackURL',
                                        displayName: 'Callback URL',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'authURL',
                                        displayName: 'Auth URL',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'accessTokenURL',
                                        displayName: 'Access Token URL',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'clientId',
                                        displayName: 'Client ID',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'clientSecret',
                                        displayName: 'Client Secret',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'scope',
                                        displayName: 'Scope',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'state',
                                        displayName: 'State',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'clientAuthentication',
                                        displayName: 'Client Authentication',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Authorization Code',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Implicit',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Client Credential',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'sendAsBasicAuthHeader',
                                                value: 'Send as Basic Auth Header',
                                                displayName: 'Send as Basic Auth Header',
                                            },
                                            {
                                                id: 'sendClientCredentialsInBody',
                                                value: 'Send client credentials in body',
                                                displayName: 'Send client credentials in body',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'userName',
                                        displayName: 'User Name',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'BasicAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                    {
                                                        fieldId: 'authType',
                                                        requiredValue: 'credentials',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'password',
                                        displayName: 'Password',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'grantType',
                                                        requiredValue: 'Password Credentials',
                                                    },
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'BasicAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                    {
                                                        fieldId: 'authType',
                                                        requiredValue: 'credentials',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'PASSWORD',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    };

    reportOutboundIntegrations: IntegrationModal = {
        api: 'outbound',
        canAddRecord: false,
        canTestConnection: true,
        canUpdateRecord: true,
        useMaster: false,
        displayName: 'Outbound Integration',
        tableFieldIds: undefined,
        tableRecordActions: undefined,
        fields: [
            {
                array: false,
                conditionalDisplay: false,
                conditionalDisable: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'id',
                                valueExists: true,
                            },
                            {
                                fieldId: 'type',
                                valueExists: true,
                            },
                        ],
                    },
                ],
                displayName: 'Integration Method',
                id: 'type',
                required: true,
                type: 'API_CONFIG',
                errors: [],
                hideLabel: false,
                inputTypeConditonal: false,
                options: (() => {
                    const allOptions = [
                        {
                            id: 'ftp',
                            displayName: translate('FTP') as string,
                            value: 'FTP',
                        },

                        {
                            id: 's3',
                            displayName: translate('S3') as string,
                            value: 'S3',
                        },

                        {
                            id: 'restapi',
                            displayName: translate('RESTAPI') as string,
                            value: 'RESTAPI',
                        },
                    ];
                    return allOptions.filter((item) => this.integrationRestriction.indexOf(item.id.toLowerCase()) === -1);
                })(),
                idFieldInSubObjectOnly: false,
                isFieldInMainData: true,
                subFieldObject: 'type',
                fieldIdInMainData: 'integrationMethod',
            },
            {
                id: 'configId',
                displayName: 'Configured List',
                required: true,
                conditionalDisplay: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'type',
                                valueExists: true,
                            },
                        ],
                    },
                ],
                type: 'API_CONFIGURED_LIST',
                options: [],
                defaultValue: undefined,
                subFieldObject: 'type',
                idFieldInSubObjectOnly: true,
            },
            {
                id: 'relativePath',
                displayName: 'Relative Folder Path',
                required: true,
                conditionalDisplay: [
                    {
                        type: 'ANY_SATISFY',
                        conditions: [
                            {
                                fieldId: 'type',
                                requiredValue: 'FTP',
                            },
                            {
                                fieldId: 'type',
                                requiredValue: 'S3',
                            },
                        ],
                    },
                ],
                inputTypeConditonal: true,
                type: 'TEXT',
                options: [],
                defaultValue: undefined,
                subFieldObject: 'type',
                idFieldInSubObjectOnly: true,
            },
            {
                displayName: 'Active',
                id: 'active',
                subFieldObject: 'type',
                array: false,
                defaultValue: false,
                conditionalDisplay: [
                    {
                        type: 'ANY_SATISFY',
                        conditions: [
                            {
                                fieldId: 'type',
                                requiredValue: 'FTP',
                            },
                            {
                                fieldId: 'type',
                                requiredValue: 'S3',
                            },
                        ],
                    },
                ],
                type: 'INPUT_RADIO',
                options: [
                    {
                        displayName: 'Yes',
                        id: 'yes',
                        value: true,
                    },
                    {
                        displayName: 'No',
                        id: 'no',
                        value: false,
                    },
                ],
                errors: [],
                hideLabel: false,
                hint: undefined,
                inputTypeConditonal: true,
                required: true,
                idFieldInSubObjectOnly: true,
            },
            {
                array: false,
                conditionalDisable: false,
                conditionalDisplay: false,
                defaultValue: true,
                displayName: 'Trigger Job',
                errors: [],
                id: 'triggerJob',
                show: false,
                type: 'TEXT',
                required: false,
                subFieldObject: 'type',
                idFieldInSubObjectOnly: true,
            },
            {
                array: false,
                conditionalDisable: false,
                conditionalDisplay: false,
                defaultValue: false,
                displayName: 'Use Common Folder',
                errors: [],
                id: 'useCommonFolder',
                show: false,
                type: 'INPUT_RADIO',
                required: false,
                subFieldObject: 'type',
                idFieldInSubObjectOnly: true,
            },
            {
                array: false,
                conditionalDisable: false,
                conditionalDisplay: false,
                defaultValue: 'FILE',
                displayName: 'Use Common Folder',
                errors: [],
                id: 'componentType',
                show: false,
                type: 'TEXT',
                required: false,
                subFieldObject: 'type',
                idFieldInSubObjectOnly: true,
            },
        ],
    };

    inboundMasters: IntegrationModal = {
        api: 'masterinbound',
        canAddRecord: false,
        canTestConnection: true,
        canUpdateRecord: true,
        useMaster: false,
        displayName: 'Inbound Integration',
        tableFieldIds: [
            'configName',
            'chainName',
            'transformationName',
            'createdByUserName',
            'createdDate',
            'modifiedByUserName',
            'lastModifiedDate',
        ],
        tableRecordActions: undefined,
        fields: [
            {
                array: false,
                displayName: 'Configuration Name',
                defaultValue: 'DefaultConfiguration',
                errors: [],
                id: 'configName',
                conditionalDisable: [
                    {
                        type: 'ANY_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                        ],
                    },
                ],
                options: [],
                required: true,
                type: 'TEXT',
                isFieldInMainDataOnly: true,
            },
            {
                array: false,
                displayName: 'Chain',
                errors: [],
                id: 'chainName',
                options: [],
                required: true,
                type: 'CHAIN_DROPDOWN',
                isFieldInMainDataOnly: true,
                conditionalDisable: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                            {
                                fieldId: 'chainName',
                                valueExists: true,
                            },
                        ],
                    },
                ],
            },
            {
                array: false,
                displayName: 'Transformation',
                errors: [],
                id: 'transformationName',
                options: [],
                required: true,
                type: 'TRANSFORMATIONS_DROPDOWN',
                isFieldInMainDataOnly: true,
                conditionalDisable: [
                    {
                        type: 'ANY_SATISFY',
                        conditions: [
                            {
                                fieldId: 'locationId',
                                valueExists: true,
                            },
                        ],
                    },
                ],
            },
            {
                id: 'createdByUserName',
                displayName: 'Created By',
                required: false,
                type: 'TEXT',
                show: false,
                copyToPayload: false,
            },
            {
                id: 'createdDate',
                displayName: 'Created Date',
                required: false,
                type: 'TEXT',
                show: false,
                decodingType: 'DATE',
                copyToPayload: false,
            },
            {
                id: 'modifiedByUserName',
                displayName: 'Modified By',
                required: false,
                type: 'TEXT',
                show: false,
                copyToPayload: false,
            },
            {
                id: 'lastModifiedDate',
                displayName: 'Modified Date',
                required: false,
                type: 'TEXT',
                show: false,
                decodingType: 'DATE',
                copyToPayload: false,
            },
        ],
        subSections: [
            {
                displayName: 'Integration Methods for Sources',
                id: 'configs',
                structure: 'RECORDS_FORM',
                canAddRecord: true,
                canDeleteRecord: true,
                array: true,
                haveTabs: false,
                fields: [
                    {
                        array: false,
                        displayName: 'Source Name',
                        errors: [],
                        id: 'resourceName',
                        options: [],
                        uniqueValueInRecords: true,
                        required: true,
                        type: 'SOURCES_DROPDOWN',
                    },
                    {
                        array: false,
                        displayName: 'Integration Method',
                        errors: [],
                        id: 'integrationMethod',
                        options: (() => {
                            const allOptions = [
                                {
                                    id: 'ftp',
                                    displayName: translate('FTP') as string,
                                    value: 'FTP',
                                },

                                {
                                    id: 's3',
                                    displayName: translate('S3') as string,
                                    value: 'S3',
                                },

                                {
                                    id: 'restapi',
                                    displayName: translate('RESTAPI') as string,
                                    value: 'RESTAPI',
                                },
                            ];
                            return allOptions.filter((item) => this.integrationRestriction.indexOf(item.id.toLowerCase()) === -1);
                        })(),
                        required: true,
                        type: 'API_CONFIG',
                    },
                    {
                        array: false,
                        displayName: 'Configuration',
                        errors: [],
                        id: 'globalConfigName',
                        options: [],
                        required: true,
                        type: 'API_CONFIGURED_LIST',
                        isFieldInConfiguredData: true,
                    },
                    {
                        array: false,
                        displayName: 'Organization Id',
                        errors: [],
                        id: 'orgId',
                        options: [],
                        required: false,
                        show: false,
                        type: 'TEXT',
                        isFieldInConfiguredData: true,
                    },
                    {
                        array: false,
                        displayName: 'Relative Folder Path',
                        errors: [],
                        id: 'relativePath',
                        options: [],
                        uniqueValueInRecords: true,
                        required: true,
                        type: 'TEXT',
                        isFieldInConfiguredData: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'FTP',
                                    },
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'S3',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 'fileNamePattern',
                        displayName: 'File Name Pattern',
                        required: false,
                        errors: [],
                        isFieldInConfiguredData: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'FTP',
                                    },
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'S3',
                                    },
                                ],
                            },
                        ],
                        type: 'TEXT',
                        defaultValue: undefined,
                    },
                    {
                        array: false,
                        displayName: 'Checksum Verification',
                        id: 'checksumVerification',
                        required: false,
                        isFieldInConfiguredData: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'FTP',
                                    },
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'S3',
                                    },
                                ],
                            },
                        ],
                        type: 'OPTIONS',
                        options: [
                            {
                                id: 'marker',
                                displayName: 'Marker',
                                value: 'marker',
                            },
                            {
                                id: 'checksumFile',
                                displayName: 'Checksum File',
                                value: 'checksumFile',
                            },
                        ],
                        errors: [],
                    },
                    {
                        array: false,
                        displayName: 'File Extension',
                        id: 'checksumFileExtension',
                        required: true,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'checksumVerification',
                                        requiredValue: 'marker',
                                    },
                                    {
                                        fieldId: 'checksumVerification',
                                        requiredValue: 'checksumFile',
                                    },
                                ],
                            },
                        ],
                        type: 'TEXT',
                        errors: [],
                        isFieldInConfiguredData: true,
                    },
                    {
                        id: 'url',
                        displayName: 'Relative Url',
                        required: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'RESTAPI',
                                    },
                                ],
                            },
                        ],
                        type: 'TEXT',
                        defaultValue: undefined,
                        errors: [],
                        isFieldInConfiguredData: true,
                    },
                    {
                        id: 'method',
                        displayName: 'Method',
                        errors: [],
                        required: false,
                        conditionalDisplay: [
                            {
                                type: 'ANY_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'integrationMethod',
                                        requiredValue: 'RESTAPI',
                                    },
                                ],
                            },
                        ],
                        type: 'OPTIONS',
                        options: [
                            {
                                id: 'get',
                                value: 'GET',
                                displayName: this.translator('Get'),
                            },
                            {
                                id: 'post',
                                displayName: this.translator('Post'),
                                value: 'POST',
                            },
                            {
                                id: 'put',
                                displayName: this.translator('Put'),
                                value: 'PUT',
                            },
                        ],
                        isFieldInConfiguredData: true,
                    },
                ],
                hasChildSubSectons: true,
                childSubSections: [
                    {
                        structure: 'POSTMAN',
                        haveTabs: true,
                        conditionalDisplay: [
                            {
                                type: 'ALL_MUST_SATISFY',
                                conditions: [
                                    {
                                        fieldId: 'method',
                                        valueExists: true,
                                    },
                                ],
                            },
                        ],
                        tabs: [
                            {
                                id: 'headers',
                                displayName: 'Header',
                                array: true,
                                canAddRecord: true,
                                active: true,
                                isFieldInConfiguredData: true,
                                canDeleteRecord: true,
                                fields: [
                                    {
                                        id: 'key',
                                        displayName: 'Key',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        required: false,
                                        position: 'SBS',
                                    },
                                    {
                                        id: 'values',
                                        displayName: 'Value',
                                        valueAsArrayInPayload: true,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: true,
                                        type: [
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: true,
                                                type: 'OPTIONS',
                                            },
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: false,
                                                type: 'TEXT',
                                            },
                                        ],
                                        required: false,
                                        position: 'SBS',
                                        options: [
                                            {
                                                displayName: 'Current Month',
                                                id: 'currentMonth',
                                                value: 'm',
                                            },
                                            {
                                                displayName: 'Current Year',
                                                id: 'currentYear',
                                                value: 'y',
                                            },
                                            {
                                                displayName: 'Current Date',
                                                id: 'currentDate',
                                                value: 'd',
                                            },
                                            {
                                                displayName: 'Beginning of Month',
                                                id: 'beginningOfMonth',
                                                value: 'bom',
                                            },
                                            {
                                                displayName: 'End of Month',
                                                id: 'endOfMonth',
                                                value: 'eom',
                                            },
                                            {
                                                displayName: 'Scheduler from Date',
                                                id: 'schedulerFromDate',
                                                value: 'schedular_from_date',
                                            },
                                            {
                                                displayName: 'Scheduler to Date',
                                                id: 'schedulerToDate',
                                                value: 'schedular_to_date',
                                            },
                                            {
                                                displayName: 'Other',
                                                id: 'other',
                                                value: 'other',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'expression',
                                        displayName: 'Expression',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        show: false,
                                        required: false,
                                        valueSameAsFieldId: 'values',
                                        position: 'SBS',
                                        defaultValueIf: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'dynamicExp',
                                                        requiredValue: false,
                                                    },
                                                ],
                                            },
                                        ],
                                        defaultValue: '',
                                    },
                                ],
                                recordActions: [
                                    {
                                        id: 'dynamic',
                                        displayName: 'Dynamic Value',
                                        defaultValue: false,
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                    },
                                    {
                                        id: 'dynamicExp',
                                        displayName: 'Dynamic Expression',
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                        defaultValue: false,
                                    },
                                ],
                            },
                            {
                                id: 'body',
                                displayName: 'Body',
                                array: false,
                                canAddRecord: false,
                                active: false,
                                isFieldInConfiguredData: true,
                                noSubObjectForFields: true,
                                canDeleteRecord: false,
                                fields: [
                                    {
                                        id: 'content',
                                        displayName: 'Raw Text',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXTAREA',
                                        required: false,
                                    },
                                ],
                                conditionallyDisable: true,
                                disableConditions: [
                                    {
                                        type: 'ANY_SATISFY',
                                        conditions: [
                                            {
                                                fieldId: 'method',
                                                requiredValue: 'GET',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                id: 'queryParams',
                                displayName: 'Query Params',
                                array: true,
                                isFieldInConfiguredData: true,
                                canAddRecord: true,
                                canDeleteRecord: true,
                                fields: [
                                    {
                                        id: 'key',
                                        displayName: 'Key',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        required: false,
                                        position: 'SBS',
                                    },
                                    {
                                        id: 'values',
                                        displayName: 'Value',
                                        valueAsArrayInPayload: true,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: true,
                                        type: [
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: true,
                                                type: 'OPTIONS',
                                            },
                                            {
                                                fieldId: 'dynamicExp',
                                                requiredValue: false,
                                                type: 'TEXT',
                                            },
                                        ],
                                        required: false,
                                        position: 'SBS',
                                        options: [
                                            {
                                                displayName: 'Current Month',
                                                id: 'currentMonth',
                                                value: 'm',
                                            },
                                            {
                                                displayName: 'Current Year',
                                                id: 'currentYear',
                                                value: 'y',
                                            },
                                            {
                                                displayName: 'Current Date',
                                                id: 'currentDate',
                                                value: 'd',
                                            },
                                            {
                                                displayName: 'Beginning of Month',
                                                id: 'beginningOfMonth',
                                                value: 'bom',
                                            },
                                            {
                                                displayName: 'End of Month',
                                                id: 'endOfMonth',
                                                value: 'eom',
                                            },
                                            {
                                                displayName: 'Scheduler from Date',
                                                id: 'schedulerFromDate',
                                                value: 'schedular_from_date',
                                            },
                                            {
                                                displayName: 'Scheduler to Date',
                                                id: 'schedulerToDate',
                                                value: 'schedular_to_date',
                                            },
                                            {
                                                displayName: 'Other',
                                                id: 'other',
                                                value: 'other',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'expression',
                                        displayName: 'Expression',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'TEXT',
                                        show: false,
                                        required: false,
                                        valueSameAsFieldId: 'values',
                                        position: 'SBS',
                                        defaultValueIf: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'dynamicExp',
                                                        requiredValue: false,
                                                    },
                                                ],
                                            },
                                        ],
                                        defaultValue: '',
                                    },
                                ],
                                recordActions: [
                                    {
                                        id: 'dynamic',
                                        defaultValue: false,
                                        displayName: 'Dynamic',
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                    },
                                    {
                                        id: 'dynamicExp',
                                        displayName: 'Dynamic Expression',
                                        hideLabel: false,
                                        conditionalDisplay: false,
                                        inputTypeConditonal: false,
                                        type: 'CHECKBOX',
                                        required: false,
                                        defaultValue: false,
                                    },
                                ],
                            },
                            {
                                id: 'authentication',
                                displayName: 'Authentication',
                                array: false,
                                canAddRecord: false,
                                canDeleteRecord: false,
                                isFieldInConfiguredData: true,
                                active: false,
                                conditionallyDisable: true,
                                disableConditions: [
                                    {
                                        type: 'ANY_SATISFY',
                                        conditions: [
                                            {
                                                fieldId: 'integrationMethod',
                                                requiredValue: 'RESTAPI',
                                            },
                                        ],
                                    },
                                ],
                                fields: [
                                    {
                                        id: 'type',
                                        conditionalDisplay: false,
                                        displayName: 'Type',
                                        inputTypeConditonal: false,
                                        required: false,
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'no_auth',
                                                displayName: 'No Auth',
                                                value: 'NOAUTH',
                                            },
                                            {
                                                id: 'basic_auth',
                                                displayName: 'Basic Auth',
                                                value: 'BasicAuthSettings',
                                            },
                                            {
                                                id: 'oauth_2.0',
                                                displayName: 'OAuth 2.0',
                                                value: 'OAuthSettings',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'url',
                                        displayName: 'URL',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                        validations: ['checkUrl'],
                                    },
                                    {
                                        id: 'method',
                                        displayName: 'Method',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'get',
                                                value: 'GET',
                                                displayName: this.translator('Get'),
                                            },
                                            {
                                                id: 'post',
                                                displayName: this.translator('Post'),
                                                value: 'POST',
                                            },
                                            {
                                                id: 'put',
                                                displayName: this.translator('Put'),
                                                value: 'PUT',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'authType',
                                        displayName: 'Auth Type',
                                        required: false,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'OPTIONS',
                                        options: [
                                            {
                                                id: 'noAuth',
                                                value: undefined,
                                                displayName: 'No Auth',
                                            },
                                            {
                                                id: 'basicAuth',
                                                displayName: 'Basic Auth',
                                                value: 'credentials',
                                            },
                                        ],
                                    },
                                    {
                                        id: 'userName',
                                        displayName: 'User Name',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'BasicAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                    {
                                                        fieldId: 'authType',
                                                        requiredValue: 'credentials',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'TEXT',
                                    },
                                    {
                                        id: 'password',
                                        displayName: 'Password',
                                        required: true,
                                        conditionalDisplay: [
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'BasicAuthSettings',
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'ALL_MUST_SATISFY',
                                                conditions: [
                                                    {
                                                        fieldId: 'type',
                                                        requiredValue: 'OAuthSettings',
                                                    },
                                                    {
                                                        fieldId: 'authType',
                                                        requiredValue: 'credentials',
                                                    },
                                                ],
                                            },
                                        ],
                                        type: 'PASSWORD',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                displayName: 'Scheduler Details',
                structure: 'FORM',
                haveTabs: false,
                fields: [
                    {
                        displayName: 'Trigger Job',
                        id: 'triggerJob',
                        array: false,
                        defaultValue: false,
                        required: true,
                        type: 'INPUT_RADIO',
                        options: [
                            {
                                id: 'yes',
                                displayName: 'Yes',
                                value: true,
                            },
                            {
                                id: 'no',
                                displayName: 'No',
                                value: false,
                            },
                        ],
                        errors: [],
                        hideLabel: false,
                        inputTypeConditonal: true,
                    },
                ],
            },
            {
                structure: 'FLEX_FORM',
                id: 'schedulerExpression',
                appendType: 'cronExpression',
                haveTabs: false,
                conditionalDisplay: [
                    {
                        type: 'ALL_MUST_SATISFY',
                        conditions: [
                            {
                                fieldId: 'triggerJob',
                                requiredValue: true,
                            },
                        ],
                    },
                ],
                fields: [
                    {
                        displayName: 'Cron Expression',
                        id: 'schedulerExpression',
                        array: false,
                        defaultValue: '0 10 1 1 ?',
                        required: true,
                        type: 'TEXT',
                        errors: [],
                        hideLabel: false,
                        inputTypeConditonal: true,
                    },
                ],
            },
        ],
    };
}
