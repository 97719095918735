import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DASHBOARD_REDUCER_KEY } from '../reducers';
import { DashboardState } from '../states';
import { getCurrentOrganizationId$ } from './session.selector';

const currentState = createFeatureSelector<DashboardState>(DASHBOARD_REDUCER_KEY);

export const getDashboardOrganizationMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.analyticDashboards?.[organizationId]
);

export const getDashboardLoader$ = createSelector(currentState, (state) => state.isDashboardsLoading);

export const getSelectedDashboard$ = createSelector(currentState, (state) => state.selectedDashboard);

export const getAnalyticsDashboards$ = createSelector(getDashboardOrganizationMap$, (organizationMap) => organizationMap?.dashboards);

export const getDashboardDetailsMap$ = createSelector(getDashboardOrganizationMap$, (organizationMap) => organizationMap?.dashboardDetails);

export const getSelectedDashboardDetails$ = createSelector(
    getDashboardDetailsMap$,
    getSelectedDashboard$,
    (dashboardDetailsMap, selectedDashboard) => dashboardDetailsMap?.[selectedDashboard?.serviceId]
);

export const getDashboardConfiguration$ = createSelector(
    getSelectedDashboardDetails$,
    (selectedDashboardDetails) => selectedDashboardDetails?.configuration
);

export const getFilteringAttributesMetadata$ = createSelector(
    getSelectedDashboardDetails$,
    (selectedDashboardDetails) => selectedDashboardDetails?.metadata
);
