import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { delay, mergeMap, withLatestFrom } from 'rxjs/operators';

import { TimelineFilter } from '../../models/app-configurator.class';
import { UtilsService } from '../../services/utils/utils.service';
import {
    ClearFilingReconRequests,
    ClearFilingRequests,
    ClearReconRequests,
    ClearRequests,
    CreateFilingRequestManually,
    CreateOTPRequest,
    CreateRequestManually,
    GetAppMetaData,
    GetFilingInstanceEntityRecords,
    GetFilingInstancePrimaryEntityRecords,
    GetFilingReconRequests,
    GetOTPAppMetaData,
    GetOTPRecordData,
    GetReconRequests,
    GetRecords,
    SearchRecords,
    SelectFilingInstances,
    SelectInstances,
    SetFilingInstanceEntityCriteria,
    SetFilingInstanceEntityPagination,
    SetFilingReconRequestsPagination,
    SetFilingReconRequestsPagingState,
    SetReconRequests,
    SetRecordsPagination,
    SetRecordsSearchCriteria,
    SetSearchRecords,
} from '../actions';
import {
    currentFilingMonth$,
    currentFilingQuarter$,
    currentFilingYear$,
    getAppConfiguratorsMap$,
    getAppsMetaDataMap$,
    getCurrentOrganizationId$,
    getCustomPeriodicSearchMap$,
    getFilingDetachedGroupSource1EntityId$,
    getFilingDetachedGroupSource2EntityId$,
    getFilingMainAssociationFieldId$,
    getFilingNonDetachedGroupEntityId$,
    getFilingParentEntityDetails$,
    getFilingReconOrganization$,
    getFilingRecordsSearchCriteria$,
    getFilingSelectedAppMetaData$,
    getFilingSelectedEntityDetails$,
    getFilingSelectedInstanceDetails$,
    getOTPAuthApp$,
    getOTPAuthAppMetaData$,
    getSubscribedAppsMap$,
} from '../selectors';

@Injectable()
export class GstFilingEffects {
    constructor(private store$: Store, private actions$: Actions, private _utils: UtilsService) {}

    getOTPAppMetaData = createEffect(() =>
        this.actions$.pipe(
            ofType(GetOTPAppMetaData),
            delay(500),
            withLatestFrom(this.store$.select(getOTPAuthApp$)),
            mergeMap(([_action, app]) => {
                if (!app?.serviceId) {
                    this.store$.dispatch(GetOTPAppMetaData());
                    return [];
                }
                return of(
                    GetAppMetaData({
                        serviceId: app.serviceId,
                        noAlerts: true,
                    })
                );
            })
        )
    );

    getOTPRecordData = createEffect(() =>
        this.actions$.pipe(
            ofType(GetOTPRecordData),
            delay(500),
            withLatestFrom(this.store$.select(getOTPAuthApp$), this.store$.select(getOTPAuthAppMetaData$)),
            mergeMap(([action, app, metaData]) => {
                return of(
                    GetRecords({
                        serviceId: app.serviceId,
                        entityUid: metaData.entities.find((entity) => entity.primary).uid,
                        instanceId: action.instanceId,
                        parentRecordId: undefined,
                        noAlerts: true,
                    })
                );
            })
        )
    );

    createOTPRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateOTPRequest),
            delay(10),
            withLatestFrom(this.store$.select(getOTPAuthApp$), this.store$.select(getOTPAuthAppMetaData$)),
            mergeMap(([action, app, metaData]) => {
                const field = UtilsService.getFilingGSTIN(metaData.getPrimaryEntity());
                const formData = new FormData();
                formData.append(field.uid, action.gstin);
                return of(
                    CreateRequestManually({
                        formData: formData,
                        serviceId: app.serviceId,
                        gstin: action.gstin,
                    })
                );
            })
        )
    );

    createGSTINCall = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateFilingRequestManually),
            delay(10),
            withLatestFrom(
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$),
                this.store$.select(currentFilingQuarter$),
                this.store$.select(getAppConfiguratorsMap$),
                this.store$.select(getCustomPeriodicSearchMap$)
            ),
            mergeMap(([action, metaDataMap, appsMap, year, month, quarter, configurators, customPeriodicSearchMap]) => {
                const app = appsMap?.[action.serviceId];
                const metaData = metaDataMap?.[action.serviceId]?.[app?.assetMetaUId];
                const primaryEntity = metaData?.entities?.find((entity) => entity.primary);
                const businessKeyFields = primaryEntity?.fields?.filter((field) => field.isBusinessKey);
                const timelineFilter = configurators?.[action.serviceId]?.[app?.assetMetaUId]?.wrapperUI?.timelineFilter;
                const customPeriodicSearch = customPeriodicSearchMap?.[action.serviceId]?.[app?.assetMetaUId];
                let formData = new FormData();
                const yearValue = UtilsService.getFilingYearField(customPeriodicSearch, timelineFilter, metaData?.getPrimaryEntity())
                    ? year
                    : undefined;
                const monthValue = UtilsService.getFilingMonthField(customPeriodicSearch, timelineFilter, metaData?.getPrimaryEntity())
                    ? month
                    : undefined;
                const quarterValue = UtilsService.getFilingQuarterField(customPeriodicSearch, timelineFilter, metaData?.getPrimaryEntity())
                    ? quarter
                    : undefined;
                businessKeyFields.forEach((field) => {
                    if (field.tagEntries?.length === 0) {
                        return;
                    }
                    let format =
                        (field.uid === timelineFilter?.yearFieldId && timelineFilter?.yearValueFormat) ||
                        (field.uid === timelineFilter?.monthFieldId && timelineFilter?.monthValueFormat) ||
                        (field.uid === timelineFilter?.quarterFieldId && timelineFilter?.quarterValueFormat) ||
                        (timelineFilter?.frequency?.length > 0 && this.getFormatByField(timelineFilter, field));
                    const tag = field.tagEntries.find((tagItem) => tagItem.key === 'gst-filing-field');
                    const value = UtilsService.getValueByTagAndFieldFormat(
                        format || tag.value,
                        yearValue,
                        monthValue,
                        quarterValue,
                        action.gstin
                    ) as string;
                    if (value === undefined) {
                        return;
                    }
                    formData.append(field.uid, value);
                });
                return of(
                    CreateRequestManually({
                        formData: formData,
                        serviceId: app.serviceId,
                        multipleCalls: action.multipleCalls ? true : false,
                    })
                );
            })
        )
    );

    private getFormatByField = (timeLineFilter: TimelineFilter, field) => {
        return (
            (timeLineFilter?.yearFieldId === field.uid && timeLineFilter?.yearValueFormat) ||
            (timeLineFilter?.monthFieldId === field.uid && timeLineFilter?.monthValueFormat) ||
            (timeLineFilter?.quarterFieldId === field.uid && timeLineFilter?.quarterValueFormat) ||
            undefined
        );
    };

    setReconRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetReconRequests),
            delay(10),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$)
            ),
            mergeMap(([action, organizationId, year, month]) => {
                if (!year || !month) {
                    return [];
                }
                return of(
                    SetFilingReconRequestsPagingState({
                        serviceId: action.serviceId,
                        pagingState: action.pagingState,
                        organizationId,
                    })
                );
            })
        )
    );

    refreshFilingReconRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetFilingReconRequests),
            delay(10),
            mergeMap((action) => {
                return of(
                    SetFilingReconRequestsPagination({
                        serviceId: action.serviceId,
                        pushAtTop: action.pushAtTop,
                        forceFetch: action.pushAtTop,
                    })
                );
            })
        )
    );

    setFilingReconRequestsPagination$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetFilingReconRequestsPagination),
            delay(10),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) => {
                if (action.organizationId) {
                    return [];
                }
                return of(
                    SetFilingReconRequestsPagination({
                        ...action,
                        organizationId,
                    })
                );
            })
        )
    );

    getFilingReconRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetFilingReconRequestsPagination),
            delay(10),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$),
                this.store$.select(getFilingReconOrganization$)
            ),
            mergeMap(([action, appsMap, year, month, paginationMap]) => {
                const restApiName = appsMap?.[action.serviceId]?.restApiName;
                if (!restApiName || !action.organizationId) {
                    return [];
                }
                const dateMonth = new Date().getMonth() + 1;
                const date =
                    dateMonth > parseInt(month) ? new Date(parseInt(year), dateMonth, 0).getDate() + '' : new Date().getDate() + '';
                const fromDate = (year && month && `${year}-${month?.length === 1 ? `0${month}` : month}-01`) || '';
                let toDate =
                    (year && month && `${year}-${month?.length === 1 ? `0${month}` : month}-${date.length === 1 ? `0${date}` : date}`) ||
                    '';
                if (year && month && (this._utils.transformDateToLocale(toDate, 'yyyy-mm-dd', 'DATE', true) as any) > new Date()) {
                    toDate = this._utils.transformDateToLocale(new Date() as any, 'DATE', 'yyyy-MM-dd', true) as string;
                }
                const criteria = `${fromDate}|${toDate}`;
                const pagination = paginationMap?.[action.serviceId]?.[criteria];
                return of(
                    GetReconRequests({
                        fromDate,
                        toDate,
                        restApiName,
                        serviceId: action.serviceId,
                        pagingState: !action.forceFetch && pagination?.pagingState?.[pagination.pageIndex],
                        size: 100,
                        pushAtTop: action.pushAtTop,
                        noAlerts: true,
                    })
                );
            })
        )
    );

    clearFilingReconRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClearFilingReconRequests),
            delay(10),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$)
            ),
            mergeMap(([action, organizationId, year, month]) => {
                const date = new Date().getDate();
                const fromDate = `${year}-${month}-${date}`;
                const toDate = `${year}-${month}-${date}`;
                const criteria = `${fromDate}|${toDate}`;
                return of(
                    ClearReconRequests({
                        organizationId,
                        serviceId: action.serviceId,
                        criteria,
                    })
                );
            })
        )
    );

    clearFilingRequests$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClearFilingRequests),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$),
                this.store$.select(currentFilingQuarter$)
            ),
            mergeMap(([action, organizationId, year, month, quarter]) => {
                return of(
                    ClearRequests({
                        organizationId,
                        serviceId: action.serviceId,
                        criteria: `${year || ''}|${month || ''}|${quarter || ''}`,
                    })
                );
            })
        )
    );

    selectFilingInstance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SelectFilingInstances),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$),
                this.store$.select(currentFilingQuarter$)
            ),
            mergeMap(([action, organizationId, year, month, quarter]) => {
                return of(
                    SelectInstances({
                        organizationId,
                        serviceId: action.serviceId,
                        criteria: `${year || ''}|${month || ''}|${quarter || ''}`,
                        instanceIds: action.instanceIds,
                    })
                );
            })
        )
    );

    getFilingInstancePrimaryEntityRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetFilingInstancePrimaryEntityRecords),
            delay(10),
            withLatestFrom(this.store$.select(getFilingSelectedInstanceDetails$), this.store$.select(getFilingSelectedAppMetaData$)),
            mergeMap(([_action, instanceDetails, metaData]) => {
                return of(
                    GetRecords({
                        entityUid: metaData?.entities?.find((entity) => entity.primary)?.uid,
                        instanceId: instanceDetails?.instanceId,
                        serviceId: instanceDetails?.serviceId,
                        noAlerts: true,
                        parentRecordId: undefined,
                    })
                );
            })
        )
    );

    getFilingInstanceEntityRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetFilingInstanceEntityRecords),
            delay(10),
            withLatestFrom(
                this.store$.select(getFilingSelectedInstanceDetails$),
                this.store$.select(getFilingSelectedEntityDetails$),
                this.store$.select(getFilingParentEntityDetails$),
                this.store$.select(getFilingRecordsSearchCriteria$),
                this.store$.select(getFilingMainAssociationFieldId$),
                this.store$.select(getFilingNonDetachedGroupEntityId$),
                this.store$.select(getFilingDetachedGroupSource1EntityId$),
                this.store$.select(getFilingDetachedGroupSource2EntityId$)
            ),
            mergeMap(
                ([
                    action,
                    instanceDetails,
                    viewDetails,
                    parentViewDetails,
                    searchCriteria,
                    associationFieldId,
                    groupedEntityId,
                    source1EntityId,
                    source2EntityId,
                ]) => {
                    const entityId = viewDetails?.entityId;
                    if (action.reconSpecific) {
                        (!action.source || action.source === 'source1') &&
                            this.store$.dispatch(
                                SearchRecords({
                                    entityUid: source1EntityId || entityId,
                                    instanceId: instanceDetails?.instanceId,
                                    serviceId: instanceDetails?.serviceId,
                                    parentRecordId: parentViewDetails?.recordId,
                                    noAlerts: true,
                                    reconSpecific: 'source1',
                                })
                            );
                        (!action.source || action.source === 'source2') &&
                            this.store$.dispatch(
                                SearchRecords({
                                    entityUid: source2EntityId || entityId,
                                    instanceId: instanceDetails?.instanceId,
                                    serviceId: instanceDetails?.serviceId,
                                    parentRecordId: parentViewDetails?.recordId,
                                    noAlerts: true,
                                    reconSpecific: 'source2',
                                })
                            );
                        action.source === 'grouped' &&
                            this.store$.dispatch(
                                SearchRecords({
                                    entityUid: groupedEntityId || entityId,
                                    instanceId: instanceDetails?.instanceId,
                                    serviceId: instanceDetails?.serviceId,
                                    parentRecordId: parentViewDetails?.recordId,
                                    noAlerts: true,
                                    reconSpecific: 'grouped',
                                    associationKey: associationFieldId,
                                })
                            );
                        return [];
                    }
                    if (searchCriteria) {
                        this.store$.dispatch(
                            SearchRecords({
                                entityUid: entityId,
                                instanceId: instanceDetails?.instanceId,
                                serviceId: instanceDetails?.serviceId,
                                parentRecordId: parentViewDetails?.recordId,
                                noAlerts: true,
                                criteria: searchCriteria,
                            })
                        );
                        return [];
                    }
                    return of(
                        GetRecords({
                            entityUid: entityId,
                            instanceId: instanceDetails?.instanceId,
                            serviceId: instanceDetails?.serviceId,
                            parentRecordId: parentViewDetails?.recordId,
                            noAlerts: true,
                        })
                    );
                }
            )
        )
    );

    checkFilingInstanceSearchRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetSearchRecords),
            delay(10),
            mergeMap((action) => {
                if (action.associationKey?.length > 0 && action.records?.length > 0) {
                    return of(
                        GetFilingInstanceEntityRecords({
                            reconSpecific: true,
                            source: action.reconSpecific,
                        })
                    );
                }
                return [];
            })
        )
    );

    setFilingInstanceEntityPagination$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetFilingInstanceEntityPagination),
            delay(10),
            withLatestFrom(
                this.store$.select(getFilingSelectedInstanceDetails$),
                this.store$.select(getFilingSelectedEntityDetails$),
                this.store$.select(getFilingParentEntityDetails$)
            ),
            mergeMap(([action, instanceDetails, viewDetails, parentViewDetails]) => {
                return of(
                    SetRecordsPagination({
                        serviceId: instanceDetails.serviceId,
                        instanceId: instanceDetails?.instanceId,
                        entityUid: action.entityId || viewDetails?.entityId,
                        parentRecordId: action.entityId ? undefined : parentViewDetails?.recordId,
                        pageIndex: action?.pageIndex,
                        pageSize: action?.pageSize,
                        reconSpecific: action.reconSpecific,
                        noAlerts: true,
                    })
                );
            })
        )
    );

    setFilingInstanceEntityReconCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetFilingInstanceEntityCriteria),
            delay(10),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getFilingSelectedInstanceDetails$),
                this.store$.select(getFilingSelectedEntityDetails$),
                this.store$.select(getFilingParentEntityDetails$)
            ),
            mergeMap(([action, organizationId, instanceDetails, viewDetails, parentViewDetails]) => {
                return of(
                    SetRecordsSearchCriteria({
                        entityUid: action.entityId || viewDetails?.entityId,
                        instanceId: instanceDetails?.instanceId,
                        serviceId: instanceDetails.serviceId,
                        parentRecordId: action.source ? undefined : parentViewDetails?.recordId,
                        organizationId: organizationId,
                        criteria: action.criteria,
                        reconSource: action.source,
                    })
                );
            })
        )
    );
}
