import { createAction, props } from '@ngrx/store';
import { CustomPeriodicSearch } from 'taxilla-library';

import { RecordsState } from '../states';

enum GSTFilingType {
    SET_FILING_YEAR = '[GST_FILING] Set Filing Year',
    SET_FILING_MONTH = '[GST_FILING] Set Filing Month',
    SET_FILING_APP_GROUP = '[GST_FILING] Set Filing App Group',
    GET_OTP_APP_META_DATA = '[GST_FILING] Get OTP App MetaData',
    GET_OTP_RECORD_DATA = '[GST_FILING] Get OTP App Record Data',
    CREATE_OTP_REQUEST = '[GST_FILING] Create OTP Request',
    CREATE_FILING_REQUEST_MANUALLY = '[GSTIN] Create Filing Request Manually',
    GET_FILING_RECON_REQUESTS = '[GST_FILING] Get Recon Requests',
    SET_FILING_RECON_REQUESTS_PAGINATION = '[GST_FILING] Set Recon Requests Pagination',
    SET_FILING_RECON_REQUESTS_PAGINGSTATE = '[GST_FILING] Set Recon Requests Paging State',
    CLEAR_FILING_RECON_REQUESTS = '[GST_FILING] Clear Recon Requests',
    CLEAR_FILING_REQUESTS = '[GST_FILING] Clear Requests',
    SELECT_FILING_INSTANCES = '[GST_FILING] Select Filing Instances',
    OPEN_RELATED_NOTIFICATION = '[GST_FILING] Open Related Notification',
    FILING_INSTANCES_PAGINATION_CHANGED = '[GST_FILING] Filing Instances Pagination Changed',
    FILING_REQUESTS_PAGINATION_CHANGED = '[GST_FILING] Filing Requests Pagination Changed',
    SET_FILING_SELECTED_INSTANCE_DETAILS = '[GST_FILING] Set Selected Instance details',
    SET_FILING_INSTANCE_ENTITY_VIEW = '[GST_FILING] Set Instance Entity View',
    SET_FILING_INSTANCE_ENTITY_IDS = '[GST_FILING] Get Filing Instance Entity Ids',
    GET_FILING_INSTANCE_PRIMARY_ENTITY_RECORDS = '[GST_FILING] Get Selected Instance Primary Entity Records',
    GET_FILING_INSTANCE_ENTITY_RECORDS = '[GST_FILING] Get Filing Instance Entity Records',
    SET_FILING_INSTANCE_ENTITY_PAGINATION = '[GST_FILING] Get Filing Instance Entity Pagination',
    SET_FILING_INSTANCE_ENTITY_CRITERIA = '[GST_FILING] Get Filing Instance Entity Recon Criteria',
    OPEN_INSTANCE_DATA_DIALOG = '[GST_FILING] Open Instance Data Dialog',
    OPEN_RECONCILE_DATA_DIALOG = '[GST_FILING] Open Reconcile Data Dialog',
    RECONCILE_DATA_DIALOG_CLOSED = '[GST_FILING] Reconcile Data Dialog Closed',
    SET_APP_CUSTOM_PERIODIC_SEARCH = '[APPS] Set App Custom Periodic Search',
}

export const SetFilingYear = createAction(
    GSTFilingType.SET_FILING_YEAR,
    props<{
        year: string;
    }>()
);

export const SetFilingMonth = createAction(
    GSTFilingType.SET_FILING_MONTH,
    props<{
        month: string;
        quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
    }>()
);

export const SetFilingAppGroup = createAction(
    GSTFilingType.SET_FILING_APP_GROUP,
    props<{
        app: string;
    }>()
);

export const GetOTPAppMetaData = createAction(GSTFilingType.GET_OTP_APP_META_DATA);

export const GetOTPRecordData = createAction(
    GSTFilingType.GET_OTP_RECORD_DATA,
    props<{
        instanceId: string;
    }>()
);

export const CreateOTPRequest = createAction(GSTFilingType.CREATE_OTP_REQUEST, props<{ gstin: string }>());

export const GetFilingReconRequests = createAction(
    GSTFilingType.GET_FILING_RECON_REQUESTS,
    props<{
        organizationId?: string;
        serviceId: string;
        pushAtTop?: boolean;
        forceFetch?: boolean;
    }>()
);

export const CreateFilingRequestManually = createAction(
    GSTFilingType.CREATE_FILING_REQUEST_MANUALLY,
    props<{
        serviceId: string;
        gstin: string;
        multipleCalls?: boolean;
    }>()
);

export const SetFilingReconRequestsPagination = createAction(
    GSTFilingType.SET_FILING_RECON_REQUESTS_PAGINATION,
    props<{
        organizationId?: string;
        serviceId: string;
        size?: number;
        pageIndex?: number;
        pushAtTop?: boolean;
        forceFetch?: boolean;
    }>()
);

export const SetFilingReconRequestsPagingState = createAction(
    GSTFilingType.SET_FILING_RECON_REQUESTS_PAGINGSTATE,
    props<{
        organizationId?: string;
        serviceId: string;
        pagingState?: string;
    }>()
);

export const ClearFilingReconRequests = createAction(
    GSTFilingType.CLEAR_FILING_RECON_REQUESTS,
    props<{
        serviceId: string;
    }>()
);

export const ClearFilingRequests = createAction(
    GSTFilingType.CLEAR_FILING_REQUESTS,
    props<{
        serviceId: string;
    }>()
);

export const SelectFilingInstances = createAction(
    GSTFilingType.SELECT_FILING_INSTANCES,
    props<{
        serviceId: string;
        instanceIds: string[];
    }>()
);

export const OpenRelatedNotification = createAction(
    GSTFilingType.OPEN_RELATED_NOTIFICATION,
    props<{
        serviceId: string;
    }>()
);

export const FilingInstancesPaginationChanged = createAction(
    GSTFilingType.FILING_INSTANCES_PAGINATION_CHANGED,
    props<{
        serviceId: string;
        instanceIds: string[];
    }>()
);

export const FilingRequestsPaginationChanged = createAction(
    GSTFilingType.FILING_REQUESTS_PAGINATION_CHANGED,
    props<{
        serviceId: string;
        requestIds: string[];
    }>()
);

export const SetFilingSelectedInstanceDetails = createAction(
    GSTFilingType.SET_FILING_SELECTED_INSTANCE_DETAILS,
    props<{
        serviceId?: string;
        instanceId?: string;
        parentRecordId?: string;
        entityId?: string;
    }>()
);

export const SetFilingInstanceEntityView = createAction(
    GSTFilingType.SET_FILING_INSTANCE_ENTITY_VIEW,
    props<{
        viewLevel: number;
        recordId: string;
        entityId?: string;
    }>()
);

export const GetFilingInstancePrimaryEntityRecords = createAction(GSTFilingType.GET_FILING_INSTANCE_PRIMARY_ENTITY_RECORDS);

export const GetFilingInstanceEntityRecords = createAction(
    GSTFilingType.GET_FILING_INSTANCE_ENTITY_RECORDS,
    props<{
        reconSpecific?: boolean;
        source?: 'source1' | 'source2' | 'grouped';
    }>()
);

export const SetFilingInstanceEntityPagination = createAction(
    GSTFilingType.SET_FILING_INSTANCE_ENTITY_PAGINATION,
    props<{
        pageIndex: number;
        pageSize: number;
        reconSpecific?: 'source1' | 'source2' | 'grouped';
        entityId: string;
    }>()
);

export const SetFilingInstanceEntityIds = createAction(
    GSTFilingType.SET_FILING_INSTANCE_ENTITY_IDS,
    props<{
        detachedSource1EntityId: string;
        detachedSource2EntityId: string;
        nonDetachedSourceEntityId: string;
    }>()
);

export const OpenFilingInstanceDialog = createAction(
    GSTFilingType.OPEN_INSTANCE_DATA_DIALOG,
    props<{
        instanceId: string;
    }>()
);

export const OpenReconcileDataDialog = createAction(
    GSTFilingType.OPEN_RECONCILE_DATA_DIALOG,
    props<{
        instanceId: string;
    }>()
);

export const ReconcileDataDialogClosed = createAction(GSTFilingType.RECONCILE_DATA_DIALOG_CLOSED);

export const SetFilingInstanceEntityCriteria = createAction(
    GSTFilingType.SET_FILING_INSTANCE_ENTITY_CRITERIA,
    props<{
        entityId: string;
        source?: 'source1' | 'source2' | 'grouped';
        criteria: RecordsState['recordIds']['orgId']['serviceId']['instanceId']['entityId']['reconSpecific']['source1']['criteria'];
    }>()
);

export const SetCustomPeriodicSearch = createAction(
    GSTFilingType.SET_APP_CUSTOM_PERIODIC_SEARCH,
    props<{
        serviceId: string;
        assetMetaUID: string;
        customPeriodicSearch: CustomPeriodicSearch;
    }>()
);
