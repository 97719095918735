import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@env';
import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'ar',
                        label: 'Arabic',
                    },
                    {
                        id: 'sr-cyrl',
                        label: 'Serbian Cryllic',
                    },
                    {
                        id: 'sr-latn',
                        label: 'Serbian Latin',
                    },
                    {
                        id: 'pl',
                        label: 'Polish',
                    },
                    {
                        id: 'ja',
                        label: 'Japanese',
                    },
                    {
                        id: 'ro',
                        label: 'Romania',
                    },
                    {
                        id: 'fr',
                        label: 'French',
                    },
                ],
                flatten: {
                    aot: environment.production,
                },
                defaultLang: environment.defaultLanguage || 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: environment.production,
            }),
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslateRootModule {}
