import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonUtilsService, NotificationEvent, Record } from 'taxilla-library';

import {
    getAllTransformationsServicesMap$,
    getAppsMetaDataMap$,
    getAppsTemplate$,
    getBridgeNodesMap$,
    getCurrentOrganizationId$,
    getInstancesOrgServicesMap$,
    getNotificationsMap$,
    getReportsMap$,
    getSearchReportsOrgMapByCurrentOrgId$,
    getServicesInstancesMap$,
    getSubscribedAppsMap$,
    getWorkflowInstances$,
    getWorkflowsLoadingMap$,
    transformMetaDataWithTemplate,
} from '.';
import { AppTemplate } from '../../models/app-template.class';
import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { Instance } from '../../models/instance.interface';
import { transformAppMetaData } from '../constants/configuration.constant';
import { ENINVOICE_REDUCER_KEY } from '../reducers';
import { EninvoiceState } from '../states/eninvoice.state';

const currentState = createFeatureSelector<EninvoiceState>(ENINVOICE_REDUCER_KEY);

const getEninvoiceSelectedBridgeRestApiName$ = createSelector(currentState, (state) => state.selectedBridgeRestApiName);

const getEninvoiceSelectedAppRestApiName$ = createSelector(currentState, (state) => state.selectedAppRestApiName);

const getEninvoiceSelectedReportName$ = createSelector(currentState, (state) => state.selectedReportName);

export const getEninvoiceSelectedInstanceId$ = createSelector(currentState, (state) => state.selectedInstanceId);

export const getEninvoiceSelectedRequestId$ = createSelector(currentState, (state) => state.selectedRequestId);

export const getEnInvoiceApps$ = createSelector(getSubscribedAppsMap$, (appsMap) =>
    Object.keys(appsMap)
        ?.filter((serviceId) => checkIfEninvoiceApp(appsMap[serviceId]))
        ?.reduce((list, id) => {
            appsMap[id] && list.push(appsMap[id]);
            return list;
        }, [] as AssetService[])
        .sort((a, b) => a?.displayName?.localeCompare(b?.displayName))
);

export const getEninvoiceSubscribedAppsApiMap$ = createSelector(getEnInvoiceApps$, getBridgeNodesMap$, (apps, bridgesMap) => {
    return {
        ...(apps.reduce((appsMap, app) => {
            appsMap[app.restApiName] = app;
            return appsMap;
        }, {}) || {}),
        ...Object.keys(bridgesMap || {}).reduce((nodesMap, bridgeAppMetaUId) => {
            const bridgeApp = apps?.find((service) => service.assetMetaUId === bridgeAppMetaUId);
            Object.keys(bridgesMap[bridgeAppMetaUId] || {}).forEach((nodeId) => {
                const node = bridgesMap[bridgeAppMetaUId][nodeId];
                if (!node.restApiName) {
                    return;
                }
                nodesMap[node.restApiName] = JSON.parse(JSON.stringify(node));
                nodesMap[node.restApiName].bridge = bridgeApp;
                nodesMap[node.restApiName].assetMetaUId = node.id;
            });
            return nodesMap;
        }, {}),
    } as { [property: string]: AssetService };
});

const checkIfEninvoiceApp = (app: AssetService) => {
    return app?.tags?.find(
        (tag) => (tag.tagKey === 'uitype' && tag.tagValue === 'eninvoice') || (tag.tagKey === 'package' && tag.tagValue === 'ewb')
    );
};

export const getEninvoiceSelectedBridgeApp$ = createSelector(
    getEninvoiceSubscribedAppsApiMap$,
    getEninvoiceSelectedBridgeRestApiName$,
    (map, api) => map?.[api]
);

const getEninvoiceSelectedBridgeNode$ = createSelector(
    getEninvoiceSelectedBridgeApp$,
    getEninvoiceSelectedAppRestApiName$,
    getBridgeNodesMap$,
    (bridge, subAppApi, nodesMap) => {
        if (!(bridge?.assetMetaUId?.length > 0 && subAppApi?.length > 0)) {
            return;
        }
        const nodes = nodesMap?.[bridge.assetMetaUId];
        for (let node in nodes) {
            if (nodes?.[node]?.restApiName === subAppApi) {
                return nodes?.[node];
            }
        }
    }
);

export const getEninvoiceSelectedApp$ = createSelector(
    getEninvoiceSelectedBridgeRestApiName$,
    getEninvoiceSelectedAppRestApiName$,
    getEninvoiceSubscribedAppsApiMap$,
    getEninvoiceSelectedBridgeNode$,
    (bridgeApi, appApi, map, bridgeNode) => {
        return bridgeApi ? (bridgeNode as any as AssetService) : map?.[appApi];
    }
);

export const getEninvoiceSelectedBridgeReport$ = createSelector(
    getBridgeNodesMap$,
    getEninvoiceSelectedBridgeApp$,
    getEninvoiceSelectedReportName$,
    (nodesMap, bridge, subAppApi) => {
        if (!(bridge?.assetMetaUId?.length > 0 && subAppApi?.length > 0)) {
            return;
        }
        const nodes = nodesMap?.[bridge.assetMetaUId];
        for (let node in nodes) {
            if (nodes?.[node]?.restApiName === subAppApi) {
                return nodes?.[node];
            }
        }
    }
);

export const getEninvoiceBridgeMetaData$ = createSelector(
    getAppsMetaDataMap$,
    getAppsTemplate$,
    getEninvoiceSelectedBridgeApp$,
    getCurrentOrganizationId$,
    (metaDataMap, appsTemplateMap, app, orgId) =>
        transformEninvoiceMetaData(metaDataMap, appsTemplateMap, app?.serviceId, app?.assetMetaUId, orgId)
);

export const getEninvoiceAppMetaData$ = createSelector(
    getAppsMetaDataMap$,
    getAppsTemplate$,
    getEninvoiceSelectedApp$,
    getCurrentOrganizationId$,
    (metaDataMap, appsTemplateMap, app, orgId) =>
        transformEninvoiceMetaData(metaDataMap, appsTemplateMap, app?.serviceId, app?.assetMetaUId, orgId)
);

const transformEninvoiceMetaData = (
    metaDataMap: {
        [property: string]: {
            [property: string]: AssetData;
        };
    },
    appsTemplateMap: {
        [property: string]: {
            isOfCurrentOrg: boolean;
            uiTemplate: AppTemplate;
        };
    },
    serviceId: string,
    assetId: string,
    orgId: string
) => {
    const metaData = transformMetaDataWithTemplate(metaDataMap, appsTemplateMap, serviceId, assetId);
    let appData = transformAppMetaData(metaData?.restAPIName, metaData);
    if (appData && orgId) {
        appData.organizationId = orgId;
    }
    return appData;
};

const getEninvoiceInstanceIds$ = createSelector(
    getInstancesOrgServicesMap$,
    getEninvoiceSelectedApp$,
    (instancesMap, app) => instancesMap?.[app?.serviceId]?.searchedInstances?.['eninvoice']
);

export const getEninvoiceInstancesPagination$ = createSelector(
    getInstancesOrgServicesMap$,
    getEninvoiceSelectedApp$,
    getEninvoiceInstanceIds$,
    (instancesMap, app, instanceIds) => {
        const page = instancesMap?.[app?.serviceId]?.pagination || { size: 0, pageIndex: 0, pagingState: undefined };
        const fetchedCount = instanceIds?.length || 0;
        const pageCount = (page?.pageIndex + 1) * page?.size;
        const count = (page?.pagingState ? pageCount : fetchedCount) + (page?.pagingState !== undefined ? 1 : 0);
        return {
            ...page,
            count,
        };
    }
);

const getEninvoicePaginatedInstanceIds$ = createSelector(
    getEninvoiceInstancesPagination$,
    getEninvoiceInstanceIds$,
    (pagination, instanceIds) => {
        const start = (pagination?.pageIndex || 0) * (pagination?.size || 0);
        const end = ((pagination?.pageIndex || 0) + 1) * (pagination?.size || 0);
        return instanceIds?.filter((_instanceId, index) => index >= start && index < end);
    }
);

const getEninvoiceInstances$ = createSelector(getServicesInstancesMap$, getEninvoicePaginatedInstanceIds$, (instancesMap, instanceIds) =>
    instanceIds?.reduce((instances, id) => {
        instances.push(instancesMap?.[id]);
        return instances;
    }, [] as Instance[])
);

export const getEninvoiceConvertedInstances$ = createSelector(getEninvoiceInstances$, (instances) => {
    return (
        instances?.reduce(
            (records, instance) => {
                const record = {
                    id: undefined,
                    assetDataId: instance.assetDataId,
                    fields: [],
                    grids: [],
                    errors: [],
                    warnings: [],
                };
                const valuesMap = {
                    ...instance?.processViewAttributes,
                };
                Object.keys(instance.attributesMap).forEach((attr) => {
                    instance.attributesMap[attr]?.list?.forEach((field) => {
                        valuesMap[field?.name?.split('^')[1]] = field.value;
                    });
                });
                Object.keys(valuesMap).forEach((id) => {
                    record.fields.push({ id, value: valuesMap?.[id] });
                });
                records.push(record as any);
                return records;
            },
            [] as {
                id: string;
                fields: {
                    id: string;
                    value: any;
                }[];
                grids: [];
                errors: string[];
                warnings: string[];
                assetDataId: string;
            }[]
        ) || []
    );
});

export const getEninvoiceSelectedInstance$ = createSelector(
    getServicesInstancesMap$,
    getEninvoiceSelectedInstanceId$,
    (map, instanceId) => map?.[instanceId]
);

export const getEninvoiceConvertedInstance$ = createSelector(getEninvoiceSelectedInstance$, (instance) => {
    if (!instance) {
        return;
    }
    const record = {
        id: undefined,
        assetDataId: instance.assetDataId,
        fields: [],
        grids: [],
        errors: [],
        warnings: [],
    };

    const valuesMap = {
        ...instance?.processViewAttributes,
    };

    Object.keys(instance.attributesMap).forEach((attr) => {
        instance.attributesMap[attr]?.list?.forEach((field) => {
            valuesMap[field?.name?.split('^')[1]] = field.value;
        });
    });

    Object.keys(valuesMap).forEach((id) => {
        record.fields.push({ id, value: valuesMap?.[id] });
    });
    return record as any as Record;
});

export const getEninvoiceInstanceMetaData$ = createSelector(
    getAppsMetaDataMap$,
    getAppsTemplate$,
    getEninvoiceSelectedApp$,
    getEninvoiceSelectedInstance$,
    getCurrentOrganizationId$,
    (metaDataMap, appsTemplateMap, app, instance, orgId) =>
        transformEninvoiceMetaData(metaDataMap, appsTemplateMap, app?.serviceId, instance?.assetId, orgId)
);

export const getEninvoiceSelectedInstanceReports$ = createSelector(
    getReportsMap$,
    getEninvoiceSelectedInstanceId$,
    (reportsMap, selectedInstanceId) => reportsMap?.[selectedInstanceId] || []
);

export const getEninvoiceSelectedInstanceNotifications$ = createSelector(
    getEninvoiceSelectedInstanceId$,
    getNotificationsMap$,
    (instaceId, notificationsMap) => {
        const instanceNotifications = notificationsMap?.[instaceId];
        return (instanceNotifications?.ids as string[])?.reduce((events, id) => {
            events.push(instanceNotifications?.entities?.[id]);
            return events;
        }, [] as NotificationEvent[]);
    }
);

export const getEninvoiceSearchReportsByServiceId$ = createSelector(
    getEninvoiceSelectedApp$,
    getSearchReportsOrgMapByCurrentOrgId$,
    (app, searchReportsServiceMap) => searchReportsServiceMap?.[app?.serviceId]
);

export const getEninvoiceSearchReports$ = createSelector(
    getEninvoiceSearchReportsByServiceId$,
    (searchReportsService) => searchReportsService?.searchReports
);

export const getEninvoiceSearchReportsPagination$ = createSelector(getEninvoiceSearchReportsByServiceId$, (searchReportsService) => {
    const pagination = searchReportsService?.searchReportsPagination;
    const count = searchReportsService?.searchReports?.length || 0;
    return {
        count,
        pageIndex: pagination?.pageIndex || 0,
        pageSize: pagination?.pageSize || 10,
        pagingState: pagination?.pagingState,
        timeRange: pagination?.timeRange || [],
    };
});

export const getEninvoiceSelectedPageSearchReports$ = createSelector(
    getEninvoiceSearchReportsPagination$,
    getEninvoiceSearchReports$,
    (pagination, allSearchReports) => {
        const start = pagination.pageIndex * pagination.pageSize;
        const end = (pagination.pageIndex + 1) * pagination.pageSize;
        return allSearchReports?.filter((_report, index) => index >= start && index < end);
    }
);

export const getEninvoiceOutboundChains$ = createSelector(
    getEninvoiceSelectedApp$,
    getAllTransformationsServicesMap$,
    (app, map) => map?.[app?.serviceId]?.transformations?.chains || []
);

export const getEninvoiceDownloadableSearchReport$ = createSelector(
    getEninvoiceSearchReportsByServiceId$,
    (searchReportsService) => searchReportsService?.downloadedSearchReports
);

export const getEninvoiceSelectedInstanceWorkFlow$ = createSelector(
    getWorkflowInstances$,
    getEninvoiceSelectedInstanceId$,
    getEninvoiceInstanceMetaData$,
    (workflowMap, selectedInstanceId, metaData) => {
        const stages = metaData?.workflowMetadata?.stages;
        // Need to update display name
        const workflows = CommonUtilsService.cloneObject(workflowMap?.[selectedInstanceId]);
        workflows?.forEach((workflow) => {
            const stage = stages?.find((stage) => stage.id === workflow.taskDefinitionKey);
            workflow.displayName = stage?.displayName || workflow.displayName;
        });
        return workflows || [];
    }
);

export const getEninvoiceCurrentWorkflow$ = createSelector(getEninvoiceSelectedInstanceWorkFlow$, (workflows) => {
    let currentlySelectedWorkflow = workflows?.find((workflow) => (workflow.startTime && !workflow.endTime) || workflow.cancelled);
    if (!currentlySelectedWorkflow) {
        currentlySelectedWorkflow = workflows
            .slice(0)
            .reverse()
            .find((rule) => rule.startTime?.length > 0 && rule.endTime?.length > 0);
    }
    return currentlySelectedWorkflow;
});

export const getEninvoiceWorkflowLoadingStatus$ = createSelector(
    getWorkflowsLoadingMap$,
    getEninvoiceSelectedInstanceId$,
    getEninvoiceInstanceMetaData$,
    getEninvoiceCurrentWorkflow$,
    (loadingMap, instanceId, metaData, stage) => loadingMap?.[instanceId] || (metaData?.workflowMetadata?.stages?.length > 0 && !stage)
);
