import { createReducer, on } from '@ngrx/store';

import { SelectDashboard, SetDashboardConfiguration, SetDashboardLoader, SetDashboards, SetFilteringAttributesMetadata } from '../actions';
import * as sessionActions from '../actions/session.actions';
import { dashboardState } from '../states';

export const DASHBOARD_REDUCER = createReducer(
    dashboardState,
    on(sessionActions.SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action.organization?.id,
        };
    }),
    on(SelectDashboard, (state, action) => {
        return {
            ...state,
            selectedDashboard: action.dashboard,
        };
    }),
    on(SetDashboards, (state, action) => {
        return {
            ...state,
            analyticDashboards: {
                ...state?.analyticDashboards,
                [action.currentOrganizationId]: {
                    ...state?.analyticDashboards?.[action?.currentOrganizationId],
                    dashboards: action.analyticDashboards,
                },
            },
        };
    }),
    on(SetDashboardLoader, (state, action) => {
        return {
            ...state,
            isDashboardsLoading: action.isDashboardsLoading,
        };
    }),
    on(SetDashboardConfiguration, (state, action) => {
        return {
            ...state,
            analyticDashboards: {
                ...state?.analyticDashboards,
                [action.organizationId]: {
                    ...state?.analyticDashboards?.[action?.organizationId],
                    dashboardDetails: {
                        ...state?.analyticDashboards?.[action?.organizationId]?.dashboardDetails,
                        [action.serviceId]: {
                            ...state?.analyticDashboards?.[action?.organizationId]?.dashboardDetails?.[action.serviceId],
                            configuration: action.configuration,
                        },
                    },
                },
            },
        };
    }),
    on(SetFilteringAttributesMetadata, (state, action) => {
        return {
            ...state,
            analyticDashboards: {
                ...state?.analyticDashboards,
                [action.organizationId]: {
                    ...state?.analyticDashboards?.[action?.organizationId],
                    dashboardDetails: {
                        ...state?.analyticDashboards?.[action?.organizationId]?.dashboardDetails,
                        [action.serviceId]: {
                            ...state?.analyticDashboards?.[action?.organizationId]?.dashboardDetails?.[action.serviceId],
                            metadata: action.metadata,
                        },
                    },
                },
            },
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => dashboardState)
);

export const DASHBOARD_REDUCER_KEY = 'dashboard';
