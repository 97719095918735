import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import {
    Get4ECPermission,
    getAnalyticsDashboards$,
    GetAppBridgeNodes,
    GetApplicationProperties,
    getApplicationProperties$,
    getCurrentOrganization$,
    getDashboardLoader$,
    GetDashboards,
    getLogo$,
    GetWorkQCount,
    getWorkQCount$,
    has4ECAccess$,
    isProviderOrganizationAndAdmin$,
    SetDashboards,
    SetLocation,
    UiSchemaService,
} from 'taxilla-library';

import { SettingsMenuInterface } from '../../interface/settings-menu.interface';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { Organization, OrganizationData } from '../../models/organization.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { ConfirmationDialogService } from '../../services/confirmation/confirmation-dialog.service';
import { LocationsService } from '../../services/locations/locations.service';
import { StoreService } from '../../services/store/store.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-taxilla-header',
    templateUrl: './taxilla-header.component.html',
    styleUrls: ['./taxilla-header.component.scss'],
})
export class TaxillaHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() loading: { [property: string]: boolean } = {};

    @ViewChild('matOrgMenuTrigger', { static: true }) _matOrgMenuTrigger: MatMenuTrigger;
    @ViewChild('matAppsMenuTrigger', { static: true }) _matAppsMenuTrigger: MatMenuTrigger;
    @ViewChild('matUiMenuTrigger') _matUiMenuTrigger: MatMenuTrigger;
    @ViewChild('tenantToFocus', { static: false }) _tenantToFocus: ElementRef;
    @ViewChild('appToFocus', { static: false }) _appToFocus: ElementRef;
    @ViewChild('tenantsMenu', { static: false }) _tenantsMenu: MatMenu;
    @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
    @ViewChild('matChartsMenuTrigger') matChartsMenuTrigger: MatMenuTrigger;

    private unSubscribe = new Subject<void>();
    public tenantsToRender: Organization[];
    public selectedSubTenant: OrganizationData;
    public filterAppName: string;
    public selectedAlpha: string;
    public filterTenantName: string;
    public filterSubTenantName: string;
    public subTenants: Organization[] = [];
    public subTentantToParentStructure: string[];
    public selectedTenant: OrganizationData;
    private organizationsMap: Map<string, Organization> = new Map();
    private isOrganizationsStructureComplete: boolean;
    private organizationsTree: Organization[] = [];
    private allSubApps: any = {};
    public alphabets: any[] = [];
    public levelWiseNodeMap: { [property: string]: any } = {};
    private totalNodes: any = [];
    private childNodes: any = {};
    private allTimeZones: any;
    public selectedTenantId: string;
    public selectedTenantName: string;
    public isSettingsSelected: boolean;
    public workqSelected: boolean;
    public currentApp: AssetService;
    public currentSubApp: BridgeNode;
    public currentReport: BridgeNode;
    public selectedAppName: string;
    private selectedApp: BehaviorSubject<AssetService> = new BehaviorSubject(undefined);
    private selectedSubApp: BehaviorSubject<BridgeNode> = new BehaviorSubject(undefined);
    private selectedReport: BehaviorSubject<BridgeNode> = new BehaviorSubject(undefined);
    public organizationsMenuOpened: boolean;
    public currentOrganizationId: BehaviorSubject<string> = new BehaviorSubject(undefined);
    private isInEnReport: boolean;
    private isInEnInvoice: boolean;
    private isInEnCollab: boolean;
    private isInEnRecon: boolean;
    private isInEnComply: boolean;
    public logoHref: string;
    private selectedAppId: string;
    private selectedSubAppId: string;
    private selectedReportId: string;
    public isInGuestMode: boolean;
    public uiList: {
        value: string;
        displayName: string;
        img?: string;
        addClass?: string;
    }[] = [];
    private currentUI: 'enComply' | 'enReport' | 'enInvoice' | 'enCollaborate' | 'enReconcile';
    private currentUIStream = new BehaviorSubject('');
    public showSwitchToParent: boolean;
    private triggeredExternally = new Subject<void>();
    private closedOrgMenuWithoutSelection = new Subject<void>();
    private organizationsMenuTriggeredExternally: boolean;
    public settings: SettingsMenuInterface = {
        name: 'settingsMenu',
        children: [],
        hasChildren: true,
    };
    public showWorkQ: boolean;
    public selectedTenantHelper: string[] = [];
    public selectedTenantHelperLength: number;
    private canShowWorkQ = !environment.restrictedFeatures?.workQ;
    public packageTags: { displayName: string; value: string }[] = [];
    private allSubscribedApps: AssetService[] = [];
    public allApps: AssetService[];
    public totalWorkqCount: number;
    public categoryFilter: { key?: string; value?: string; displayName?: string } = {};
    public filterTagName: string;
    public filterSubAppName: string;
    public loadingApps: boolean;
    public showDashboardIcon: boolean;
    public canShowAnalytics = environment.canShowAnalytics;
    public insights: any[] = [];
    public filterDashboardName: string = '';
    private has4ECAccess = false;
    public imgSrc: string;
    public isDashboardsLoading: boolean = false;
    public onNewUI = this._commonUtils.getCookie('new-ui') === 'true';
    public isNewUI: boolean = this._commonUtils.getCookie('new-ui') === 'true';
    public hideNewUISkin = environment.hideNewUISkin;
    private isProviderAdminAndOrganization: boolean;

    constructor(
        private _utils: UtilsService,
        private _locations: LocationsService,
        private _broadcaster: BroadcasterService,
        public _commonUtils: CommonUtilsService,
        private _router: Router,
        private _api: ApiService,
        private _confirmation: ConfirmationDialogService,
        private _storeService: StoreService,
        public _schema: UiSchemaService,
        private store$: Store,
        private actions$: Actions
    ) {
        if (this.checkIfCurrentOrgIDMatches()) {
            this.analyzeRouteContext();
            if (!this.isInEnComply && this.canShowWorkQ) {
                this.getCountOfActiveMessages();
            }
            this.analyzeAppRouteContext();
        } else {
            this.analyzeRouteContext();
        }
    }

    private checkIfCurrentOrgIDMatches = () => {
        const orgId = this._commonUtils.getFromStorage('currentOrganizationId');
        const urlId = this.getOrganizationIdFromURL();
        return orgId === urlId;
    };

    /**
     * get count of active messages
     */
    private getCountOfActiveMessages = () => {
        this.store$.dispatch(GetWorkQCount());
    };

    private selectAppsOnChange() {
        const apps = this.allSubscribedApps;
        const currentApp = apps && this.selectedAppId && apps.find((app) => app.restApiName === this.selectedAppId);
        if (!this.selectedApp.value || this.selectedApp.value?.restApiName !== currentApp?.restApiName) {
            this.allSubApps = {};
            this.selectedApp.next(currentApp);
            this.clearSearchAppName();
        }
        if (this.selectedSubAppId && this.allSubApps) {
            const subApps = this.allSubApps;
            Object.keys(subApps).forEach((subAppId) => {
                if (subApps[subAppId].restApiName === this.selectedSubAppId) {
                    if (!this.selectedSubApp.value || this.selectedSubApp.value?.restApiName !== subApps[subAppId].restApiName) {
                        this.selectedSubApp.next(subApps[subAppId]);
                    }
                }
            });
        } else if (this.selectedSubApp.value?.restApiName) {
            this.selectedSubApp.next(undefined);
        }
        if (this.selectedReportId && this.selectedSubApp.value && this.allSubApps) {
            const subApp = this.selectedSubApp.value;
            const reportNode = this.selectedReportId;
            const report = (subApp as any)?.reports?.find((node) => node.name === reportNode);
            if (!this.selectedReport.value || this.selectedReport.value?.name !== report?.name) {
                this.selectedReport.next(report);
            }
        } else if (this.selectedReport.value?.name) {
            this.selectedReport.next(undefined);
        }
    }

    @HostListener('click', ['$event'])
    onClick() {
        const element = document.querySelector('.activeorgName') as HTMLElement;
        if (element !== null) {
            const width = (document.querySelector('.activeorgName') as HTMLElement).offsetWidth;
            (document.querySelector('.rootOrgId') as HTMLElement).style.width = 'calc(100% - ' + width + 'px)';
        }
    }

    private startComponent = async () => {
        this.tenantsToRender = [];
        this.alphabets = this.getAlphabetsArray();
        Promise.all([this.loadOrganizationsManagement(), this.getSubscribedAppsForCurrentLocation()]).then(([, allApps]) => {
            this.allSubscribedApps = allApps;
            this.getPackageTagValues();
            const categoryKey = this._commonUtils.getFromStorage('categoryFilterKey');
            const categoryValue = this._commonUtils.getFromStorage('categoryFilterValue');
            const uiListItem = this.uiList.find((ui) => ui.value === categoryValue);
            const tagItem = this.packageTags.find((tag) => tag.value === categoryValue)?.displayName;
            const categoryFilterDisplayName = (categoryValue && uiListItem?.displayName) || (categoryKey === 'tag' && tagItem) || undefined;
            this.startAppFilter(categoryKey, categoryValue, categoryFilterDisplayName, true);
            this.startHeaderContext();
            this.getAllTimeZones(() => {
                const organizationId = this.getOrganizationIdFromURL();
                const organization = this.organizationsMap[organizationId];
                if (organization) {
                    const parentOrganization = this.getParentOrganization(organization);
                    this.selectedTenant = this.organizationsMap[parentOrganization.id];
                    this.selectedSubTenant = organization;
                    this.checkIfParentExists();
                    this.selectedTenantId = this.selectedTenant.id;
                    this.setTimeZoneOffset(organization);
                }
                this.analyzeOrganizationRouteContext();
            });
        });
    };

    private checkIfParentExists = () => {
        const childOrganization = this.selectedSubTenant;
        const parentOrganizationId = childOrganization?.parent?.id;
        const organizations = this.subTenants;
        const parentOrganization = parentOrganizationId && organizations.find((org) => org.id === parentOrganizationId);
        let parentId = this.checkForAccess(parentOrganization);
        this.showSwitchToParent = parentId ? true : false;
    };

    private getPackageTagValues = () => {
        this.packageTags = [];
        const packageTags = [];
        this.allSubscribedApps
            ?.filter((app) => ['ASSET', 'BRIDGEASSET', 'RECON'].includes(app.assetType))
            .forEach((app) => {
                app.tags
                    ?.filter((tag) => tag.tagKey === 'package')
                    .filter((tag) => !packageTags.includes(tag.tagValue))
                    .forEach((tag) => {
                        packageTags.push(tag.tagValue);
                        this.packageTags.push({
                            displayName: this._utils.convertEnumsToNormalWords(tag.tagValue),
                            value: tag.tagValue,
                        });
                    });
            });
    };

    private loadOrganizationsManagement = async () => {
        const organizations = await this.getOrganizations();
        const tempOrganizationsList = organizations?.slice(0);
        for (let i = 0; i < 2; i++) {
            this.generateTenantNodeHierarchy(tempOrganizationsList);
        }
        this.generatePartnerTenantNodeHierarchy(tempOrganizationsList);
        this.generateTenantDisplayNames();
        this.tenantsToRender = this.organizationsTree.slice(0);
    };

    private generateTenantNodeHierarchy = (organizationsList: Organization[]) => {
        for (let i = organizationsList.length - 1; i >= 0; i -= 1) {
            const organization = organizationsList[i];
            let node: Organization;
            if (this.organizationsMap[organization.id]) {
                node = this.organizationsMap[organization.id];
            } else if (organization.name) {
                node = this.createNode(organization);
                this.addToMap(node);
            } else {
                organizationsList.splice(i, 1);
                continue;
            }
            if (!organization.parent) {
                const currentOrg = this.organizationsTree.filter((org) => org.id == organization.id);
                if (currentOrg.length < 1 && organization.name) {
                    this.organizationsTree.push(node);
                }
                organizationsList.splice(i, 1);
            } else {
                if (this.organizationsMap[organization.parent.id]) {
                    this.organizationsMap[organization.parent.id].nextNodes = this.organizationsMap[organization.parent.id].nextNodes || [];
                    if (this.organizationsMap[organization.parent.id].nextNodes.indexOf(organization.id) > -1) {
                        // no operation
                    } else {
                        this.organizationsMap[organization.parent.id].nextNodes.push(organization.id);
                    }
                    organizationsList.splice(i, 1);
                }
            }
        }
    };

    private createNode = (organization?: Organization) => {
        if (organization) {
            organization.nextNodes = organization.nextNodes || [];
        }
        return organization;
    };

    private addToMap = (organization: Organization) => {
        if (organization) {
            this.organizationsMap[organization.id] = organization;
        }
    };

    private generatePartnerTenantNodeHierarchy = (tempOrganizationsList: Organization[]) => {
        for (let i = tempOrganizationsList.length - 1; i >= 0; i -= 1) {
            const tenant = tempOrganizationsList[i];
            if (tenant.parent) {
                let lastParent = tenant.parent;
                while (lastParent.parent) {
                    if (this.organizationsMap[lastParent.id]) {
                        lastParent = lastParent.parent;
                    } else {
                        if (lastParent.name) {
                            const parentNode = this.createPartnerNode(lastParent);
                            parentNode.noAccess = !this.organizationsMap[parentNode.id];
                            this.addToMap(parentNode);
                            lastParent = lastParent.parent;
                        }
                    }
                }
                if (!this.organizationsMap[lastParent.id]) {
                    if (lastParent.name) {
                        const node = this.createPartnerNode(lastParent);
                        node.noAccess = true;
                        this.addToMap(node);
                        this.organizationsTree = this.organizationsTree || [];
                        if (!this.isNodePresentInTree(node, this.organizationsTree)) {
                            this.organizationsTree.push(node);
                        }
                    }
                }
                this.organizationsMap[lastParent.id] && this.organizationsMap[lastParent.id].nextNodes.push(tenant.id);
                tempOrganizationsList.splice(i, 1);
            }
        }
    };

    /**
     * Is node present in tree already
     */
    private isNodePresentInTree = (node, tree) => {
        if (!node || !tree || !tree.length) {
            return;
        }
        let isPresent = false;
        for (const branch of tree) {
            if (branch.id === node.id) {
                isPresent = true;
                break;
            }
        }
        return isPresent;
    };

    private createPartnerNode = (organization: Organization) => {
        const partnerNode = this.createNode(organization);
        partnerNode && (partnerNode.isPartnerOrg = true);
        return partnerNode;
    };

    private generateTenantDisplayNames = () => {
        Object.keys(this.organizationsMap).forEach((organizationId) => {
            this.organizationsMap[organizationId].displayTenantName = this._utils.getTenantName(this.organizationsMap[organizationId]);
        });
    };

    private startHeaderContext = () => {
        const currentOrganizationId = this.getOrganizationIdFromURL();
        this.buildTenantsStructure(currentOrganizationId);
        this.isOrganizationsStructureComplete = true;
        this.buildTenantName();
    };

    private buildTenantsStructure = (organizationId: string) => {
        const organization = this.organizationsMap[organizationId];
        const parentOrganization = organization && this.getParentOrganization(organization);
        this.selectedTenant = parentOrganization && this.organizationsMap[parentOrganization.id];
        this.selectedTenantId = this.selectedTenant?.id;
        this.selectedSubTenant = organization;
        this.buildSelectedSubtenantStructure();
    };

    private buildSelectedSubtenantStructure = (buildFromTenant?: boolean) => {
        this.subTenants = [];
        this.selectedTenant &&
            this.buildSubTenantsList(
                this.subTenants as any,
                buildFromTenant ? this.organizationsMap[this.selectedTenant.id] : this.organizationsMap[this.selectedSubTenant.id]
            );
        this._broadcaster.broadcast('subTenants', this.subTenants);
        this.selectedSubTenant && this.buildParentsStructure();
        this.buildTenantName();
        this.checkIfParentExists();
    };

    private buildParentsStructure = () => {
        const parents = this.getLocationithParents([], this.selectedSubTenant);
        this.subTentantToParentStructure = parents;
        this.filterSubTenantName = '';
    };

    private buildSubTenantsList = (subTenants: OrganizationData[], organization: OrganizationData) => {
        if (organization.parent) {
            const parentOrganization: OrganizationData = this.organizationsMap[organization.parent.id];
            const parentIndex = subTenants.findIndex((org) => org.id === parentOrganization.id);
            if (parentIndex === -1) {
                this.buildSubTenantsList(subTenants, parentOrganization);
            }
        }
        const orgIndex = subTenants.findIndex((org) => org.id === organization.id);
        if (orgIndex === -1) {
            subTenants.push(organization);
            organization.nextNodes?.forEach((nodeId) => {
                const childOrganization = this.organizationsMap[nodeId];
                this.buildSubTenantsList(subTenants, childOrganization);
            });
        }
    };

    private buildTenantName = () => {
        const name = this._utils.getTenantName(this.selectedSubTenant, '|');
        this.selectedTenantName = name;
        const splitTenant = name?.split('|');
        if (splitTenant) {
            this.selectedTenantHelper = splitTenant;
            this.selectedTenantHelperLength = this.selectedTenantHelper.length;
        }
    };

    private getParentOrganization = (organization: Organization): Organization => {
        return organization?.parent ? this.getParentOrganization(organization.parent) : organization;
    };

    public applyDownwardStructureToSearch = (index: number) => {
        const subOrgsToSearch = this.subTentantToParentStructure.filter((_orgName, i) => i <= index);
        this.filterSubTenantName = subOrgsToSearch.join('  |  ');
    };

    public selectTenant = (tenant?: OrganizationData) => {
        this.filterSubTenantName = '';
        if (!tenant) {
            return;
        }
        if (tenant.id === this.selectedTenant.id) {
            this.selectedTenantId = this.selectedTenant.id;
            return;
        }
        this.selectedTenant = tenant;
        if (!this.selectedTenant.isPartnerOrg && this.selectedTenant.parent) {
            this.selectTenant(this.selectedTenant.parent as any);
            return;
        } else {
            this.selectedTenantId = this.selectedTenant.id;
        }
        this.buildSelectedSubtenantStructure(true);
    };

    public selectSubTenant = (subTenant: Organization) => {
        if (!subTenant) {
            this._matOrgMenuTrigger?.closeMenu();
            this.triggeredExternally.next();
            this.triggeredExternally.complete();
            return;
        } else if (this.selectedSubTenant && this.selectedSubTenant.id === subTenant.id) {
            if (!this.organizationsMenuTriggeredExternally) {
                this.navigateHome(subTenant);
            }
            this._matOrgMenuTrigger?.closeMenu();
            return;
        }
        this.organizationsMenuTriggeredExternally = false;
        this._matOrgMenuTrigger?.closeMenu();
        this.filterAppName = '';
        this.selectedAlpha = '';
        this.filterTenantName = '';
        this.filterSubTenantName = '';
        this.switchToOrganization(subTenant);
        const itemCollection = document.querySelector('#item-collection-1') as HTMLElement;
        const marqueeContent = document.querySelector('#marquee-content') as HTMLElement;
        if (itemCollection?.classList.contains('item-collection-1') || marqueeContent?.classList.contains('marquee-content')) {
            itemCollection.classList.remove('item-collection-1');
            marqueeContent.classList.remove('marquee-content');
            setTimeout(() => {
                itemCollection.classList.add('item-collection-1');
                marqueeContent.classList.add('marquee-content');
            });
        }
    };

    private switchToOrganization = (org: Organization) => {
        if (org?.id) {
            this._utils.navigateToOrganization(org?.id);
        }
    };

    public navigateHome = (subTenant?) => {
        this.filterAppName = '';
        this.selectedAlpha = '';
        this.filterTenantName = '';
        this.filterSubTenantName = '';
        let switchToEnReport = this.isInEnComply && environment.redirectOnLogin === '/enreport';
        this._utils.navigateToOrganization(subTenant?.id, switchToEnReport ? 'enreport' : undefined);
    };

    private getLocationithParents = (parents: string[], organization: Organization): string[] => {
        parents.unshift(organization.name);
        if (organization.parent) {
            this.getLocationithParents(parents, organization.parent);
        }
        return parents;
    };

    private getOrganizations = (): Promise<Organization[]> => {
        return new Promise((resolve) => {
            this._locations.getOrganizations({
                successCallback: (res) => {
                    resolve(res);
                },
                failureCallback: () => {},
            });
        });
    };

    private addLocationToList = (location: Organization) => {
        if (!location || Object.keys(location).length === 0) {
            return;
        }
        const parent = this.organizationsMap[location.parent.id];
        location.displayTenantName = this._utils.getTenantName(location);
        location.nextNodes = [];
        const organizationIndex = this.subTenants.findIndex((tenant) => tenant.id === parent.id);
        this.subTenants.splice(organizationIndex + 1, 0, location);
        this.organizationsMap[location.id] = location;
        parent.nextNodes.push(location.id);
    };

    private separateSubAppsLevelWise = () => {
        this.totalNodes = [];
        this.levelWiseNodeMap = {};
        this.childNodes = CommonUtilsService.cloneObject(this.allSubApps);
        if (this.childNodes && !this._utils.isEmpty(this.childNodes)) {
            for (const node in this.childNodes) {
                if (this.childNodes[node].componentType === 'ASSET') {
                    this.totalNodes.push(this.childNodes[node]);
                }
            }
        }
        if (this.totalNodes && this.totalNodes.length > 0) {
            this.totalNodes.filter((obj) => {
                if (this.levelWiseNodeMap[obj.level] === undefined) {
                    this.levelWiseNodeMap[obj.level] = [];
                }
                this.levelWiseNodeMap[obj.level].push(obj);
            });
        }
    };

    public setTenantAutoFocus = () => {
        const nodeList = document.querySelectorAll('.tenantsList .cdk-virtual-scroll-content-wrapper') as NodeList;
        nodeList?.forEach((a: HTMLElement) => (a.style.transform = 'translateY(0px)'));
        if (this.organizationsMenuOpened) {
            this._tenantToFocus.nativeElement.focus();
        } else {
            setTimeout(() => {
                this._tenantToFocus.nativeElement.focus();
            }, 100);
        }
        this.organizationsMenuOpened = this.organizationsMenuOpened || (this._matOrgMenuTrigger as any)?._menuOpen;
    };

    private getAllTimeZones = (cb?) => {
        if (this.allTimeZones && this.allTimeZones['Asia/Kolkata']) {
            cb && cb();
        } else {
            this.allTimeZones = this._commonUtils.getAllTimeZones();
            cb && cb();
        }
    };

    private setTimeZoneOffset = (response, callback?) => {
        this._broadcaster.broadcast('getFromCrossStorage', {
            property: 'serverTimeZoneOffset',
            callback: (serverOffsetValue) => {
                const data = {
                    tz: response.timeZone,
                    serverTimeZoneOffset: serverOffsetValue,
                };
                this._utils.setTimeZoneOffset(data, callback);
            },
        });
    };

    public clearSearchAppName = () => {
        this.filterAppName = '';
    };

    public clearSearchTenant = () => {
        this.filterTenantName = '';
    };

    public clearSearchSubTenant = () => {
        this.filterSubTenantName = '';
    };

    private getAlphabetsArray = () => {
        return [
            'A',
            'B',
            'c',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ];
    };

    public filterTenants = (alphabet) => {
        if (!alphabet) {
            return;
        }
        this.selectedAlpha = alphabet;
        this.tenantsToRender = this.organizationsTree.filter((tenant) => {
            return tenant.name.toUpperCase().startsWith(alphabet.toUpperCase());
        });
    };

    public clearSelectedAlpha = () => {
        this.selectedAlpha = '';
        this.tenantsToRender = CommonUtilsService.cloneObject(this.organizationsTree);
    };

    public selectApp = async ($event: Event, app?: AssetService, subApp?: BridgeNode) => {
        $event?.stopPropagation();
        await this._api.permissions.transformAppWithPermissions(app);
        if (subApp?.id) {
            this._commonUtils.setInStorage('categoryFilterKey', this.categoryFilter.key);
            this._commonUtils.setInStorage('categoryFilterValue', this.categoryFilter.value);
            this._commonUtils.setInStorage('allSubApps', this.allSubApps);
        }
        if (app?.id === this.currentApp?.id) {
            if (app?.assetType === 'RECON') {
                this._commonUtils.setInStorage('categoryFilterKey', this.categoryFilter.key);
                this._commonUtils.setInStorage('categoryFilterValue', this.categoryFilter.value);
            }
            this._matAppsMenuTrigger && this._matAppsMenuTrigger.closeMenu();
            return;
        }
        if (app?.assetType === 'BRIDGE_ASSET') {
            this.currentApp = app;
            this.transformSubApps();
        } else if (app?.restApiName) {
            this._commonUtils.setInStorage('categoryFilterKey', this.categoryFilter.key);
            this._commonUtils.setInStorage('categoryFilterValue', this.categoryFilter.value);
            this._matAppsMenuTrigger && this._matAppsMenuTrigger.closeMenu();
        }
        localStorage.removeItem('filtercolumnlog');
    };

    public clearAppSelection = () => {
        this.currentApp = undefined;
        this.allSubApps = {};
        this.levelWiseNodeMap = [];
    };

    public navigateWorkQ = () => {
        this.filterAppName = '';
        this.selectedAlpha = '';
        this.filterTenantName = '';
        this.filterSubTenantName = '';
        this.confirmNavigation(() => {
            this._utils.navigateToWorkQPage();
        });
    };

    private confirmNavigation = (callback: () => void) => {
        const isInNewPage = this._utils.isInNewProcessPage();
        const isInProcessPage = this._utils.isInProcessPage();
        if (isInNewPage) {
            this.openConfirmationToNavigate('Switch Application', 'Do you wish to navigate away from new process page?', callback);
        } else if (isInProcessPage) {
            this.openConfirmationToNavigate('Switch Application', 'Do you wish to navigate away from current process page?', callback);
        } else {
            callback();
        }
    };

    private openConfirmationToNavigate = (title: string, message: string, callback: () => void) => {
        this._confirmation.confirm(title, message, 'Switch', 'Cancel').subscribe((action) => {
            if (action) {
                callback();
            }
        });
    };

    private transformSubApps = async () => {
        this.currentApp.assetType === 'BRIDGE_ASSET' && this.store$.dispatch(GetAppBridgeNodes({ serviceId: this.currentApp.serviceId }));
        this.allSubApps = await this._api.assets.getAndTransformBridgeNodes(this.currentApp);
        if (this.currentApp?.assetType === 'BRIDGE_ASSET') {
            this.separateSubAppsLevelWise();
            if (this._router.url.indexOf('/apps/') > -1) {
                this.selectAppsOnChange();
            }
        }
    };

    private buildSelectedAppName = () => {
        const app = this.selectedApp.value;
        const subApp = this.selectedSubApp.value;
        const report = this.selectedReport.value;
        this.selectedAppName = undefined;
        if (this.currentApp?.name || this.currentApp?.displayName) {
            this.selectedAppName = app?.displayName || app?.name || '';
        }
        if (subApp?.displayName || subApp?.name) {
            this.selectedAppName += ' / ' + (subApp?.displayName || subApp?.name);
        }
        if (report?.name || report?.displayName) {
            this.selectedAppName += ' / ' + (report?.displayName || report?.name);
        }
    };

    private getRoutePaths = () => {
        return location.href.split('/');
    };

    private analyzeOrganizationRouteContext = () => {
        const orgId = this.getOrganizationIdFromURL();
        const organization = this.organizationsMap[orgId];
        const rootOrgId = this._commonUtils.getFromStorage('rootOrganizationId');
        const rootOrg = this.organizationsMap[rootOrgId];
        const currentOrganizationId = this._commonUtils.getFromStorage('currentOrganizationId');
        if (!organization && currentOrganizationId?.length > 0) {
            this.currentOrganizationId.next(currentOrganizationId);
        } else if (!organization && rootOrg?.id) {
            this.currentOrganizationId.next(rootOrgId);
        } else if (!this.currentOrganizationId.value) {
            this.currentOrganizationId.next(orgId);
        } else if (orgId && orgId !== this.currentOrganizationId.value) {
            this._storeService.privateScope.clearValue('reconciliationServices');
            this._storeService.privateScope.clearValue('services');
            this.currentOrganizationId.next(orgId);
        }
    };

    private getOrganizationIdFromURL = () => {
        const routes = this.getRoutePaths();
        const orgIndex = routes.indexOf('organizations');
        return orgIndex > -1 && routes[orgIndex + 1];
    };

    private analyzeAppRouteContext = () => {
        const routes = this.getRoutePaths();
        const packageIndex = routes.findIndex((item) => item === 'packages');
        const appIndex = routes.findIndex((item) => item === 'apps');
        const reportIndex = routes.findIndex((item) => item === 'reports');
        let bridgeAPI: string;
        let appAPI: string;
        let reportName: string;
        if (packageIndex > -1) {
            bridgeAPI = routes[packageIndex + 1];
        }
        if (appIndex > -1) {
            appAPI = routes[appIndex + 1];
        }
        if (reportIndex > -1) {
            reportName = decodeURIComponent(decodeURIComponent(routes[reportIndex + 1]));
        }
        this.selectedAppId = bridgeAPI ? bridgeAPI : appAPI;
        this.selectedSubAppId = bridgeAPI ? appAPI : undefined;
        this.selectedReportId = reportName;
        this.selectAppsOnChange();
        this.analyzeSwitchProcess();
        this.clearSearchAppName();
    };

    private analyzeSwitchProcess = () => {
        this.uiList
            .filter((ui) => ui.value !== 'enrecon' && !this.isInEnRecon)
            .forEach(async (list) => {
                list.img = 'file.png';
            });
        this.uiList
            .filter((ui) => ui.value === 'enrecon' && this.isInEnRecon)
            .forEach(async (list) => {
                list.img = 'file.png';
            });
    };

    private analyzeUIContext = async () => {
        const list: {
            value: string;
            displayName: string;
            addClass?: string;
            isSwitchToProcess?: boolean;
            isSwitchToProcesses?: boolean;
            isSwitchToAppHome?: boolean;
            img?: string;
            hasDashborard: boolean;
        }[] = [];
        const restrictedProcessingUIs = environment.restrictedProcessingUIs || [];
        this.currentUIStream.next(
            this.isInEnReport
                ? 'enReport'
                : this.isInEnInvoice
                ? 'enInvoice'
                : this.isInEnCollab
                ? 'enCollaborate'
                : this.isInEnRecon
                ? 'enReconcile'
                : 'enComply'
        );
        list.push(
            {
                displayName: 'enComply',
                value: 'encomply',
                addClass: this.isInEnComply ? 'activeUi' : undefined,
                isSwitchToProcess: false,
                isSwitchToProcesses: false,
                isSwitchToAppHome: false,
                img: 'file.png',
                hasDashborard: this.isDashboardNeeded('encomply'),
            },
            {
                displayName: 'enReport',
                value: 'enreport',
                addClass: this.isInEnReport ? 'activeUi' : undefined,
                isSwitchToProcess: false,
                isSwitchToProcesses: false,
                isSwitchToAppHome: false,
                img: 'file.png',
                hasDashborard: this.isDashboardNeeded('enreport'),
            },
            {
                displayName: 'enInvoice',
                value: 'eninvoice',
                addClass: this.isInEnInvoice ? 'activeUi' : undefined,
                isSwitchToProcess: false,
                isSwitchToProcesses: false,
                isSwitchToAppHome: false,
                img: 'file.png',
                hasDashborard: this.isDashboardNeeded('eninvoice'),
            },
            {
                displayName: 'enCollaborate',
                value: 'encollab',
                addClass: this.isInEnCollab ? 'activeUi' : undefined,
                isSwitchToProcess: false,
                isSwitchToProcesses: false,
                isSwitchToAppHome: false,
                img: 'file.png',
                hasDashborard: false,
            },
            {
                displayName: 'enReconcile',
                value: 'enreconcile',
                addClass: this.isInEnRecon ? 'activeUi' : undefined,
                isSwitchToProcess: false,
                isSwitchToProcesses: false,
                isSwitchToAppHome: false,
                img: 'file.png',
                hasDashborard: false,
            }
        );
        if (JSON.stringify(list) !== JSON.stringify(this.uiList)) {
            this.uiList.splice(0);
            list.filter((list) => list.value !== 'encomply' || (list.value === 'encomply' && !restrictedProcessingUIs.includes('enreport')))
                .filter((list) => restrictedProcessingUIs.indexOf(list.value) === -1)
                .forEach((item) => this.uiList.push(item));
        }
    };

    private analyzeRouteContext = () => {
        const href = location.href;
        this.isInEnReport = href.indexOf(environment['enreport-ui']) > -1;
        this.isInEnInvoice = href.indexOf(environment['eninvoice-ui']) > -1;
        this.isInEnCollab = href.indexOf(environment['encollab-ui']) > -1;
        this.isInEnRecon = href.indexOf(environment['enreconcile-ui']) > -1;
        this.isInEnComply = !this.isInEnReport && !this.isInEnInvoice && !this.isInEnCollab && !this.isInEnRecon;
        if (this.isInEnComply) {
            this.setImagesource('encomply');
        } else if (this.isInEnReport) {
            this.setImagesource('enreport');
        } else if (this.isInEnInvoice) {
            this.setImagesource('eninvoice');
        } else if (this.isInEnCollab) {
            this.setImagesource('encollab');
        } else if (this.isInEnRecon) {
            this.setImagesource('enreconcile');
        }
        this.analyzeUIContext();
        this.analyzeOrganizationRouteContext();
        this.analyzeAppRouteContext();
        this.workqSelected = href.indexOf(this._utils.generateBaseRoute({}).join('/') + '/workq') > -1;
        this.isSettingsSelected = href.indexOf(this._utils.generateBaseRoute({}).join('/') + '/settings') > -1;
        this.markSelectedSetting(this.settings.children);
    };

    private isDashboardNeeded = (ui: 'encomply' | 'enreport' | 'eninvoice'): boolean => {
        let dashboardNeeded = false;
        const session = this._commonUtils.getFromStorage('session');
        const restrictedFeatures = environment.restrictedFeatures;
        let hideDashboard = false;
        switch (ui) {
            case 'encomply':
            case 'enreport':
                hideDashboard = restrictedFeatures?.dashboardOnEnComply;
                dashboardNeeded = session && !hideDashboard && (dashboardNeeded = true);
                break;
            case 'eninvoice':
                hideDashboard = restrictedFeatures?.dashboardOnEnInvoice;
                session && !hideDashboard && (dashboardNeeded = true);
                break;
        }
        return dashboardNeeded;
    };

    private markSelectedSetting = (list: SettingsMenuInterface[]) => {
        const href = location.href;
        list.forEach((item) => {
            item.selected = href.indexOf(item.route) > -1;
            item.children?.length > 0 && this.markSelectedSetting(item.children);
        });
    };

    private setImagesource = (ui) => {
        this.logoHref = environment.logoHref;
        if (ui === 'encomply') {
            this.logoHref = environment.logoHref;
        } else if (ui === 'enreport') {
        } else if (ui === 'eninvoice') {
        } else if (ui === 'encollab') {
        } else if (ui === 'enreconcile') {
        }
    };

    public tenantTrackBy = (_index: number, item: any) => {
        return item.id;
    };

    public subTenantTrackBy = (_index: number, item: any) => {
        return item.id;
    };

    public allAppsTrackBy = (_index: number, item: any) => {
        return item.id;
    };

    private getSubscribedAppsForCurrentLocation = (): Promise<AssetService[]> => {
        return new Promise((resolve, reject) => {
            this._api.subscriptions.getSubscribedServices(
                {
                    noAlerts: true,
                },
                {
                    successCallback: (response) => {
                        resolve(response as any);
                    },
                    failureCallback: (response) => {
                        this._utils.alertError((response && response.msg) || translate('Failed to get subscribed apps'));
                        reject();
                    },
                }
            );
        });
    };

    private fetchSubscribedApps = async (uiType: string) => {
        switch (uiType) {
            case 'eninvoice':
                this.allApps = [];
                this.allSubscribedApps
                    .filter(
                        (app) =>
                            app.tags.find(
                                (appTag) =>
                                    (appTag.tagKey === 'package' && environment.eninvoiceAutoSubscribeTags.includes(appTag.tagValue)) ||
                                    (appTag.tagKey === 'uitype' && environment.eninvoiceTags.includes(appTag.tagValue))
                            ) !== undefined
                    )
                    .forEach((app) => this.allApps.push(app));
                break;
            case 'encollab':
                this.allApps = await this._api.tags.fetchTaggedSubscriptions(
                    environment.encollabAutoSubscribeTags,
                    environment.encollabTags,
                    true,
                    true
                );
                break;
            case 'enrecon':
            case 'enreconcile':
                this.allApps = await this._api.subscriptions.getReconciliationServices(false, true);
                break;
            default:
                this.allApps = await this.getSubscribedAppsForCurrentLocation();
                break;
        }
    };

    private triggerOrgChangeExternally = (callback: () => void) => {
        this._matOrgMenuTrigger.openMenu();
        this.organizationsMenuOpened = true;
        this.organizationsMenuTriggeredExternally = true;
        this._matOrgMenuTrigger.menuClosed
            .pipe(takeUntil(this.unSubscribe), takeUntil(this.closedOrgMenuWithoutSelection))
            .subscribe(() => {
                this.closedOrgMenuWithoutSelection.next();
                this.closedOrgMenuWithoutSelection.complete();
                if (this.organizationsMenuTriggeredExternally) {
                    this.organizationsMenuTriggeredExternally = false;
                    this.triggeredExternally.next();
                    this.triggeredExternally.complete();
                }
            });
        this.currentOrganizationId.pipe(takeUntil(this.unSubscribe), takeUntil(this.triggeredExternally)).subscribe(() => {
            if (!this.organizationsMenuTriggeredExternally) {
                this.triggeredExternally.next();
                this.triggeredExternally.complete();
                callback?.();
            }
        });
    };

    private triggerAppsMenuExternally = (callback: () => void) => {
        if (this.allApps?.length > 0) {
            this._matAppsMenuTrigger.openMenu();
        } else {
            setTimeout(() => {
                this.triggerAppsMenuExternally(callback);
            }, 100);
        }
    };

    private generateSettingsMenu = async () => {
        const userPermissions: any = await this._api.permissions.getOrganizationRelatedPermissions();
        const permissions = userPermissions?.CUSTOMER?.CUSTOMER?.permissions;
        const subscriptionPermissions = userPermissions?.PROVIDER?.PROVIDER?.permissions?.SUBSCRIPTION_APPROVAL;
        const isAdmin = this._commonUtils.getFromStorage('isAdmin');
        const baseRoute = `organizations/${this.currentOrganizationId.value}`;
        const settingsBaseRoute = `${baseRoute}/settings`;
        this.settings.children = [];
        const integrationRestriction = environment?.restrictedFeatures?.integrations;
        if (userPermissions && permissions) {
            if (permissions.ORG_DETAILS) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Organization Management',
                    icon: 'account_balance',
                    route: `${settingsBaseRoute}/manageOrganization`,
                    hasChildren: false,
                });
            }
            if (permissions.CREATE_USER) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'User Management',
                    icon: 'person',
                    route: `${settingsBaseRoute}/users`,
                    hasChildren: false,
                });
            }
            if (permissions.CREATE_ROLE) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Roles & Permissions',
                    icon: 'assignment',
                    route: `${settingsBaseRoute}/roles`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_LOCATION) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Locations',
                    icon: 'location_on',
                    route: `${settingsBaseRoute}/locations`,
                    hasChildren: false,
                });
            }
            if (permissions.PROCESS_MASTERS) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Master Data Management',
                    icon: 'blur_linear',
                    route: `${settingsBaseRoute}/masterManagement`,
                    hasChildren: true,
                    loadChildren: this.loadMasters,
                });
            }
            if (this.isProviderAdminAndOrganization) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Authorization Systems',
                    icon: 'security_key',
                    route: `${settingsBaseRoute}/authorization-systems`,
                    hasChildren: false,
                });
            }
            if (permissions.INTEGRATION_SETTINGS && (!integrationRestriction || integrationRestriction.length > 0)) {
                const parent = {
                    id: this.settings.children.length,
                    name: 'Integrations',
                    icon: 'settings_input_antenna',
                    route: `${settingsBaseRoute}/integrations`,
                    hasChildren: true,
                    children: [],
                };
                ['FTP', 'S3', 'RESTAPI', 'EMAIL']
                    .filter((item) => !integrationRestriction || integrationRestriction.indexOf(item.toLowerCase()) === -1)
                    .forEach((child) => {
                        parent.children.push({
                            id: parent.children.length,
                            name: child,
                            route: `${parent.route}/${child.toLowerCase()}`,
                            hasChildren: false,
                            children: [],
                        });
                    });
                this.settings.children.push(parent);
            }
            if (permissions.MANAGE_CLIENT) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Access API',
                    icon: 'language',
                    route: `${settingsBaseRoute}/apiaccess`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_CLIENT) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Active Tokens',
                    icon: 'vpn_key',
                    route: `${settingsBaseRoute}/active-tokens`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_PARTNERS && this.selectedSubTenant?.organizationTypes !== 'PROVIDER') {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Partner Management',
                    icon: 'thumb_up',
                    route: `${settingsBaseRoute}/partners`,
                    hasChildren: false,
                });
            }
            if (permissions.RESET_PASS_APPROVAL) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Reset User Password',
                    icon: 'lock_open',
                    route: `${settingsBaseRoute}/resetpasswordapproval`,
                    hasChildren: false,
                });
            }
            if (subscriptionPermissions) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Subscription Approval',
                    icon: 'assignment_turned_in',
                    route: `${settingsBaseRoute}/subscriptionapproval`,
                    hasChildren: false,
                });
            }
            this.settings.children.push({
                id: this.settings.children.length,
                name: 'Notifications',
                icon: 'notifications',
                route: `${settingsBaseRoute}/notification`,
                hasChildren: false,
            });
            if (isAdmin) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Index Data',
                    icon: 'view_list',
                    route: `${settingsBaseRoute}/indexdata`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_SUBSCRIPTION) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Manage Apps',
                    icon: 'apps',
                    route: `${baseRoute}/manageapps`,
                    hasChildren: true,
                    children: [
                        {
                            id: 0,
                            icon: 'widgets',
                            name: 'My Apps',
                            route: `${baseRoute}/manageapps/approved`,
                            hasChildren: false,
                        },
                        {
                            id: 1,
                            icon: 'timer',
                            name: 'Apps Pending Approval',
                            route: `${baseRoute}/manageapps/pending`,
                            hasChildren: false,
                        },
                        {
                            id: 2,
                            icon: 'block',
                            name: 'Deactivated Apps',
                            route: `${baseRoute}/manageapps/deactivated`,
                            hasChildren: false,
                        },
                        {
                            id: 3,
                            icon: 'apps',
                            name: 'All Apps',
                            route: `${baseRoute}/manageapps/all`,
                            hasChildren: false,
                        },
                        {
                            id: 4,
                            icon: 'layers',
                            name: 'Bundles',
                            route: `${baseRoute}/manageapps/bundles`,
                            hasChildren: false,
                        },
                    ],
                });
            }
            if (isAdmin) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Event Subscriptions',
                    icon: 'language',
                    route: `${settingsBaseRoute}/eventsubscription`,
                    hasChildren: false,
                });
            }
            if (permissions.MANAGE_IDENTITY_PROVIDER) {
                this.settings.children.push({
                    id: this.settings.children.length,
                    name: 'Identity Providers',
                    icon: 'assignment_ind',
                    route: `${settingsBaseRoute}/identityproviders`,
                    hasChildren: false,
                });
            }
            this.initiate4EC();
            this.markSelectedSetting(this.settings.children);
        }
    };

    /**
     * Method to get Application properties
     */
    private getApplicationProperties = () => {
        this.store$.dispatch(GetApplicationProperties());
    };

    private loadMasters = async (menu: SettingsMenuInterface) => {
        menu.children = [];
        return new Promise<void>((resolve) => {
            this._api.masters.getAllMasters(this.currentOrganizationId.value).subscribe({
                next: (response) => {
                    Object.keys(response).forEach((key) => {
                        menu.children.push({
                            children: [],
                            hasChildren: false,
                            icon: undefined,
                            id: menu.children.length,
                            loadChildren: undefined,
                            name: response[key].displayName || response[key].itemName,
                            route: `${menu.route}/${key}/${response[key].restApiServiceName}`,
                            selected: false,
                            noTranslation: true,
                        });
                    });
                    this.markSelectedSetting(menu.children);
                    resolve();
                },
            });
        });
    };

    private initializeComponent = () => {
        this.getApplicationProperties();
        this.startComponent();
        this.currentOrganizationId
            .pipe(takeUntil(this.unSubscribe))
            .pipe(filter(() => this.isOrganizationsStructureComplete))
            .subscribe((orgId) => {
                const organization = this.organizationsMap[orgId];
                const parentOrg = this.getParentOrganization(organization);
                const previousParent = this.getParentOrganization(this.selectedSubTenant);
                if (parentOrg?.id === previousParent?.id) {
                    this.selectedSubTenant = organization;
                    this.buildParentsStructure();
                    this.buildTenantName();
                    this.checkIfParentExists();
                } else {
                    this.buildTenantsStructure(orgId);
                }
                this.getAllTimeZones(() => {
                    this.setTimeZoneOffset(organization);
                });
            });
        this.store$
            .select(getCurrentOrganization$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((organization) => {
                this.showWorkQ = organization?.workQueueEnabled && this.canShowWorkQ;
                this.showWorkQ && this.getCountOfActiveMessages();
            });
        this.selectedApp.pipe(takeUntil(this.unSubscribe)).subscribe(async (app) => {
            this.currentApp = app;
            this._commonUtils.setInStorage('selectedapp', this.currentApp);
            if (app) {
                if (app.assetType === 'BRIDGE_ASSET') {
                    if (Object.keys(this.allSubApps || {}).length > 0) {
                        return;
                    }
                    await this.transformSubApps();
                    this._commonUtils.setInStorage('allSubApps', this.allSubApps);
                    if (this.selectedSubAppId && this.selectedSubAppId.length > 0 && !this.currentSubApp) {
                        this.selectedSubApp.next(this.allSubApps[this.selectedSubAppId]);
                    } else if (!this.selectedSubAppId) {
                    }
                    if (this.selectedReportId?.length > 0 && !this.selectedReport) {
                        this.selectedReport.next(this.allSubApps[this.selectedReportId]);
                    }
                } else {
                    this._commonUtils.setInStorage('allSubApps', undefined);
                    this.allSubApps = {};
                }
            } else {
                this.currentReport = undefined;
                this.currentSubApp = undefined;
                this.allSubApps = {};
            }
            this.buildSelectedAppName();
        });
        this.selectedSubApp.pipe(takeUntil(this.unSubscribe)).subscribe((subApp) => {
            this.currentSubApp = subApp;
            this.buildSelectedAppName();
        });
        this.selectedReport.pipe(takeUntil(this.unSubscribe)).subscribe((report) => {
            this.currentReport = report;
            this.buildSelectedAppName();
        });
        this.currentUIStream.pipe(takeUntil(this.unSubscribe)).subscribe((newUI) => {
            if (newUI !== this.currentUI) {
                this.currentUI = newUI as any;
                if (this.currentUI === 'enInvoice') {
                    this.analyzeAppRouteContext();
                }
            }
        });
        this.store$
            .select(getLogo$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data) => {
                this.imgSrc = data;
            });
        this._router.events
            .pipe(
                takeUntil(this.unSubscribe),
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                const origin = window.location.origin;
                this.isSettingsSelected = window.location.href.indexOf(origin + '/settings') > -1;
                this.workqSelected = window.location.href.indexOf(origin + '/workq') > -1;
            });
        this._broadcaster
            .on('switchedOrganization')
            .pipe(takeUntil(this.unSubscribe), delay(10))
            .pipe(filter((event: Organization) => this.currentOrganizationId.value === event.id))
            .subscribe(() => {
                this.analyzeRouteContext();
                this.analyzeAppRouteContext();
                this.generateSettingsMenu();
                this.startComponent();
                this.store$.dispatch(Get4ECPermission());
            });
        this._broadcaster
            .on('addedLocation')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((location: any) => {
                this.addLocationToList(location);
            });
        this.actions$
            .pipe(ofType(SetLocation))
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data.organization !== undefined)
            )
            .subscribe((event) => {
                this.addLocationToList(CommonUtilsService.cloneObject(event.organization));
            });
        this._broadcaster
            .on('pushTenants')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(() => this._broadcaster.broadcast('subTenants', this.subTenants));
        this._broadcaster
            .on('organizationUpdated')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((organization) => {
                const currentOrgId = this._commonUtils.getFromStorage('currentOrganizationId');
                if (currentOrgId === organization['id']) {
                    this.showWorkQ = organization?.['workQueueEnabled'] && this.canShowWorkQ;
                }
            });
        this.store$
            .select(getWorkQCount$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((count) => {
                this.totalWorkqCount = count;
            });
        this._router.events.pipe(takeUntil(this.unSubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.analyzeRouteContext();
            }
        });
        this.store$
            .select(getApplicationProperties$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((res) => res !== undefined)
            )
            .subscribe((data) => {
                if (data?.['analytics.service.types']?.length > 0) {
                    this._commonUtils.analyticsServiceTypes = data['analytics.service.types'];
                }
            });
        this.store$
            .select(has4ECAccess$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((res) => res !== undefined)
            )
            .subscribe((data) => {
                this.has4ECAccess = data;
                this.initiate4EC();
            });
        this.store$
            .select(getAnalyticsDashboards$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data) => {
                this.insights = CommonUtilsService.cloneObject(data) || [];
            });
        this.actions$
            .pipe(
                ofType(SetDashboards),
                filter((data) => data !== undefined),
                takeUntil(this.unSubscribe)
            )
            .subscribe((res) => {
                const insights = res.analyticDashboards;
                if (insights.length === 0 && this._commonUtils.analyticsServiceTypes?.split(',').includes('WIDGET')) {
                    this.matChartsMenuTrigger?.closeMenu();
                    this._router.navigate(['organizations', this.currentOrganizationId.value, 'dashboard']);
                }
            });
        this.store$
            .select(getDashboardLoader$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((res) => res !== undefined)
            )
            .subscribe((data) => {
                this.isDashboardsLoading = data;
            });
        this.store$
            .select(isProviderOrganizationAndAdmin$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                this.isProviderAdminAndOrganization = event;
            });
    };

    public startAppFilter = async (key: string, value: string, displayName: string, initialLoad?: boolean) => {
        this.loadingApps = true;
        !initialLoad && (this.currentApp = undefined);
        this.filterAppName = '';
        const ui = this.uiList.find((item) => item.displayName === this.currentUI);
        value = value || ((key || 'platform') === 'platform' ? (ui?.value === 'enreport' ? 'encomply' : ui?.value) : undefined);
        this.categoryFilter = {
            key: key || 'platform',
            value: value,
            displayName: displayName || ui?.displayName,
        };
        this.allApps = [];
        switch (key) {
            case 'tag':
                this.showDashboardIcon = false;
                await this.startAppFilterByTag(this.categoryFilter.value);
                break;
            default:
                this.showDashboardIcon = this.isDashboardNeeded(this.categoryFilter.value as any);
                await this.fetchSubscribedApps(this.categoryFilter.value);
                break;
        }
        this.loadingApps = false;
    };

    private startAppFilterByTag = async (value: string) => {
        let appFilters: string[];
        if (value) {
            appFilters = await this.getAppsByTag(value);
        } else {
            appFilters = await this.getOtherAppTags();
        }
        this.allApps = this.allSubscribedApps.filter((app) => appFilters.includes(app.restApiName));
    };

    private getOtherAppTags = () => {
        return new Promise<string[]>((resolve) => {
            const promises = [];
            this.packageTags.forEach((tag) => {
                tag.value && promises.push(this.getAppsByTag(tag.value));
            });
            Promise.all(promises).then((appRestApiNamesList: string[][]) => {
                let appRestApiNames = [];
                appRestApiNamesList.forEach((appNames) => {
                    appRestApiNames = [...appRestApiNames, ...appNames];
                });
                const appsList = this.allSubscribedApps
                    .filter((app) => !appRestApiNames.includes(app.restApiName))
                    .reduce((apps, app) => {
                        apps.push(app.restApiName);
                        return apps;
                    }, []);
                resolve(appsList);
            });
        });
    };

    /**
     * Method to get app tags
     * @param tag tag key
     * @returns Promise<tagValues>
     */
    private getAppsByTag = async (tag: string) => {
        const response = await this._api.assets.getServicesByTagValues({
            key: `package:${tag}`,
            serviceTypes: undefined,
            subscriptionStatus: 'APPROVED',
            size: 500,
            noAlerts: true,
        });
        const appNames = response?.reduce((restApiNames, taggedData) => {
            restApiNames.push(taggedData.restApiName);
            return restApiNames;
        }, []);
        return appNames;
    };

    public navigateToHome = () => {
        this._utils.navigateToOrganization(undefined, this.categoryFilter.value as any);
        this.setPostNavigationContext();
    };

    public navigateToWidgets = (): void => {
        this._utils.navigateToDashboardPage(this.categoryFilter.value as any);
        this.setPostNavigationContext();
    };

    private setPostNavigationContext = () => {
        this._commonUtils.setInStorage('categoryFilterKey', this.categoryFilter.key);
        this._commonUtils.setInStorage('categoryFilterValue', this.categoryFilter.value);
        this.categoryFilter.value !== 'enreconcile' && this._matAppsMenuTrigger.toggleMenu();
    };

    private initiate4EC = () => {
        if (!this.has4ECAccess) {
            return;
        }
        const baseRoute = `organizations/${this.currentOrganizationId.value}`;
        const settingsBaseRoute = `${baseRoute}/settings`;
        this.settings.children.push({
            id: this.settings.children.length,
            name: '4EC Approval',
            icon: 'view_list',
            route: `${settingsBaseRoute}/four-eye-check`,
            hasChildren: false,
        });
    };

    public toggleAppCategory = () => {
        this._schema.setSchemaProperty('header', 'hideAppCategory', !this._schema.schema.header.hideAppCategory);
    };

    public openInsights = () => {
        this._schema.setSchemaProperty('enComply', 'showAnalytics', true);
    };

    public getDashboardCharts = () => {
        if (this._commonUtils.analyticsServiceTypes?.split(',').includes('DASHBOARD')) {
            (!this.insights || this.insights.length === 0) && this.store$.dispatch(GetDashboards({ noAlerts: true }));
        } else if (this._commonUtils.analyticsServiceTypes?.split(',').includes('WIDGET')) {
            this.matChartsMenuTrigger?.closeMenu();
            this._router.navigate(['organizations', this.currentOrganizationId.value, 'dashboard']);
        }
    };

    public selectParentOrganization = () => {
        const organization = this.selectedSubTenant;
        let parentId = this.checkForAccess(organization?.parent);
        parentId?.length > 0 ? this.selectSubTenant(this.organizationsMap[parentId]) : (this.showSwitchToParent = false);
    };

    private checkForAccess = (parentOrg) => {
        if (parentOrg?.noAccess) {
            return this.checkForAccess(parentOrg.parent);
        } else {
            return parentOrg?.id;
        }
    };

    public switchToNewUI = () => {
        this._commonUtils.setCookie('new-ui', '' + !this.isNewUI);
        location.reload();
    };

    ngOnInit(): void {
        const guestUrl = environment['encomply-ui'] + '/collaboration';
        const currentUrl = window.location.href;
        this.isInGuestMode = currentUrl.indexOf(guestUrl) > -1;
        if (this.isInGuestMode) {
            return;
        }
        this.initializeComponent();
    }

    ngAfterViewInit(): void {
        this._broadcaster
            .on('openOrganizationChangeMenu')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event: { callback: () => void }) => {
                this.triggerOrgChangeExternally(event?.callback);
            });
        this._broadcaster
            .on('openAppsMenu')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event: { callback: () => void }) => {
                this.triggerAppsMenuExternally(event?.callback);
            });
        const targetElement = document.getElementById('maindiv');
        const sourceElement = document.getElementById('div1');
        if (targetElement && sourceElement) {
            targetElement.style.width = sourceElement.style.width;
        }
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.closedOrgMenuWithoutSelection.next();
        this.closedOrgMenuWithoutSelection.complete();
    }
}
