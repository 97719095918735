import { Injectable } from '@angular/core';
import { Utils } from '@encomply/core/services/utils/utils.service';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { getCurrentLocationIdsHierarchy$ } from 'taxilla-library';

import {
    IntegrationConfigField,
    IntegrationModal,
    IntegrationsSubSection,
    IntegrationTabStructure,
    IntergrationFieldType,
} from '../../interface/integrationmodal.interface';
import { Chain } from '../../models/chain.interface';
import { AppIntegrationRecord } from '../../models/integrations/appintegrationrecord.class';
import { AppNewIntegrationRecordPayload } from '../../models/integrations/appnewintegrationpayload.class';
import { IntegrationRecord } from '../../models/integrations/integrationrecord.class';
import { MasterField } from '../../models/masters/masterfield.class';
import { MastersMetaData } from '../../models/masters/mastersmetadata.class';
import { MasterTables } from '../../models/masters/mastertables.class';
import { ApiService } from '../api/api.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class IntegrationsUtilsService {
    masterTableMetaData: MastersMetaData;

    useMaster = false;
    isUseMaster = false;
    private orgIds: string[] = [];

    constructor(
        private _api: ApiService,
        private _commonUtils: CommonUtilsService,
        private _utils: Utils,
        private store$: Store,
        private _libUtils: UtilsService,
        private R: RootScopeService
    ) {
        this.store$.select(getCurrentLocationIdsHierarchy$).subscribe((ids) => {
            this.orgIds = ids;
        });
    }

    getFieldType = (
        field: IntegrationModal['fields'][0],
        record: IntegrationRecord,
        tabRecord?: IntegrationTabStructure['records'][0] | any
    ): IntegrationConfigField['type'] => {
        let fieldType: IntegrationConfigField['type'] = 'TEXT';
        if (field.inputTypeConditonal && Array.isArray(field.type)) {
            for (let i = 0; i < field.type.length; i++) {
                const fieldTypeObj = (field.type as IntergrationFieldType[])[i];
                let requiredField;
                if (tabRecord) {
                    requiredField = tabRecord.fields.find((recordField) => recordField.id === fieldTypeObj.fieldId);
                    if (!requiredField) {
                        requiredField = tabRecord.recordActions.find((recordAction) => recordAction.id === fieldTypeObj.fieldId);
                    }
                } else {
                    requiredField = record.getRecordField(fieldTypeObj.fieldId);
                }
                if (fieldTypeObj.requiredValue !== undefined) {
                    if (fieldTypeObj.requiredValue === requiredField.value) {
                        fieldType = fieldTypeObj.type;
                    }
                }
            }
        } else {
            fieldType = field.type;
        }
        if (field.array && (!field.values || field.values.length === 0)) {
            field.values = [{ value: '' }];
        }
        return fieldType;
    };

    // https://taxilla.myjetbrains.com/youtrack/issue/TAF-869
    // For the sake of above issue, introduced a new argument 'fieldRef'. This will check if field is available, then it will run a validation check only on that field.
    isFormValid = (
        record: IntegrationRecord,
        fieldRef?: IntegrationConfigField,
        subSectionRecord?: IntegrationsSubSection['records'][0]
    ) => {
        let noErrors = true;
        const isNewRecord = record.originalData?.createdDate === undefined;
        if (record?.recordData !== undefined) {
            if (fieldRef !== null && fieldRef !== undefined) {
                noErrors = this.validateField(isNewRecord, fieldRef, record, undefined, subSectionRecord) && noErrors;
            }
            if ((fieldRef && noErrors) || !fieldRef) {
                const allFields = record.recordData.fields;
                allFields.forEach((field) => {
                    noErrors = this.validateField(isNewRecord, field, record, undefined, subSectionRecord) && noErrors;
                });
                if (record.recordData.subSections?.length > 0) {
                    record.recordData.subSections.forEach((subSection) => {
                        noErrors = this.validateSubSection(isNewRecord, subSection, record, undefined) && noErrors;
                    });
                }
            }
            record.isValid = noErrors;
        }
        return noErrors;
    };

    validateSubSection = (
        isNewRecord: boolean,
        subSection: IntegrationsSubSection,
        record?: IntegrationRecord,
        subSectionRecord?: IntegrationsSubSection['records'][0],
        recordIndex?: number
    ): boolean => {
        let noErrors = true;
        if (!record.canHideSubSection(subSection, subSectionRecord)) {
            if (subSection.haveTabs) {
                subSection.tabs?.forEach((tab) => {
                    if (tab.array) {
                        tab.records.forEach((tabRecord) => {
                            tabRecord.fields.forEach((field) => {
                                noErrors = this.validateField(isNewRecord, field, record, tab, subSectionRecord) && noErrors;
                            });
                        });
                    } else {
                        tab.fields?.forEach((field) => {
                            noErrors = this.validateField(isNewRecord, field, record, tab, subSectionRecord) && noErrors;
                        });
                    }
                });
            } else if (subSection.array) {
                if (subSection.records.length > 0) {
                    subSection.records.forEach((subSectionRecordData, index) => {
                        if (recordIndex === undefined || recordIndex === index) {
                            subSectionRecordData.fields.forEach((field) => {
                                noErrors = this.validateField(isNewRecord, field, record, undefined, subSectionRecordData) && noErrors;
                            });
                            if (subSectionRecordData.childSubSections?.length > 0) {
                                subSectionRecordData.childSubSections.forEach((childSubSection) => {
                                    noErrors =
                                        this.validateSubSection(isNewRecord, childSubSection, record, subSectionRecordData) && noErrors;
                                });
                            }
                        }
                    });
                }
            } else {
                subSection.fields.forEach((field) => {
                    noErrors = this.validateField(isNewRecord, field, record, undefined, subSectionRecord) && noErrors;
                });
            }
        }
        return noErrors;
    };

    private validateField = (
        isNewRecord: boolean,
        field: IntegrationConfigField,
        record: IntegrationRecord,
        tab?: IntegrationTabStructure,
        subSectionRecord?: IntegrationsSubSection['records'][0]
    ): boolean => {
        if (field.id === 'useMaster' && field.value === true) {
            this.isUseMaster = true;
        } else if (field.id === 'useMaster' && field.value === false) {
            this.isUseMaster = false;
        }
        const errors = [];
        if (!this.hideField(field, record, tab, subSectionRecord)) {
            if (field.required) {
                if ((field.array || (field.conditionalArray && record.isConditionalArray(field))) && !this.useMaster) {
                    if ((!field.values || field.values.length === 0) && field.type !== 'ORGANIZATIONS_CHECKBOX') {
                        errors.push(this.translateMsg('Enter a valid value'));
                    } else {
                        if (field.type === 'ORGANIZATIONS_CHECKBOX') {
                            let errorsFound = true;
                            if (field.value) {
                                errorsFound = false;
                                field.value?.forEach((value) => {
                                    if (!this._libUtils.checkValueExists(value)) {
                                        errorsFound = true;
                                    }
                                });
                            }
                            if (errorsFound) {
                                (errors as string[]).push(this.translateMsg('Select a valid value'));
                            }
                        } else {
                            field.values.forEach((value, index) => {
                                errors[index] = [];
                                if (!this._libUtils.checkValueExists(value.value)) {
                                    (errors[index] as string[]).push(this.translateMsg('Enter a valid value'));
                                }
                            });
                        }
                    }
                } else if (field.valueAsArrayInPayload && this.useMaster) {
                    if (!field.values || field.values.length === 0) {
                        errors.push(this.translateMsg('Enter a valid value'));
                    } else {
                        field.values?.forEach((valueObject) => {
                            if (!this._libUtils.checkValueExists(valueObject.value)) {
                                errors.push(this.translateMsg('Enter a valid value'));
                            }
                        });
                    }
                } else if (
                    !['boolean', 'object', 'number'].includes(typeof field.value) &&
                    !this._libUtils.checkMinLength(field.value, 1)
                ) {
                    errors.push(this.translateMsg('Enter a valid value'));
                }
            }
            if (field.id === 'configName') {
                let integrations;
                if (record.recordData.api === 'outbound') {
                    integrations = this._commonUtils.getFromStorage('outboundintegrations');
                } else {
                    integrations = this._commonUtils.getFromStorage('inboundintegrations');
                }
                if (integrations?.length > 0 && Object.keys(record.originalData).length === 0) {
                    for (let i = 0; i < integrations.length; i++) {
                        if (integrations[i].configName?.trim() === field.value?.trim()) {
                            errors.push(this.translateMsg('Configuration Name already exists'));
                        }
                    }
                }
                if (isNewRecord && !this._utils.checkSpecialChrsWithHyphen(field.value)) {
                    errors.push(this.translateMsg('Only alphabets, numbers and hyphen are allowed'));
                }
            }
            if (field.validations && !this.isUseMaster) {
                field.validations.forEach((validation) => {
                    if ((field.array || (field.conditionalArray && record.isConditionalArray(field))) && !this.useMaster) {
                        if (!field.values) {
                            field.values = [
                                {
                                    value: '',
                                },
                            ];
                        }
                        field.values.forEach((value, index) => {
                            errors[index] = errors[index] || [];
                            if (value.value && (value.value as string).length > 0) {
                                const isDisabledField = record.canDisableField(field);
                                if (!isDisabledField) {
                                    this.checkValidation(validation, value.value, errors[index]);
                                }
                            }
                        });
                    } else if (field.value !== undefined && typeof field.value === 'string' && field.value.length > 0) {
                        const isDisabledField = record.canDisableField(field);
                        if (!isDisabledField) {
                            this.checkValidation(validation, field.value, errors);
                        }
                    }
                });
            }
            field.errors = errors;
            if (field.array || (field.conditionalArray && record.isConditionalArray(field))) {
                return errors.findIndex((error) => error.length > 0) === -1;
            } else {
                return errors.length === 0;
            }
        }
        return true;
    };

    checkValidation = (validation: string, value: any, errors: any[]) => {
        switch (validation) {
            case 'checkEmail':
                if (!this._libUtils.acceptEmail(value)) {
                    errors.push(this.translateMsg('Enter a valid email id'));
                }
                break;
            case 'checkNumberOnly':
                if (!this._libUtils.checkDouble(value)) {
                    errors.push(this.translateMsg('Enter a valid value'));
                }
                break;
            case 'checkUrl':
                if (!this._libUtils.checkWebUrl(value)) {
                    errors.push(this.translateMsg('Enter a valid value'));
                }
                break;
            case 'checkInteger':
                if (!this._libUtils.checkInteger(value)) {
                    errors.push(this.translateMsg('Enter a valid value'));
                }
                break;
            case 'checkMaxLength':
                if (!this._libUtils.checkMaxLength(value, 255)) {
                    errors.push(this.translateMsg('Enter 255 characters only'));
                }
                break;
            case 'maxLength250':
                if (!this._libUtils.checkMaxLength(value, 250)) {
                    errors.push(this.translateMsg('Enter 250 characters only'));
                }
                break;
            case 'minLength3':
                if (!this._libUtils.checkMinLength(value, 3)) {
                    errors.push(this.translateMsg('Enter a minimum of 3 characters'));
                }
                break;
            case 'checkMinimumNumberValue':
                if (!this._libUtils.checkMinValue(value, 0)) {
                    errors.push(this.translateMsg('Minimum value must be greater than zero'));
                }
                break;
            case 'mustContainAlphabets':
                if (!this._libUtils.mustContainAlphabets(value)) {
                    errors.push(this.translateMsg('Must contain alphabets'));
                }
                break;
            case 'mustContainAlphaAsFirstLetter':
                if (!this._libUtils.mustContainAlphabets(value?.[0])) {
                    errors.push(this.translateMsg('Must contain alphabet as first letter'));
                }
                break;
            case 'mustNotContainSpaces':
                if (this._libUtils.hasBlankSpaces(value)) {
                    errors.push(this.translateMsg('Must not contain any spaces'));
                }
                break;
        }
    };

    toggleFieldValues = (masterField: IntegrationModal['fields'][0], record: IntegrationRecord, newValue: boolean) => {
        this.useMaster = newValue;
        if (!masterField.isMasterField) {
            return;
        }
        record.recordData.fields.forEach((field) => {
            if (field.conditionalDisplay) {
                field.value = newValue === true ? undefined : field.defaultValue;
            }
        });

        record.recordData.subSections?.forEach((subFieldObject) => {
            subFieldObject.tabs?.forEach((tab) => {
                tab.fields.forEach((field) => {
                    if (field.conditionalDisplay) {
                        field.value = newValue === true ? undefined : field.defaultValue;
                    }
                });
            });
        });
    };

    getOptions = (
        field: IntegrationModal['fields'][0],
        record: IntegrationRecord
    ): {
        id: string;
        displayName: string;
        value?: string | boolean;
        pushValue?: string;
    }[] => {
        if (field.inputTypeConditonal && field.type instanceof Array) {
            for (let i = 0; i < field.type.length; i++) {
                const fieldTypeObj = (field.type as IntergrationFieldType[])[i];
                const requiredField = record.getRecordField(fieldTypeObj.fieldId);
                if (fieldTypeObj.requiredValue !== undefined) {
                    if (fieldTypeObj.requiredValue === requiredField.value) {
                        return fieldTypeObj.options;
                    }
                }
            }
            return [];
        } else {
            return field.options;
        }
    };

    getMasterColumns = (masterId, masterTableColumns: MasterField[]) => {
        const currentOrgId = this._commonUtils.getFromStorage('currentorganizationid');
        const currentMasterTables = this.getMasterTables();
        const masterTable = currentMasterTables.find((master) => master.itemId === masterId);
        this.masterTableMetaData = masterTable && this.R.masters?.getTable(masterTable.id);
        masterTableColumns.splice(0);
        if (!masterTable) {
            setTimeout(() => {
                this.getMasterColumns(masterId, masterTableColumns);
            }, 1000);
        } else if (!this.masterTableMetaData) {
            this._api.masters.getMetaData(
                {
                    organizationId: currentOrgId,
                    tableId: masterTable.itemId,
                },
                {
                    successCallback: (response) => {
                        this.R.masters.setMasterTable(masterId, response);
                        this.masterTableMetaData = this.R.masters.getTable(masterId);
                        const columns = this.masterTableMetaData.getAllColumns();
                        columns?.forEach((column) => {
                            if (!this.checkColumnIndex(masterTableColumns, column)) {
                                masterTableColumns.push(column);
                            }
                        });
                    },
                }
            );
        } else {
            masterTableColumns = this.masterTableMetaData.getAllColumns();
            const columns = this.masterTableMetaData.getAllColumns();
            columns?.forEach((column) => {
                if (!this.checkColumnIndex(masterTableColumns, column)) {
                    masterTableColumns.push(column);
                }
            });
        }
    };

    checkColumnIndex = (masterTableColumns, column) => {
        let index = false;
        if (masterTableColumns.length !== 0) {
            for (let i = 0; i < masterTableColumns.length; i++) {
                if (masterTableColumns[i].id === column.id) {
                    index = true;
                    break;
                }
            }
        }
        return index;
    };

    deleteFieldValue = (field: IntegrationConfigField, index: number, record: IntegrationRecord) => {
        if ((field.array || (field.conditionalArray && record.isConditionalArray(field))) && field.values instanceof Array) {
            field.values.splice(index, 1);
            if (field.values === undefined) {
                field.values = [];
            }
            if (field.values.length === 0) {
                field.values.push({
                    value: '',
                });
            }
        }
    };

    setAsActiveTab = (currentTab: IntegrationTabStructure, tabs: IntegrationTabStructure[]) => {
        currentTab.active = true;
        tabs.filter((tab) => tab.id !== currentTab.id).forEach((tab) => {
            tab.active = false;
        });
    };

    addNewTabRecord = (currentTab: IntegrationTabStructure, record: IntegrationRecord) => {
        record?.addNewTabRecord(currentTab);
    };

    deleteTabRecord = (currentTab: IntegrationTabStructure, index: number, record: IntegrationRecord) => {
        if (currentTab.array) {
            currentTab.records.splice(index, 1);
            if (currentTab.records.length === 0) {
                this.addNewTabRecord(currentTab, record);
            }
        }
    };

    isTabDisabled = (
        currentTab: IntegrationTabStructure,
        record: IntegrationRecord,
        tabs: IntegrationTabStructure[],
        subSectionRecord?: IntegrationsSubSection['records'][0]
    ) => {
        if (currentTab.conditionallyDisable) {
            let satisfied = false;
            currentTab.disableConditions &&
                currentTab.disableConditions.forEach((condtionsObject) => {
                    let satisfiedConditions;
                    switch (condtionsObject.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = condtionsObject.conditions.filter((condition) => {
                                const requiredField = record.getRecordField(condition.fieldId, undefined, subSectionRecord);
                                return condition.valueExists
                                    ? requiredField.value !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredField.value
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === condtionsObject.conditions.length;
                            break;
                        case 'ANY_SATISFY':
                            satisfiedConditions = condtionsObject.conditions.filter((condition) => {
                                const requiredField = record.getRecordField(condition.fieldId, undefined, subSectionRecord);
                                return condition.valueExists
                                    ? requiredField.value !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredField.value
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
            if (satisfied) {
                if (currentTab.array) {
                    currentTab.records = [];
                    record.addNewTabRecord(currentTab);
                } else {
                    currentTab?.fields.forEach((field) => {
                        field.value = field.defaultValue || undefined;
                    });
                }
                if (currentTab.active) {
                    const newTab = tabs?.find(
                        (tab) => !tab.conditionallyDisable || !this.isTabDisabled(tab, record, tabs, subSectionRecord)
                    );
                    setTimeout(() => {
                        this.setAsActiveTab(newTab, tabs);
                    });
                }
            }
            return satisfied;
        }
        return false;
    };

    hideField = (
        field: IntegrationConfigField,
        record: IntegrationRecord | any,
        tab?: IntegrationTabStructure,
        subSectionRecord?: IntegrationsSubSection['records'][0]
    ) => record.canHideField(field, tab, subSectionRecord);

    disableField = (field: IntegrationConfigField, record: IntegrationRecord, tab?: IntegrationTabStructure) =>
        record.canDisableField(field, tab);

    getIOConfiguredList = (
        api: string,
        isMaster: boolean,
        recordsList: any[],
        field: IntegrationConfigField,
        dontFetchUsingMasters: boolean
    ) => {
        const providerIds = this.orgIds;
        const currentOrganization = this._commonUtils.getFromStorage('currentorganization');
        if (providerIds.indexOf(currentOrganization.providerOrganizationId) === -1) {
            providerIds.push(currentOrganization.providerOrganizationId);
        }
        if (api !== undefined && api.length > 0) {
            this.getRecords(api, isMaster, field, dontFetchUsingMasters, (response) => {
                recordsList.splice(0);
                response?.forEach((record) => recordsList.push(record));
                setTimeout(() => {
                    this.changeRecordsHierarchy(recordsList, providerIds);
                }, 1000);
            });
        } else {
            recordsList.splice(0);
            field && (field.value = undefined);
        }
    };

    changeRecordsHierarchy = (recordsList, providerIds) => {
        const allRecords = CommonUtilsService.cloneObject(recordsList);
        recordsList.splice(0);
        providerIds.forEach((orgId) => {
            allRecords.find((object, index) => {
                if (object.configs[0].id === orgId) {
                    recordsList.push(allRecords[index]);
                }
            });
        });
    };

    getRecords = (
        api: string,
        isMasterFlag: boolean,
        field: IntegrationConfigField,
        dontFetchUsingMasters: boolean,
        callback?: (
            response: {
                appId: string;
                configuredData: string;
                id: string;
                lastModifiedByUserId: string;
                lastModifiedDate: string;
                name: string;
                organizationId: string;
                type: string;
            }[]
        ) => void
    ) => {
        this._api.integrations
            .getIntegrationConfigurations({
                config: api as any,
                dontFetchUsingMasters,
                isMaster: isMasterFlag,
            })
            .then((response) => {
                let newResponse: {
                    appId: string;
                    configuredData: string;
                    id: string;
                    lastModifiedByUserId: string;
                    lastModifiedDate: string;
                    name: string;
                    organizationId: string;
                    type: string;
                }[] = [];
                if (response?.length > 1) {
                    const selectedTenantName = this._commonUtils.getFromStorage('selectedtenantname');
                    if (selectedTenantName) {
                        const tenantNameArray = selectedTenantName.split(' / ');
                        tenantNameArray.forEach((tenant) => {
                            for (let i = 0; i < response.length; i++) {
                                if (response[i]['orgName'] === tenant) {
                                    newResponse.push(response[i]);
                                    response.splice(i, 1);
                                    break;
                                }
                            }
                        });
                        if (response?.length) {
                            response.forEach((conf) => {
                                newResponse.push(conf);
                            });
                        }
                    }
                } else {
                    newResponse = response;
                }
                let found = false;
                (newResponse as any)?.forEach((responseObject) => {
                    responseObject?.configs.forEach((config) => {
                        found = found || config.id === field?.value || config.name === field?.value;
                    });
                });
                if (!found) {
                    field && (field.value = undefined);
                }
                callback?.(newResponse);
            });
    };

    hideSubSection = (
        subSection: IntegrationsSubSection,
        record: IntegrationRecord | AppIntegrationRecord,
        subSectionRecord?: IntegrationsSubSection['records'][0]
    ) => record.canHideSubSection(subSection, subSectionRecord);

    addNewSubSectionRecord = (record: AppIntegrationRecord, subSection: IntegrationsSubSection) => record.addSubSectionRecord(subSection);

    chainChanged = (
        record: AppIntegrationRecord,
        field: IntegrationConfigField,
        assetName: string,
        assetId: string,
        chains: Chain[] = []
    ) => {
        const value = field?.value;
        const transformationField = record.getRecordField('transformationName');
        if (value && value.length > 0) {
            const selectedChain = chains && chains.find((chain) => chain.chainName === value);
            if (!selectedChain && chains?.length > 0) {
                field.value = undefined;
                field.values = undefined;
                transformationField.value = undefined;
                transformationField.values = undefined;
                return this._libUtils.alertError(this.translateMsg('Previously selected chain is deleted, Please select a new chain.'));
            } else if (!selectedChain) {
                return;
            }
            const data = {
                chainName: value,
                assetId: selectedChain?.metadataId || assetId,
                assetName,
                repositoryId: selectedChain?.repositoryId,
            };
            if (!data.repositoryId) {
                return;
            }
            this._api.transformations.getTransformationNamesInChain(data).subscribe({
                next: (response: any) => {
                    if (transformationField) {
                        transformationField.options.splice(0);
                        response.forEach((transformation) => {
                            const transformationObject = {
                                displayName: transformation,
                                id: transformation,
                                chainName: transformation,
                            };
                            transformationField.options.push(transformationObject);
                        });
                        if (!transformationField.value || !response.includes(transformationField.value)) {
                            transformationField.value = response?.[0];
                        }
                    }
                },
            });
        } else {
            const dependencyField = record.getRecordField('transformationName');
            dependencyField && (dependencyField.value = undefined);
            dependencyField.options = [];
        }
    };

    transformationsChanged = (
        record: AppIntegrationRecord,
        field: IntegrationConfigField,
        assetName: string,
        assetId: string,
        sources: IntegrationConfigField['options'],
        chains?: Chain[]
    ) => {
        const value = field?.value;
        if (value && value.length > 0) {
            const chainField = record.getRecordField('chainName');
            const selectedChain = chains && chains.find((chain) => chain.chainName === chainField?.value);
            if (!selectedChain?.repositoryId) {
                return;
            }
            this._api.transformations.getSourceNamesInChain(
                {
                    transformationName: value,
                    assetId: selectedChain?.metadataId || assetId,
                    assetName,
                    repositoryId: selectedChain?.repositoryId,
                },
                {
                    successCallback: (response) => {
                        sources.splice(0);
                        response.forEach((transformation) => {
                            const transformationObject = {
                                displayName: transformation,
                                id: transformation,
                                value: transformation,
                            };
                            sources.push(transformationObject);
                        });
                    },
                }
            );
        }
    };

    configNameChanged = (option, subSectionRecord: IntegrationsSubSection['records'][0]) => {
        const configurationListField = subSectionRecord.fields.find((field) => field.id === 'orgId');
        configurationListField && (configurationListField.value = option?.id);
    };

    apiConfigChange = (
        value: IntegrationConfigField['value'],
        subSectionRecord: IntegrationsSubSection['records'][0],
        config: IntegrationModal,
        dontFetchUsingMasters: boolean
    ) => {
        const configurationListField =
            subSectionRecord.fields.find((field) => field.id === 'globalConfigName') ||
            subSectionRecord.fields.find((field) => field.id === 'configId');
        this.getIOConfiguredList(value, config.useMaster, configurationListField.options, configurationListField, dontFetchUsingMasters);
    };

    deleteConfiguration = (currentRecord: AppIntegrationRecord): Promise<void> => {
        const configuredData = currentRecord.originalData;
        return new Promise<void>((resolve) => {
            this._api.integrations.delteAppIntegrationConfiguration(
                {
                    locationId: configuredData.locationId,
                    serviceId: configuredData.serviceId,
                    integrationType: configuredData.integrationType,
                    configName: encodeURIComponent(configuredData.configName),
                },
                {
                    successCallback: (response) => {
                        this._libUtils.alertSuccess((response && response.msg) || this.translateMsg('Successfully deleted configuration'));
                        resolve();
                    },
                }
            );
        });
    };

    saveAppIntegrationResourceConfiguration = (payload: AppNewIntegrationRecordPayload): Promise<any> => {
        return new Promise((resolve) => {
            this._api.transformations.saveResourceConfiguration(payload, {
                successCallback: (response) => {
                    this._libUtils.alertSuccess(this.translateMsg('Configuration saved successfully'));
                    resolve(response);
                },
            });
        });
    };

    triggerAppResourceIntegrationConfiguration = (
        record: AppIntegrationRecord,
        config?: IntegrationModal,
        isEninvoiceApp?: boolean
    ): Promise<any> => {
        return new Promise((resolve) => {
            if (record && record.originalData && record.originalData.serviceId && record.originalData.serviceId.length > 0) {
                this._api.integrations.runAppIntegrationConfiguration(
                    {
                        configName: record.originalData.configName,
                        locationId: record.originalData.locationId,
                        serviceId: record.originalData.serviceId,
                        api: config && config.api,
                        isEninvoiceApp: isEninvoiceApp || false,
                    },
                    {
                        successCallback: (response) => {
                            this._libUtils.alertSuccess(
                                (response && response.msg) || this.translateMsg('Configuration triggered successfully')
                            );
                            resolve(response);
                        },
                    }
                );
            }
        });
    };

    getMasterTables = (orgId?: string) => {
        let masterTables: MasterTables[] = [];
        const storedMasters = this._commonUtils.getFromStorage('allMasters');
        const currentOrgId = orgId || this._commonUtils.getFromStorage('currentorganizationid');
        if (storedMasters) {
            const masters = storedMasters[currentOrgId];
            masterTables = Object.values(masters);
        }
        return masterTables;
    };

    translateMsg = (msg: string): string => translate('' + msg);
}
