import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Chain } from '../../models/chain.interface';
import { Transformation } from '../../models/transformation';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { TRANSFORMATIONS_REDUCER_KEY } from '../reducers';
import { getSelectedAppServiceId$ } from './apps.selector';
import { getAssociatedOrganizationsMap$ } from './locations.selector';
import { getCurrentOrganizationId$ } from './session.selector';

import type { TransformationsState } from '../states';
const currentState = createFeatureSelector<TransformationsState>(TRANSFORMATIONS_REDUCER_KEY);

export const getAllTransformationsServicesMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.organizationsMap?.[organizationId]?.services
);

export const getServiceTransformations$ = (serviceId: string) =>
    createSelector(getAllTransformationsServicesMap$, (map) => map?.[serviceId]);

export const getActiveInboundTransformations$ = (serviceId: string) =>
    createSelector(getAllTransformationsServicesMap$, (services) => services?.[serviceId]?.transformations?.activeTransformations?.inbound);

export const getActiveOutboundTransformations$ = (serviceId: string) =>
    createSelector(
        getAllTransformationsServicesMap$,
        (services) => services?.[serviceId]?.transformations?.activeTransformations?.outbound
    );

export const getInboundDetailedTransformations$ = (serviceId: string) =>
    createSelector(getServiceTransformations$(serviceId), getActiveInboundTransformations$(serviceId), (map, activeInboundTrs = []) => {
        const organizationsMap: {
            orgName: string;
            repositoryId: string;
            transformations: Transformation[];
        }[] = [];
        Object.keys(map?.transformations?.inboundDetailed || {}).forEach((key) => {
            if (!organizationsMap.find((orgMap) => orgMap.repositoryId === key)) {
                organizationsMap.push({
                    orgName: map?.relativeOrganizations?.[key],
                    repositoryId: key,
                    transformations: [],
                });
            }
            const orgTransformations = map?.transformations?.inboundDetailed?.[key];
            const organizationMap = organizationsMap.find((orgMap) => orgMap.repositoryId === key);
            Object.keys(orgTransformations || {}).forEach((transformationKey) => {
                organizationMap.transformations.push({
                    ...orgTransformations?.[transformationKey],
                    active: activeInboundTrs?.includes(transformationKey),
                });
            });
            organizationMap?.transformations.sort((a, b) => {
                if (activeInboundTrs?.includes(a.chainName) && activeInboundTrs?.includes(b.chainName)) {
                    return activeInboundTrs?.indexOf(a.chainName) - activeInboundTrs?.indexOf(b.chainName);
                } else if (activeInboundTrs?.includes(a.chainName)) {
                    return -1;
                } else if (activeInboundTrs?.includes(b.chainName)) {
                    return 1;
                }
                return 0;
            });
        });
        return organizationsMap;
    });

export const getFileUploadTransformations$ = (serviceId: string) =>
    createSelector(getInboundDetailedTransformations$(serviceId), (organizationsMap) => {
        let organizationsList: {
            orgName: string;
            transformations: Transformation[];
            repositoryId: string;
        }[] = [];
        organizationsMap.forEach((orgMap) => {
            const transformations = orgMap.transformations.filter((transformation) => transformation.isFileUpload);
            transformations?.length > 0 &&
                organizationsList.push({
                    ...orgMap,
                    transformations,
                });
        });
        return organizationsList;
    });

export const getAssetToAssetTransformations$ = (serviceId: string) =>
    createSelector(getInboundDetailedTransformations$(serviceId), (organizationsMap) => {
        let organizationsList: {
            orgName: string;
            transformations: Transformation[];
            repositoryId: string;
        }[] = [];
        organizationsMap.forEach((orgMap) => {
            const transformations = orgMap.transformations.filter((transformation) => transformation.isAssetToAsset);
            transformations?.length > 0 &&
                organizationsList.push({
                    ...orgMap,
                    transformations,
                });
        });
        return organizationsList;
    });

export const getSearchAssetTransformations$ = (serviceId: string) =>
    createSelector(getInboundDetailedTransformations$(serviceId), (organizationsMap) => {
        let organizationsList: {
            orgName: string;
            transformations: Transformation[];
            repositoryId: string;
        }[] = [];
        organizationsMap.forEach((orgMap) => {
            const transformations = orgMap.transformations.filter((transformation) => transformation.isSearchAsset);
            transformations?.length > 0 &&
                organizationsList.push({
                    ...orgMap,
                    transformations,
                });
        });
        return organizationsList;
    });

export const getSearchOrAssetToAssetTransformations$ = (serviceId) =>
    createSelector(
        getInboundDetailedTransformations$(serviceId),
        getActiveInboundTransformations$(serviceId),
        (organizationsMap, activeTransformations) => {
            let organizationsList: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[] = [];
            organizationsMap.forEach((orgMap) => {
                const isActiveInboundTrsExists = !!orgMap.transformations.find(
                    (trans) => activeTransformations?.length === 0 || trans.active
                );
                const transformations = orgMap.transformations.filter(
                    (transformation) =>
                        (isActiveInboundTrsExists &&
                            (activeTransformations?.length === 0 || transformation.active) &&
                            (transformation.isAssetToAsset || transformation.isSearchAsset)) ||
                        (!isActiveInboundTrsExists && (transformation.isAssetToAsset || transformation.isSearchAsset))
                );
                transformations?.length > 0 &&
                    organizationsList.push({
                        ...orgMap,
                        transformations,
                    });
            });
            return organizationsList.sort((a, b) => {
                if (a.repositoryId === '111') {
                    return 1;
                }
                if (b.repositoryId === '111') {
                    return -1;
                }
                return 0;
            });
        }
    );

export const getChainNamesByServiceId$ = (serviceId: string) =>
    createSelector(getAllTransformationsServicesMap$, (services) => services?.[serviceId]?.chainDisplayNames);

export const getOutBoundTransformations$ = (serviceId: string) =>
    createSelector(
        getAllTransformationsServicesMap$,
        getActiveOutboundTransformations$(serviceId),
        getAssociatedOrganizationsMap$,
        (services, transformationNames, associatedOrgs) => {
            let transformations: Transformation[] = CommonUtilsService.cloneObject(services?.[serviceId]?.transformations?.outbound || []);
            transformations
                .sort((a, b) => {
                    if (transformationNames?.includes(a.chainName) && transformationNames?.includes(b.chainName)) {
                        return transformationNames?.indexOf(a.chainName) - transformationNames?.indexOf(b.chainName);
                    } else if (transformationNames?.includes(a.chainName)) {
                        return -1;
                    } else if (transformationNames?.includes(b.chainName)) {
                        return 1;
                    }
                    return 0;
                })
                .forEach((transformation) => {
                    transformation.active = transformationNames?.indexOf(transformation.chainName) > -1;
                    transformation['organizationName'] = associatedOrgs?.[transformation.repositoryId]?.name;
                });
            return transformations;
        }
    );

const getTransformationsRelativeOrganizations$ = (serviceId: string) =>
    createSelector(getServiceTransformations$(serviceId), (map) => map?.relativeOrganizations);

export const getOutboundTransformationsMap$ = (serviceId: string) =>
    createSelector(
        getOutBoundTransformations$(serviceId),
        getTransformationsRelativeOrganizations$(serviceId),
        (transformations, orgNames) => {
            let organizationsList: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[] = [];
            transformations.forEach((transformation) => {
                if (!organizationsList.find((org) => org.repositoryId === transformation.repositoryId)) {
                    organizationsList.push({
                        orgName: orgNames?.[transformation.repositoryId],
                        repositoryId: transformation.repositoryId,
                        transformations: [],
                    });
                }
                const org = organizationsList.find((org) => org.repositoryId === transformation.repositoryId);
                org.transformations.push(transformation);
            });
            return organizationsList;
        }
    );

export const getInBoundTransformations$ = (serviceId: string) =>
    createSelector(
        getAllTransformationsServicesMap$,
        getActiveInboundTransformations$(serviceId),
        getAssociatedOrganizationsMap$,
        (services, transformationNames = [], associatedOrgs) => {
            let transformations: Transformation[] = CommonUtilsService.cloneObject(services?.[serviceId]?.transformations?.inbound || []);
            transformations
                .sort((a, b) => {
                    if (transformationNames?.includes(a.chainName) && transformationNames?.includes(b.chainName)) {
                        return transformationNames?.indexOf(a.chainName) - transformationNames?.indexOf(b.chainName);
                    } else if (transformationNames?.includes(a.chainName)) {
                        return -1;
                    } else if (transformationNames?.includes(b.chainName)) {
                        return 1;
                    }
                    return 0;
                })
                .forEach((transformation) => {
                    transformation.active = transformationNames?.indexOf(transformation.chainName) > -1;
                    transformation['organizationName'] = associatedOrgs?.[transformation.repositoryId]?.name;
                });
            return transformations;
        }
    );

const getTransformationsLoading$ = (serviceId: string) =>
    createSelector(getServiceTransformations$(serviceId), (state) => {
        return state?.loading;
    });

export const getInboundLoadingStatus$ = (serviceId: string) =>
    createSelector(getTransformationsLoading$(serviceId), (loadingMap) => {
        return loadingMap?.inbound;
    });

export const getOutboundLoadingStatus$ = (serviceId: string) =>
    createSelector(getTransformationsLoading$(serviceId), (loadingMap) => {
        return loadingMap?.outbound;
    });

export const getChainLoadingStatus$ = (serviceId: string) =>
    createSelector(getTransformationsLoading$(serviceId), (loadingMap) => {
        return loadingMap?.chainNames;
    });

export const getTransformationDetailLoadingStatus$ = (serviceId: string) =>
    createSelector(getTransformationsLoading$(serviceId), (loadingMap) => {
        return loadingMap?.transformationsDetail;
    });

export const getMasterSchedulerInBoundTransformations$ = createSelector(
    getSelectedAppServiceId$,
    getAllTransformationsServicesMap$,
    (serviceId, transformationNamesMap) => {
        const transformations = CommonUtilsService.cloneObject(transformationNamesMap?.[serviceId]?.transformations);
        const chains: Chain[] = transformations?.masterScheduler?.inbound || [];
        const activeTransformations = transformations?.activeTransformations?.inbound || [];
        return chains
            .sort((a, b) => {
                if (activeTransformations?.includes(a.chainName) && activeTransformations?.includes(b.chainName)) {
                    return activeTransformations?.indexOf(a.chainName) - activeTransformations?.indexOf(b.chainName);
                } else if (activeTransformations?.includes(a.chainName)) {
                    return -1;
                } else if (activeTransformations?.includes(b.chainName)) {
                    return 1;
                }
                return 0;
            })
            .filter((chain) => activeTransformations?.length === 0 || activeTransformations?.includes(chain.chainName));
    }
);

export const getInboundIntegrationsChains$ = createSelector(
    getSelectedAppServiceId$,
    getAllTransformationsServicesMap$,
    (serviceId, transformationNamesMap) => {
        const transformations = CommonUtilsService.cloneObject(transformationNamesMap?.[serviceId]?.transformations);
        const chains: Chain[] = transformations?.integrationsChains?.inbound || [];
        const activeTransformations = transformations?.activeTransformations?.inbound || [];
        return chains
            .sort((a, b) => {
                if (activeTransformations?.includes(a.chainName) && activeTransformations?.includes(b.chainName)) {
                    return activeTransformations?.indexOf(a.chainName) - activeTransformations?.indexOf(b.chainName);
                } else if (activeTransformations?.includes(a.chainName)) {
                    return -1;
                } else if (activeTransformations?.includes(b.chainName)) {
                    return 1;
                }
                return 0;
            })
            .filter((chain) => activeTransformations?.length === 0 || activeTransformations?.includes(chain.chainName));
    }
);

export const getOutboundIntegrationsChains$ = createSelector(
    getSelectedAppServiceId$,
    getAllTransformationsServicesMap$,
    (serviceId, transformationNamesMap) => {
        const transformations = CommonUtilsService.cloneObject(transformationNamesMap?.[serviceId]?.transformations);
        const chains: Chain[] = transformations?.integrationsChains?.outbound || [];
        const activeTransformations = transformations?.activeTransformations?.outbound || [];
        return chains
            .sort((a, b) => {
                if (activeTransformations?.includes(a.chainName) && activeTransformations?.includes(b.chainName)) {
                    return activeTransformations?.indexOf(a.chainName) - activeTransformations?.indexOf(b.chainName);
                } else if (activeTransformations?.includes(a.chainName)) {
                    return -1;
                } else if (activeTransformations?.includes(b.chainName)) {
                    return 1;
                }
                return 0;
            })
            .filter((chain) => activeTransformations?.length === 0 || activeTransformations?.includes(chain.chainName));
    }
);

export const getTransformationNamesInChain$ = (serviceId: string) =>
    createSelector(getAllTransformationsServicesMap$, (services) => services?.[serviceId]?.transformationsByChainName);

export const getOutboundChains$ = (serviceId: string) =>
    createSelector(getServiceTransformations$(serviceId), (transformationsMap) => transformationsMap?.transformations?.chains);

export const getChainsAndTransformations$ = createSelector(
    getSelectedAppServiceId$,
    getAllTransformationsServicesMap$,
    (serviceId, transformationNamesMap) => {
        const transformations = CommonUtilsService.cloneObject(transformationNamesMap?.[serviceId]?.transformations);
        const chainsAndTransformation: Chain[] = transformations?.chainsAndTransformations?.outbound || [];
        return chainsAndTransformation;
    }
);

export const getInternalConfig$ = createSelector(
    getSelectedAppServiceId$,
    getAllTransformationsServicesMap$,
    (serviceId, transformationNamesMap) => {
        return transformationNamesMap?.[serviceId]?.transformations?.internalConfigs?.inbound || [];
    }
);
