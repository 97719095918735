import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'taxilla-library';

import { IntegrationConfigField, IntegrationsSubSection } from '../../interface/integrationmodal.interface';
import { IntegrationRecord } from '../../models/integrations/integrationrecord.class';
import { MasterField } from '../../models/masters/masterfield.class';
import { MasterTables } from '../../models/masters/mastertables.class';
import { IntegrationsUtilsService } from '../../services/integrations-utils/integrations-utils.service';

@Component({
    selector: 'app-integrations-field-template',
    templateUrl: './integrations-field-template.component.html',
    styleUrls: ['./integrations-field-template.component.scss'],
})
export class IntegrationsFieldTemplateComponent implements OnInit {
    @Input() record: IntegrationRecord;
    @Input() field: IntegrationConfigField;
    @Input() masterTableColumns: MasterField[];
    @Input() hide: boolean;
    @Input() configuredRecords: any[];
    @Input() parentRecord: IntegrationRecord;
    @Input() chain = [];
    @Input() sources = [];
    @Input() subSectionRecord?: IntegrationsSubSection['records'][0];
    @Input() masterTables: MasterTables[];
    @Input() subTenants: any[];

    @Output() getMasterColumns = new EventEmitter();
    @Output() apiConfigChange = new EventEmitter();
    @Output() configNameChanged = new EventEmitter();
    @Output() chainChanged: EventEmitter<IntegrationConfigField['value']> = new EventEmitter();
    @Output() transformationsChanged: EventEmitter<IntegrationConfigField['value']> = new EventEmitter();
    @Output() checkValidateButton = new EventEmitter();
    @Output() checkDisableUpdateAndRunNow = new EventEmitter();

    getFieldType = this._integrationUtils.getFieldType;
    toggleFieldValues = this._integrationUtils.toggleFieldValues;
    getOptions = this._integrationUtils.getOptions;
    deleteFieldValue = this._integrationUtils.deleteFieldValue;
    isFormValid = this._integrationUtils.isFormValid;

    constructor(public _integrationUtils: IntegrationsUtilsService, private _utils: UtilsService) {}

    isFieldDisabled = () => {
        let disabled = false;
        if (this.field.conditionalDisable) {
            disabled = this._integrationUtils.disableField(this.field, this.record);
        }
        return disabled;
    };

    toggleValueChanged(field: IntegrationConfigField, _record: IntegrationRecord, value: any): void {
        if (field.id === 'internalAsync') {
            this.checkDisableUpdateAndRunNow.emit(value);
        }
    }

    setPanelTransformation = (field, mapping, value) => {
        if (field.value === undefined) {
            const obj = {};
            obj[mapping.name] = value;
            field.value = obj;
        } else {
            field.value[mapping.name] = value;
        }
    };

    isConditionalArray = (field: IntegrationConfigField) => this.record.isConditionalArray(field);

    valueChanged = ($event) => {
        this.field.value = $event;
        this.field.errors = [];
        this.isFormValid(this.record, this.field, this.subSectionRecord);
    };

    changekValidateButton = () => {
        if (this.record?.recordData?.api === 'RESTAPI' || this.parentRecord?.recordData?.api === 'RESTAPI') {
            if (this.field.id === 'authorizationSystem') {
                this.checkValidateButton.emit(this.field.value !== 'OTHERS');
                return;
            }
            if (this.field.id === 'type') {
                let authorizationSystem = this.parentRecord?.recordData?.fields?.find((fld) => fld.id === 'authorizationSystem')?.value;
                this.checkValidateButton.emit(authorizationSystem === 'OTHERS' && this.field.value === 'OAUTH');
                return;
            }
        }
    };

    createOptionClick = () => {
        switch (this.field.id) {
            case 'authorizationSystem':
                this._utils.navigateToAuthorizationConfig('create-new');
                break;
        }
    };
    editOptionClick = (option: any) => {
        switch (this.field.id) {
            case 'authorizationSystem':
                this._utils.navigateToAuthorizationConfig(option?.id);
                break;
        }
    };

    ngOnInit() {
        this.record?.id && this.changekValidateButton();
    }
}
