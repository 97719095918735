import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private localeLanguage: string;

    constructor(public _utils: UtilsService, private _commonUtils: CommonUtilsService, private _translate: TranslocoService) {
        this._translate.langChanges$.subscribe(() => {
            this.localeLanguage = this._commonUtils.getLocaleLangugae();
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('configuration.json') >= 0) {
            return next.handle(req);
        }
        const guestUrl = environment['encomply-ui'] + '/collaboration';
        const currentUrl = window.location.href;
        const data = {
            'Accept-Language': this.localeLanguage,
        };
        const authBearer = this._commonUtils.getFromStorage('authorizationToken');
        let withCredentials: boolean;
        if (currentUrl.indexOf(guestUrl) > -1) {
            if (authBearer?.length > 0) {
                data['Authorization'] = 'Bearer ' + authBearer;
            }
            withCredentials = false;
        } else {
            const requestURL = req.url;
            if (
                requestURL.indexOf(environment.taxilla_api) > -1 ||
                requestURL.indexOf(environment.taxilla_search_api) > -1 ||
                requestURL.indexOf(environment.taxilla_user_identity_api) > -1 ||
                requestURL.indexOf(environment.notifications_api) > -1 ||
                requestURL.indexOf(environment.oauth_api) > -1 ||
                requestURL.indexOf(environment.bundle_api) > -1 ||
                requestURL.indexOf(environment.recon_api) > -1 ||
                requestURL.indexOf(environment.integration_api) > -1 ||
                requestURL.indexOf(environment.analytics_api) > -1 ||
                environment.wrappers?.find((wrapper: string) => requestURL.indexOf(wrapper) > -1)
            ) {
                withCredentials = true;
            }
        }
        const newHttpRequest = req.clone({
            setHeaders: data,
            withCredentials: withCredentials,
        });
        const cloneReq = req.clone(newHttpRequest);
        return next.handle(cloneReq);
    }
}
