import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Search } from 'taxilla-library';

import { NewProcessService } from '../../services/new-process/new-process.service';

@Component({
    selector: 'app-common-search-criteria-view',
    templateUrl: './common-search-criteria-view.component.html',
    styleUrls: ['./common-search-criteria-view.component.scss'],
})
export class CommonSearchCriteriaViewComponent implements OnInit, OnDestroy {
    unSubscribe = new Subject<void>();

    @Input() parameters: {
        dataType: string;
        fullId: string;
        isBusinessKey: boolean;
        isPrecaptured: boolean;
        isSearchCriteria: boolean;
        keyId: string;
        label: string;
        value: string;
        search?: Search;
        selectedSearchTab?: number;
        unitIds: string[];
    }[] = [];
    allTenantsMap: {};

    constructor(public _newProcessService: NewProcessService) {}

    ngOnInit() {
        this._newProcessService.getAllTenantsForFlatStructure().then(() => {
            this.allTenantsMap = this.getAllTenantMap();
        });
    }

    getAllTenantMap = () => this._newProcessService.allTenantsMap;

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
