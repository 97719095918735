import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FilterPipe',
    pure: true,
})
export class FilterPipe implements PipeTransform {
    transform(
        items: any[],
        field: string,
        searchText: string,
        secondLevelField?: string,
        alternateField?: string,
        alternateSecondLevelField?: string,
        subFieldsProperty?: string
    ): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter((it) =>
            this.filterItems(it, field, searchText, secondLevelField, alternateField, alternateSecondLevelField, subFieldsProperty)
        );
    }

    filterItems = (
        it: any,
        field: string,
        searchText: string,
        secondLevelField?: string,
        alternateField?: string,
        alternateSecondLevelField?,
        subFieldsProperty?: string
    ) => {
        let item = '';
        let matched = false;
        if (secondLevelField || alternateSecondLevelField) {
            item = it[field];
            if (item) {
                item = item[secondLevelField];
                if (item) {
                    matched = item.toLowerCase().includes(searchText);
                }
            }
            if (!matched && !item) {
                item = it[alternateField];
                if (item) {
                    item = item[alternateSecondLevelField];
                    if (item) {
                        matched = item.toLowerCase().includes(searchText);
                    }
                }
            }
        }
        if (!matched && subFieldsProperty && it[subFieldsProperty]) {
            const subField = it[subFieldsProperty];
            let subItems;
            switch (Array.isArray(subField)) {
                case true:
                    subItems = subField.filter((subItem) =>
                        this.filterItems(
                            subItem,
                            field,
                            searchText,
                            secondLevelField,
                            alternateField,
                            alternateSecondLevelField,
                            subFieldsProperty
                        )
                    );
                    it[subFieldsProperty] = subItems;
                    break;
                case false:
                    subItems = Object.keys(subField).filter((subItemId) =>
                        this.filterItems(
                            subField[subItemId],
                            field,
                            searchText,
                            secondLevelField,
                            alternateField,
                            alternateSecondLevelField,
                            subFieldsProperty
                        )
                    );
                    break;
            }
            matched = subItems?.length > 0;
        }
        if (!matched) {
            if (typeof it === 'string') {
                matched = it?.toLowerCase().includes(searchText);
                return matched;
            } else {
                item = it[field];
                matched = typeof item === 'string' && item?.toLowerCase().includes(searchText);
                if (!item || !matched) {
                    item = it[alternateField];
                    matched = item?.toLowerCase().includes(searchText);
                }
                if (!matched && subFieldsProperty && it[subFieldsProperty]) {
                    const subItems = it[subFieldsProperty].filter((subItem) =>
                        this.filterItems(subItem, field, searchText, secondLevelField, alternateField, alternateSecondLevelField)
                    );
                    matched = subItems?.length > 0;
                }
                return matched;
            }
        }
        return matched;
    };
}
